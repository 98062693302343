import { register } from '@onpace/onspace-core/components/translations'

register({
  "en": {
    "onspace": {
      "cms": {
        "interactions": {
          "subscribers": {
            "anonymous": {
              "name": "Anonymous",
              "initial": "Ay"
            },
            "online": {
              "singular": "%{count} other user is viewing this:",
              "plural": "%{count} other users are viewing this:"
            }
          }
        }
      }
    }
  }
})
