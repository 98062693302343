import OnspacePlayerControls from '@onpace/onspace-media/elements/player/controls'

/// The Onspace Player Controls is an element to control an Audio Player.
export default class OnspaceAudioPlayerControls extends OnspacePlayerControls {
  /// Sets up the content which should be displayed at all times.
  ///
  /// This overrides OnspacePlayerControls.setupInitialContent to also include playback controls, which are needed to
  /// initiate the player.
  setupInitialContent() {
    super.setupInitialContent()

    this.setupPlaybackControls()
  }

  /// Initialises the elements which control playback.
  ///
  /// This overrides OnspacePlayerControls.playPausePressed to initiate the player if playback has not begun.
  playPausePressed(event) {
    if (this.player.playbackStarted) {
      super.playPausePressed(event)
    } else {
      this.player.startPlayer()
    }
  }
}

window.customElements.define('onspace-audio-controls', OnspaceAudioPlayerControls)
