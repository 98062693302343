import { create as createCredential, parseCreationOptionsFromJSON as parseCreationOptions, get as getCredential, parseRequestOptionsFromJSON as parseRequestOptions } from '@github/webauthn-json/browser-ponyfill'

import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import translation from '@onpace/onspace-core/components/translations'

/// An abstract Web Authentication class.
///
/// This should not be used directly, instead subclass and override the necessary functions.
class OnspaceAuthenticationWebauthnElement extends CustomHTMLElement {
  /// Initialises the element.
  runConstructor() {
    super.runConstructor()

    this.classList.add('onspace-webauthn')
    this.detectForm()
  }

  /// Run when the element is first attached to the DOM.
  runFirstConnected() {
    super.runFirstConnected()

    if (this.supportRequired) { this.startLoading() }
    this.detectSupport().then((result) => {
      if (result) {
        this.setupComponent()
      } else if (this.supportRequired) {
        this.displayError(translation('onspace.authentication.webauthn.message.unsupported'))
      }
    })
  }

  /// Detects if Web Authentication is available.
  ///
  /// This is checked automatically and doesn't need to be called by a concrete subclass.
  async detectSupport() {
    if (!window.PublicKeyCredential || !window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable || !window.PublicKeyCredential.isConditionalMediationAvailable) { return false }

    const results = await Promise.all([window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable(), window.PublicKeyCredential.isConditionalMediationAvailable()])
    const success = results.every(r => r === true)

    return success
  }

  ///// Component

  /// Indicates if the component is required.
  ///
  /// When this is the case, loading will be displayed while the component is detecting suport, and an error will be
  /// displayed if not available. This returns +true+ by default.
  get supportRequired() {
    return true
  }

  /// Sets up the component.
  ///
  /// This should be overridden in a subclass, the default implementation does nothing.
  setupComponent() {}

  ///// Form

  /// Detects the form belonging to this element.
  ///
  /// This accepts an object which is used to update the form's data. Each object key corresponds to an input's name,
  /// and the data it's value.
  detectForm() {
    this.formElement = this.closest('form')
  }

  /// Updates the form with the given data.
  updateForm(data) {
    Object.keys(data).forEach((name) => {
      const input = this.formElement.querySelector(`input[name="${name}"]`)
      input.value = data[name]
    })
  }

  /// Submits the associated form.
  ///
  /// This will also update the form with any given data.
  submitForm(data={}) {
    this.updateForm(data)

    const button = this.formElement.querySelector('button[type="submit"]')
    if (button) {
      button.click()
    } else {
      this.formElement.submit()
    }
  }

  ///// Message

  /// Displays the given text in the message element.
  displayMessage({ message, iconName }) {
    this.removeMessage()

    this.messageElement = document.createElement('div')
    this.messageElement.classList.add('onspace-webauthn__message')
    this.appendChild(this.messageElement)

    if (iconName) {
      const iconElement = SVGElement.createOnspaceSpritemapSvg(iconName)
      this.messageElement.appendChild(iconElement)
    }

    if (message) {
      const textElement = document.createElement('div')
      textElement.classList.add('onspace-webauthn__text')
      textElement.innerText = message
      this.messageElement.appendChild(textElement)
    }
  }

  /// Removes any messages currently displayed.
  removeMessage() {
    if (!this.messageElement) { return }

    this.messageElement.remove()
    this.messageElement = null
  }

  /// Displays the loading indicator with an optional message.
  startLoading(message = '') {
    this.displayMessage({ message, iconName: 'onspace/logo_onspace_loading' })
  }

  /// Displays an error message.
  displayError(message) {
    this.displayMessage({ message, iconName: 'onspace/icon_warning' })
  }

  ///// Action

  /// Shows an action button.
  displayAction(text, action) {
    this.removeAction()

    this.actionButton = document.createElement('div')
    this.actionButton.classList.add('onspace-button')
    this.actionButton.addEventListener('click', action)
    this.appendChild(this.actionButton)

    const textElement = document.createElement('span')
    textElement.innerText = text
    this.actionButton.appendChild(textElement)
  }

  /// Removes a currently displayed action button.
  removeAction() {
    if (!this.actionButton) { return }

    this.actionButton.remove()
    this.actionButton = null
  }
}

////////// Add

/// The Onspace Authentication Web Authentication Add element has the ability to create a new credential.
///
/// This component will trigger itself automatically when it is added to the DOM.
export class OnspaceAuthenticationWebauthnAdd extends OnspaceAuthenticationWebauthnElement {
  /// Initialises the element.
  setupComponent() {
    super.setupComponent()

    const options = this.getJsonAttribute('data-options')
    this.createCredential(options)
  }

  /// Creates a credential and submits the form.
  async createCredential(options) {
    try {
      const publicKey = parseCreationOptions({ publicKey: options })
      const credential = await createCredential(publicKey)

      this.updateForm({ 'webauthn[credential]': JSON.stringify(credential) })
      this.displayMessage({ message: translation('onspace.authentication.webauthn.add.success'), iconName: 'onspace/icon_checkmark' })
    } catch (error) {
      this.displayError(translation('onspace.authentication.webauthn.add.failed'))
    }
  }
}

window.customElements.define('onspace-webauthn-add', OnspaceAuthenticationWebauthnAdd)

////////// Get

/// The Onspace Authentication Web Authentication Get element has the ability to authorise with an existing credential.
///
/// This component behaves in two different ways:
/// - By default, it will display an action button to trigger sign in.
/// - When the +data-automatic+ attribute is provided, it will trigger itself automatically when it enters the DOM. This
///   should only be used when it is known the user possesses a web authentication credential.
export class OnspaceAuthenticationWebauthnGet extends OnspaceAuthenticationWebauthnElement {
  /// Indicates if the component is required.
  get supportRequired() {
    return false
  }

  /// Initialises the element.
  setupComponent() {
    super.setupComponent()

    const automatic = this.getBooleanAttribute('data-automatic')
    this.options = this.getJsonAttribute('data-options')

    if (automatic) {
      this.startLoading()

      this.retrieveCredential(this.options)
    } else {
      this.removeMessage()
      this.displayAction(translation('onspace.authentication.webauthn.get.action'), this.actionTriggered.bind(this))
    }
  }

  /// Responds to the displayed action being triggered.
  actionTriggered() {
    this.formElement.onspaceDisableForm(this.actionButton)
    this.retrieveCredential(this.options)
  }

  /// Retrieves a credential and submits the form.
  async retrieveCredential(options) {
    try {
      const request = parseRequestOptions({ publicKey: options })
      const credential = await getCredential(request)
      this.formElement.onspaceEnableForm(this.actionButton)

      this.submitForm({ 'session[credential]': JSON.stringify(credential) })
    } catch (error) {
      this.formElement.onspaceEnableForm(this.actionButton)
      this.displayError(translation('onspace.authentication.webauthn.get.failed'))
    }
  }
}

window.customElements.define('onspace-webauthn-get', OnspaceAuthenticationWebauthnGet)
