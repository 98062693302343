document.addEventListener('turbo:load', (_event) => {
  document.querySelectorAll('[data-slideover-show]').forEach((element) => {
    element.addEventListener('click', showSlideover)
  })
  document.querySelectorAll('[data-slideover-hide]').forEach((element) => {
    element.addEventListener('click', hideSlideover)
  })
})

window.addEventListener('resize', hideSlideover)
document.addEventListener('onspace:scrollretain:released', hideSlideover)
document.addEventListener('onspace:dialog:show', hideSlideover)

function showSlideover() {
  const slideover = document.querySelector('.onspace-slideover')
  if (!slideover) { return }

  const style = slideover.getComputedStyle()

  let contentWidth = style.getPropertyValue('--content-width')
  if (contentWidth.endsWith('px')) {
    contentWidth = parseInt(contentWidth.replace('px', ''))

    const margin = (window.innerWidth - contentWidth) / 2
    slideover.style.setProperty('--slideover-padding-left', `${margin}px`)
  } else {
    slideover.style.setProperty('--slideover-padding-left', '0px')
  }

  slideover.classList.add('onspace-slideover--active')
  slideover.onspaceRetainScrolling()
}

function hideSlideover() {
  const slideover = document.querySelector('.onspace-slideover')
  if (!slideover) { return }

  slideover.classList.remove('onspace-slideover--active')
  slideover.onspaceReleaseScrolling()
}
