import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// An element which coordinates exploring multiple metrics.
export default class OnspaceMetricExplorer extends CustomHTMLElement {
  /// Sets up the explorer element.
  runConstructor() {
    super.runConstructor()

    this.classList.add('onspace-metric-explorer')
  }

  /// Runs when the element is first connected to the DOM.
  runFirstConnected() {
    super.runFirstConnected()

    const form = this.querySelector('form.onspace-form--simple')
    this.formAction = form.action

    const selectElements = form.querySelectorAll('select-custom')

    this.categorySelectElement = selectElements[0]
    this.categorySelectElement.addEventListener('onspace:select-custom:change', this.categoryChanged.bind(this))

    this.dimensionSelectElement = selectElements[1]
    this.dimensionSelectElement.addEventListener('onspace:select-custom:change', this.dimensionChanged.bind(this))
  }

  /// Responds to changes on the category selector.
  categoryChanged(_event) {
    const category = this.categorySelectElement.value
    const action = `${this.formAction}/${category.replace(/_/g, '-')}`

    window.Turbo.visit(action)
  }

  /// Responds to changes on the dimension selector.
  dimensionChanged(_event) {
    const category = this.categorySelectElement.value
    const dimension = this.dimensionSelectElement.value
    let action // eslint-disable-line prefer-const

    if (dimension) {
      action = `${this.formAction}/${category.replace(/_/g, '-')}/${dimension.replace(/_/g, '-')}`
    } else {
      action = `${this.formAction}/${category.replace(/_/g, '-')}`
    }

    window.Turbo.visit(action)
  }
}

window.customElements.define('onspace-metric-explorer', OnspaceMetricExplorer)
