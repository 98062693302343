import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// An element which controls uploading multiple assets at once.
export class BulkUpload extends CustomHTMLElement {
  /// Sets up the element.
  ///
  /// Locates the required children and adds events where necessary.
  runConstructor() {
    super.runConstructor()

    this.inputElement = document.createElement('input')
    this.inputElement.type = 'file'
    this.inputElement.multiple = true
    this.inputElement.addEventListener('change', this.inputFilesChanged.bind(this))

    this.chooseButton = this.querySelector('a')
    this.chooseButton.addEventListener('click', this.chooseButtonClicked.bind(this))

    this.dragAreaElement = this.querySelector('.onspace-bulk-upload__dragarea')
    this.dragAreaElement.addEventListener('dragenter', this.dragEntered.bind(this))
    this.dragAreaElement.addEventListener('dragleave', this.dragLeft.bind(this))
    this.dragAreaElement.addEventListener('drop', this.dragDropped.bind(this))

    this.formTemplate = this.querySelector('[data-bulk-upload-template]')
    this.formTemplate.remove()

    const formElements = this.querySelectorAll('.onspace-bulk-upload__form')
    formElements.forEach((form) => this.processExistingForm(form))
  }

  ////////// Events

  /// Responds to clicks on the choose button.
  chooseButtonClicked(_event) {
    this.inputElement.click()
  }

  /// Listens for changes to the file input.
  ///
  /// This runs +processAddedFiles+, then clears the input's value.
  inputFilesChanged(_event) {
    const files = Array.from(this.inputElement.files)
    this.processAddedFiles(files)

    this.inputElement.value = ''
  }

  /// Listens for when a file drag enters the dragarea element.
  ///
  /// This adds a class to show that the drop is over the element.
  dragEntered(_event) {
    this.dragAreaElement.classList.add('onspace-bulk-upload__dragarea--active')
  }

  /// Listens for when a file drag leaves the dragarea element.
  ///
  /// This removes the class showing that the drop is over the element.
  dragLeft(_event) {
    this.dragAreaElement.classList.remove('onspace-bulk-upload__dragarea--active')
  }

  /// Listens for when a file is dropped over the dragarea element.
  ///
  /// This collects the dropped files and sends them to +processAddedFiles+. It also calls +dragLeft+ to clear the
  /// dragging UI.
  dragDropped(event) {
    this.dragLeft(event)

    const files = Array.from(event.dataTransfer.files)
    this.processAddedFiles(files)

    event.preventDefault()
    return false
  }

  ////////// Files

  /// Loops through each file for upload.
  processAddedFiles(files) {
    files.forEach((file) => this.addFileForm(file))
  }

  /// Adds the form elements for a new file.
  addFileForm(file) {
    const index = Math.floor(Math.random() * 1000)

    const form = document.createElement('div')
    form.classList.add('onspace-bulk-upload__form')
    form.innerHTML = this.formTemplate.innerHTML
      .replace(/(for|name)="models\$template/g, `$1="models[${index}]`)
      .replace(/models\$template/g, `models_${index}`)

    const inputBlob = form.querySelector('input-blob')
    inputBlob.processAddedFiles([file])

    const inputBlobFile = form.querySelector('input-blob-file')
    inputBlobFile.remove = () => form.remove()

    const titleInput = form.querySelector('input[data-blob-filename]')
    titleInput.value = file.name.replace(/\..*?$/, '')

    this.appendChild(form)
  }

  /// Processes a form element which was provided by the page.
  processExistingForm(form) {
    const inputBlobFile = form.querySelector('input-blob-file')
    inputBlobFile.remove = () => form.remove()
  }
}

window.customElements.define('onspace-bulk-upload', BulkUpload)
