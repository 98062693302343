const submitButtonsByForm = new WeakMap

const PROCESSING_ATTRIBUTE = 'data-onspace-media-processing'
const PARALLEL_UPLOADS = 2

const uploadMeta = document.querySelector('meta[name=onspace-media-upload-url]')
const BLOB_UPLOAD_URL = uploadMeta ? uploadMeta.content : null

/// Captures click events on submit buttons.
///
/// This simply maps these buttons to the form, so it can be looked up later which button triggered a submission.
document.addEventListener('click', (event) => {
  const target = event.target
  if ((target.tagName == 'INPUT' || target.tagName == 'BUTTON') && target.type == 'submit' && target.form) {
    submitButtonsByForm.set(target.form, target)
  }
}, true)

/// Captures form submissions.
///
/// This inserts itself between a user submitting a form, and the form actually being submitted to the server. It checks
/// if there are any new blobs to upload, and performs that operation first. Once the files have all been uploaded, it
/// then resubmits the form, unless there was an error.
document.addEventListener('submit', (event) => {
  const form = event.target

  if (form.hasAttribute(PROCESSING_ATTRIBUTE)) {
    event.preventDefault()
    return false
  }

  const controller = new UploadController(form, PARALLEL_UPLOADS)
  if (!controller.requiresUpload) { return }

  const button = submitButtonsByForm.get(form) || form.querySelector('input[type=submit], button[type=submit]')

  const eventDetail = {
    formSubmission: {
      formElement: form,
      submitter: button
    }
  }

  event.preventDefault()
  form.setAttribute(PROCESSING_ATTRIBUTE, '')
  document.triggerEvent('onspace:media:upload-start', eventDetail)

  controller.start()
    .then(() => {
      form.removeAttribute(PROCESSING_ATTRIBUTE)
      document.triggerEvent('onspace:media:upload-end', eventDetail)

      button.focus()
      button.click()
    })
    .catch(() => {
      form.removeAttribute(PROCESSING_ATTRIBUTE)
      document.triggerEvent('onspace:media:upload-error', eventDetail)
    })
}, true)

/// A class which handles uploading multiple blob files.
export class UploadController {
  /// Sets up the controller.
  ///
  /// This requires 2 arguments:
  /// [form]
  ///   The form which contains blob files to be uploaded.
  /// [parallelUploads]
  ///   The amount of concurrent uploads to allow.
  constructor(form, parallelUploads) {
    this.form = form
    this.parallelUploads = parallelUploads

    this.inputBlobFiles = Array.from(form.querySelectorAll('input-blob-file'))

    this.requiredFiles = this.inputBlobFiles.filter(file => file.requiresUpload)
    this.activeFiles = []
    this.completedFiles = []

    this.requiresUpload = this.requiredFiles.length > 0
  }

  /// Begin the file upload.
  ///
  /// This returns a promise which resolves when all file uploads are completed. If any upload errors for any reason,
  /// the promise will be rejected, but only after all other uploads have completed or errored.
  start() {
    const promise = new Promise((resolve, reject) => {
      this.promiseResolve = resolve
      this.promiseReject = reject
    })

    this.requiredFiles.forEach(file => file.prepareUpload())

    for (let i=0; i<this.parallelUploads; i++) { this.startNextUpload() }

    return promise
  }

  /// Starts the next upload.
  ///
  /// This takes the next queued file and calls it's +upload+ function.
  startNextUpload() {
    if (this.requiredFiles.length === 0) { return }

    const file = this.requiredFiles.shift()
    this.activeFiles.push(file)
    file.upload()
      .then(() => this.uploadCompleted(file))
      .catch(() => this.uploadFailed(file))
  }

  /// Callback for when an upload is finished.
  uploadCompleted(file) {
    const activeIndex = this.activeFiles.indexOf(file)
    this.activeFiles.splice(activeIndex, 1)
    this.completedFiles.push(file)

    this.startNextUpload()
    if (this.activeFiles.length === 0) {
      if (this.uploadError) {
        this.promiseReject()
      } else {
        this.promiseResolve()
      }
    }
  }

  /// Callback for when an upload fails.
  ///
  /// This marks the controller as errored, and then calls +uploadCompleted+.
  uploadFailed(file) {
    this.uploadError = true
    this.uploadCompleted(file)
  }
}

/// A class which manages uploading a single file.
export class Uploader {
  /// Sets up the uploader.
  ///
  /// This requires one argument, the InputBlobFile element which is to be uploaded.
  constructor(fileElement) {
    this.fileElement = fileElement
    this.file = fileElement.file

    const csrfMeta = document.querySelector('meta[name=csrf-token]')
    this.csrfToken = csrfMeta ? csrfMeta.content : null

    this.setupXhr()
  }

  /// Creates and prepares the XMLHttpRequest object for the upload.
  setupXhr() {
    this.xhr = new XMLHttpRequest
    this.xhr.open('POST', BLOB_UPLOAD_URL, true)
    this.xhr.responseType = 'json'

    const encodedFilename = window.encodeURIComponent(this.file.name)
    this.xhr.setRequestHeader('Content-Disposition', `attachment; filename="${encodedFilename}"`)
    if (this.file.type) {
      this.xhr.setRequestHeader('Content-Type', this.file.type)
    } else {
      this.xhr.setRequestHeader('Content-Type', 'application/octet-stream')
    }

    if (this.csrfToken) {
      this.xhr.setRequestHeader('X-CSRF-Token', this.csrfToken)
    }

    this.xhr.addEventListener('load', this.requestDidLoad.bind(this))
    this.xhr.upload.addEventListener('progress', this.requestDidProgress.bind(this))
    this.xhr.addEventListener('error', this.requestDidError.bind(this))
  }

  /// Begins the file upload.
  start() {
    return new Promise((resolve, reject) => {
      this.promiseResolve = resolve
      this.promiseReject = reject

      this.xhr.send(this.file.slice())
    })
  }

  /// Callback for when an upload is partially complete.
  ///
  /// This is called multiple times during an upload.
  requestDidProgress(event) {
    const progress = event.loaded / event.total

    this.fileElement.uploadDidProgress(progress)
  }

  /// Callback for when an upload finishes.
  ///
  /// This is called when the request completes with any status.
  requestDidLoad(_event) {
    const status = this.xhr.status
    if (status < 200 || status >= 300) {
      this.requestDidError()
      return
    }

    const blob = this.xhr.response.blob
    this.fileElement.uploadDidFinish(blob)

    this.promiseResolve()
  }

  /// Callback when an upload request fails.
  requestDidError(_event) {
    let error = null
    if (typeof this.xhr.response === 'object' && typeof this.xhr.response.meta === 'object') {
      error = this.xhr.response.meta.message
    }

    this.fileElement.uploadDidError(error)
    this.promiseReject()
  }
}
