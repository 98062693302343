import RestService from '@onpace/api_service/rest_service'

class OnspaceRestService extends RestService {
  /// Performs a request.
  ///
  /// This overrides RestService.request to automatically inject a CSRF token as required for non-GET requests.
  async request(method, path, options={}) {
    if (method !== 'GET') {
      const csrfElement = document.querySelector('meta[name="csrf-token"]')
      if (csrfElement) {
        if (typeof options.headers !== 'object') { options.headers = {} }

        options.headers['X-CSRF-Token'] = csrfElement.content
      }
    }

    await super.request(method, path, options)
  }
}

const client = new OnspaceRestService({ baseUrl: '' })
export default client
