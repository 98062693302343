const conditionalListeners = {}
let setupOnRender = false
let pageConditionalsLoaded = false

document.addEventListener('turbo:load', (_event) => {
  conditionalListeners['document'] = {}
  pageConditionalsLoaded = false

  setupPageConditionals(document, 'document')
  pageConditionalsLoaded = true

  Object.entries(conditionalListeners.document).forEach((on) => {
    const element = document.querySelector(`#${on[0]}`)
    element.conditionalChangeCallback()
  })
})
document.addEventListener('turbo:frame-load', (event) => {
  const frameId = event.target.id
  conditionalListeners[frameId] = {}

  setupPageConditionals(event.target, frameId)
})

document.addEventListener('turbo:submit-end', (event) => {
  if (!event.detail.success) {
    setupOnRender = true
  }
})
document.addEventListener('turbo:render', (_event) => {
  if (setupOnRender) {
    setupOnRender = false
    setupPageConditionals(document, 'document')
  }
})

/// Finds conditional elements on the page and sets up events.
function setupPageConditionals(pageElement, frameId) {
  pageElement
    .querySelectorAll('[onspace-conditional-on]')
    .forEach((element) => setupConditional(element, frameId))
}

/// Configures an element with a conditional.
///
/// This looks up the element's attributes and configures the conditional for the +on+ and +include+ or +exclude+
/// values. The listener is set up by +setupConditionalListener+, however this function handles callback which checks if
/// the conditional matches the values.
function setupConditional(element, frameId) {
  const conditionalOnId = element.getAttribute('onspace-conditional-on')
  let conditionalValues = []
  if (element.hasAttribute('onspace-conditional-include')) {
    conditionalValues = element.getJsonAttribute('onspace-conditional-include')
  } else if (element.hasAttribute('onspace-conditional-exclude')) {
    conditionalValues = element.getJsonAttribute('onspace-conditional-exclude')
  }

  const result = setupConditionalListener(conditionalOnId, frameId, (values) => {
    const intersection = conditionalValues.filter(cv => values.includes(cv))
    if (intersection.length > 0) {
      element.setAttribute('onspace-conditional-match', '')
    } else {
      element.removeAttribute('onspace-conditional-match')
    }
  })

  if (result === 'notfound') {
    element.setAttribute('onspace-conditional-missing', '')
  }
}

/// Sets up listeners on a conditional's dependent elements.
///
/// This first finds the dependent elements - of which there can be more than one depending on the element type. If the
/// elements belong to an InputArray, a listener is added to listen for when new fields are added. It then sets up a
/// listener on each dependent element using +setupElementListener+.
function setupConditionalListener(on, frameId, callback) {
  const onElements = Array.from(document.querySelectorAll(`#${on}`))
  if (onElements.length === 0) { return 'notfound' }

  if (Array.isArray(conditionalListeners[frameId][on])) {
    conditionalListeners[frameId][on].push(callback)
    if (pageConditionalsLoaded) {
      onElements[0].conditionalChangeCallback()
    }
    return
  } else {
    conditionalListeners[frameId][on] = [callback]
  }

  const lastElement = onElements.slice(-1)[0]

  const elementCallback = (value) => {
    conditionalListeners[frameId][on].forEach((callback) => callback(value))
    lastElement.triggerEvent(`onspace:conditional:${on}:change`)
  }

  let inputArray = null
  if (lastElement.type === 'checkbox') {
    inputArray = lastElement.closest('.onspace-button-group') || lastElement.closest('.onspace-button-list') || lastElement.closest('select-custom')
  } else {
    inputArray = lastElement.closest('input-array')
    if (inputArray) {
      inputArray.addEventListener('input-array:add-control', (event) => {
        const addedElement = event.detail.querySelector(`#${on}`)
        setupElementListener(addedElement, on, inputArray, elementCallback)
      })
    }
  }

  onElements.forEach((onElement) => {
    setupElementListener(onElement, on, inputArray, elementCallback)
  })

  onElements[0].conditionalChangeCallback()
}

/// Determines which listener to use based on the element.
function setupElementListener(element, elementId, inputArray, callback) {
  switch (element.tagName) {
  case 'INPUT':
    switch(element.type) {
    case 'date':
      setupDateChangeListener(element, elementId, inputArray, callback)
      break
    case 'datetime-local':
      setupDateTimeChangeListener(element, elementId, inputArray, callback)
      break
    case 'checkbox':
      setupRadioCheckboxChangeListener(element, elementId, inputArray, callback)
      break
    case 'radio':
      setupRadioCheckboxChangeListener(element, elementId, inputArray, callback)
      break
    default:
      setupTextChangeListener(element, elementId, inputArray, callback)
    }
    break
  case 'SELECT':
    setupGenericChangeListener(element, elementId, inputArray, callback)
    break
  }
}

/// Sets up a listener for a dependant element which doesn't fit any of the other specific functions.
///
/// This simply responds to +change+ events. In array mode, the callback responds with all values.
function setupGenericChangeListener(element, elementId, inputArray, callback) {
  if (inputArray) {
    element.conditionalChangeCallback = () => {
      const elements = Array.from(inputArray.querySelectorAll(`#${elementId}`))
      const values = elements.map((el) => el.value)
      callback(values)
    }
  } else {
    element.conditionalChangeCallback = () => callback([element.value])
  }

  element.addEventListener('change', element.conditionalChangeCallback)
}

/// Sets up a listener for dependant checkbox or radio button elements.
///
/// This responds to +change+ events. In array mode, the callback responds with all values which are marked as
/// +:checked+.
function setupRadioCheckboxChangeListener(element, elementId, inputArray, callback) {
  if (inputArray) {
    element.conditionalChangeCallback = () => {
      const elements = Array.from(inputArray.querySelectorAll(`#${elementId}:checked`))
      const values = elements.map((el) => el.value)
      callback(values)
    }
  } else {
    element.conditionalChangeCallback = () => {
      const selectedElement = document.querySelector(`#${elementId}:checked`)
      callback([selectedElement.value])
    }
  }

  element.addEventListener('change', element.conditionalChangeCallback)
}

/// Sets up a listener for dependent date elements.
///
/// This responds to +change+ events. In array mode, the callback responds with all values. All values (array or not)
/// are processed so the date format matches the same ISO8601 format used by Rails.
function setupDateChangeListener(element, elementId, inputArray, callback) {
  if (inputArray) {
    element.conditionalChangeCallback = () => {
      const elements = Array.from(inputArray.querySelectorAll(`#${elementId}`))
      const values = elements.map((el) => {
        if (el.valueAsNumber) {
          const date = new Date(el.valueAsNumber)
          return date.toISODateString()
        } else {
          return null
        }
      })
      callback(values)
    }
  } else {
    element.conditionalChangeCallback = () => {
      const timestamp = element.valueAsNumber
      let output = null
      if (timestamp) {
        const date = new Date(timestamp)
        output = date.toISODateString()
      }
      callback([output])
    }
  }

  element.addEventListener('change', element.conditionalChangeCallback)
}

/// Sets up a listener for dependent datetime elements.
///
/// This responds to +change+ events. In array mode, the callback responds with all values. All values (array or not)
/// are processed so the datetime format matches the same ISO8601 format used by Rails.
function setupDateTimeChangeListener(element, elementId, inputArray, callback) {
  if (inputArray) {
    element.conditionalChangeCallback = () => {
      const elements = Array.from(inputArray.querySelectorAll(`#${elementId}`))
      const values = elements.map((el) => {
        if (el.valueAsNumber) {
          const date = new Date(el.valueAsNumber)
          return date.toISOString().split('.')[0] + 'Z'
        } else {
          return null
        }
      })
      callback(values)
    }
  } else {
    element.conditionalChangeCallback = () => {
      const timestamp = element.valueAsNumber
      let output = null
      if (timestamp) {
        const date = new Date(timestamp)
        output = date.toISODateString().split('.')[0] + 'Z'
      }
      callback([output])
    }
  }

  element.addEventListener('change', element.conditionalChangeCallback)
}

/// Sets up a listener for dependent text elements.
///
/// This responds to +input+ events. In array mode, the callback responds with all values.
function setupTextChangeListener(element, elementId, inputArray, callback) {
  if (inputArray) {
    element.conditionalChangeCallback = () => {
      const elements = Array.from(inputArray.querySelectorAll(`#${elementId}`))
      const values = elements.map((el) => el.value)
      callback(values)
    }
  } else {
    element.conditionalChangeCallback = () => callback([element.value])
  }

  element.addEventListener('input', element.conditionalChangeCallback)
}
