import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// An element which allows inputs to be reused with an array of values.
///
/// This works by allowing elements to be added, removed and rearranged on the page. When adding a new element, a new
/// InputArrayControl is created, with it's contents coming from the template, when removing an element, the control is
/// removed from the dom, and when rearranging, the items are simply reordered in the DOM.
///
/// You must set the +name+ attribute on this class to the parameter name in the form, and the following children at
/// root level:
/// - An element with the attribute +data-input-array-template+.
///
/// In order to add controls, you can optionally provide an anchor element at root level. It will be subscribed to for
/// click events which will add a new control.
export default class InputArray extends CustomHTMLElement {
  /// Sets up the input array element.
  ///
  /// Locates the required children and adds events where necessary.
  runConstructor() {
    super.runConstructor()

    this.name = this.getAttribute('name')
    this.controlContentTemplate = this.querySelector('[data-input-array-template]')
    this.controlContentTemplate.remove()

    this.addButton = this.querySelector(':scope > a')
    if (this.addButton) {
      this.addButton.addEventListener('click', this.addControl.bind(this))
    }

    this.addEventListener('dragover', this.draggedOver.bind(this))
  }

  /// Detects whether this element is disabled, based on a parent fieldset.
  get disabled() {
    const fieldset = this.closest('fieldset')
    if (fieldset) {
      return fieldset.disabled
    } else {
      return false
    }
  }

  ////////// Controls

  /// Retrieves the control elements from the DOM.
  get controls() {
    return this.querySelectorAll('input-array-control')
  }

  /// Creates and inserts a control into the DOM.
  addControl(_event) {
    if (this.disabled) { return }

    const control = new InputArrayControl(this.controlContentTemplate)
    this.insertBefore(control, this.addButton)

    this.triggerEvent('input-array:add-control', control)
  }

  ////////// Ordering

  /// Initiates a drag.
  ///
  /// When a control begins a drag, it calls this function to notify this element and setup for the drag.
  beginDrag(control) {
    this.draggingControl = control
    this.dragControls = Array.from(this.controls)
    this.dragIndex = this.dragControls.indexOf(this.draggingControl)

    const offset = document.documentElement.scrollTop - document.documentElement.clientTop
    this.dragCenters = this.dragControls.map(i => {
      const rect = i.getBoundingClientRect()
      return rect.top + (rect.height / 2) + offset
    })
  }

  /// Finishes a drag.
  ///
  /// When a control ends a drag, it calls this function to notify this element and cleanup from the drag.
  endDrag() {
    this.draggingControl = null
    this.dragControls = null
    this.dragCenters = null
    this.dragIndex = null
  }

  /// Updates from a current drag.
  ///
  /// This function is called during a drag, when the control is above this element. The position of the dragged control
  /// relative to the other controls is calculated, and its position in the DOM is changed if necessary.
  draggedOver(event) {
    if (!this.draggingControl) { return }
    event.preventDefault()

    let i = -1
    do {
      i += 1
    } while(i < this.dragControls.length && event.pageY > this.dragCenters[i])

    const insertIndex = i
    if (i > this.dragIndex) {
      i -= 1
    }

    if (this.dragIndex != i) {
      this.dragIndex = i
      this.insertBefore(this.draggingControl, this.children[insertIndex])
    }
  }
}

/// An element which operates a single control within an InputArray.
///
/// This is responsible for the control-specific functionality of it's parent element. It does not require any children,
/// however you can include the following:
/// - An anchor with the attribute +data-input-array-drag+, which is required for reordering.
/// - An anchor with the attribute +data-input-array-remove+, which is required for removal.
export class InputArrayControl extends CustomHTMLElement {
  /// Sets up the control element.
  ///
  /// Locates the children and adds events where necessary.
  runConstructor(template) {
    super.runConstructor()

    if (template) {
      const index = Math.floor(Math.random() * 1000)
      this.innerHTML = template.innerHTML.replace(/\[\$idx\]/g, `[${index}]`)
    }

    this.dragButton = this.querySelector('[data-input-array-drag]')
    if (this.dragButton) {
      this.draggable = true
      this.addEventListener('dragstart', this.dragStarted.bind(this))
      this.addEventListener('dragend', this.dragEnded.bind(this))
    }

    this.removeButton = this.querySelector('[data-input-array-remove]')
    if (this.removeButton) {
      this.removeButton.addEventListener('click', this.removeControl.bind(this))
    }
  }

  /// Detects whether this element is disabled, based on a parent fieldset.
  get disabled() {
    const fieldset = this.closest('fieldset')
    if (fieldset) {
      return fieldset.disabled
    } else {
      return false
    }
  }

  ////////// Array

  /// Retrieves the parent InputArray element.
  get inputArray() {
    return this.parentElement
  }

  /// Removes this control from the DOM.
  removeControl(_event) {
    if (this.disabled) { return }

    this.remove()
  }

  ////////// Ordering

  /// Initiates a drag.
  ///
  /// This is called when a user picks up the drag button. It notifies the parent InputArray and prepares itself for a
  /// drag.
  dragStarted(event) {
    if (this.disabled) {
      event.preventDefault()
      return
    }

    const eventElement = document.elementFromPoint(event.clientX, event.clientY)
    if (!eventElement || !eventElement.closest('[data-input-array-drag]')) { return event.preventDefault() }

    this.inputArray.beginDrag(this)

    setTimeout(() => this.style.opacity = 0)
  }

  /// Finishes a drag.
  ///
  /// This is called when a user drops the dragged element. It notifies the parent InputArray and cleans itself up from
  /// the drag.
  dragEnded(_event) {
    if (this.disabled) { return }

    this.inputArray.endDrag()

    this.style.opacity = ''
  }
}

window.customElements.define('input-array', InputArray)
window.customElements.define('input-array-control', InputArrayControl)
