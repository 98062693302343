// cspell:words onwait

////////// Attributes //////////

/// Gets the element's attribute as a boolean value.
///
/// This will return true if the attribute exists on the element, and if the value is anything other than +false+.
HTMLElement.prototype.getBooleanAttribute = function(key) {
  return this.hasAttribute(key) && this.getAttribute(key) !== 'false'
}

/// Gets the element's attribute as a date value.
///
/// If the attribute value is missing or falsy this returns null. If the attribute is not a valid Date, passing it as an
/// argument to new Date() will return an Invalid Date object, which  responds to isNaN, thus we can protect against
/// invalid dates, and return null instead.
HTMLElement.prototype.getDateAttribute = function(key) {
  if (!this.getAttribute(key)) { return null }

  const date = new Date(this.getAttribute(key))

  return isNaN(date) ? null : date
}

/// Gets the element's attribute as a json value.
///
/// If the attribute value is missing, or not json, this returns null.
HTMLElement.prototype.getJsonAttribute = function(key) {
  return JSON.parse(this.getAttribute(key)) || null
}

/// Gets the element's attribute as a integer value.
///
/// If the attribute value is missing, or not a number, this returns null.
HTMLElement.prototype.getIntegerAttribute = function(key) {
  const val = parseInt(this.getAttribute(key), 10)
  if (typeof val === 'number' && !Number.isNaN(val)) {
    return val
  } else {
    return null
  }
}

/// Gets the element's attribute as a float value.
///
/// If the attribute value is missing, or not a number, this returns null.
HTMLElement.prototype.getFloatAttribute = function(key) {
  const val = parseFloat(this.getAttribute(key), 10)
  if (typeof val === 'number' && !Number.isNaN(val)) {
    return val
  } else {
    return null
  }
}

////////// Presses //////////

/// Adds custom press and long press listeners for mouse and touch devices.
///
/// This takes the following options:
/// [onPress]
///   A callback to run when a press is successful.
/// [onPressBegan]
///   A callback to run when a press begins.
/// [onPressEnded]
///   A callback to run when a press ends.
/// [onLongPress]
///   A callback to run when a long press is successful.
/// [longPressDuration]
///   The time in milliseconds to wait before triggering a long press.
///
/// Note that this uses the +mousedown+/+mouseup+ and +touchstart+/+touchend+ events, rather than +click+.
HTMLElement.prototype.addPressEventListeners = function({ onPress = null, onPressBegan = null, onPressMoved = null, onPressEnded = null, onLongPress = null, longPressDuration = 5000 } = {}) {
  this.pressStarted = function(event) {
    window.clearTimeout(this.pressTouchTimeout)
    this.pressClickActive = true
    this.pressActive = true
    this.pressActiveLong = false

    event.stopPropagation()

    this.addDocumentBoundEventListener('mousemove', this.pressMoved)
    this.addDocumentBoundEventListener('touchmove', this.pressMoved)
    this.addDocumentBoundEventListener('mouseup', this.pressEnded)
    this.addEventListener('touchend', this.pressEnded.bind(this))

    if (onLongPress) {
      this.pressTouchTimeout = window.setTimeout(() => {
        if (this.pressActive) {
          this.pressActiveLong = true
          onLongPress(event)
        }
      }, longPressDuration)
    }

    if (onPressBegan) { onPressBegan(event) }
  }
  this.pressMoved = function(_event) {
    window.clearTimeout(this.pressTouchTimeout)
    this.pressTouchTimeout = null
    this.pressActive = false

    if (onPressMoved) { onPressMoved(event) }
  }
  this.pressCancelled = function(_event) {
    window.clearTimeout(this.pressTouchTimeout)
    this.pressTouchTimeout = null
    this.pressActive = false

    if (onPressEnded) { onPressEnded(event) }
  }
  this.pressEnded = function(event) {
    window.clearTimeout(this.pressTouchTimeout)
    this.pressTouchTimeout = null

    this.removeDocumentBoundEventListener('mousemove')
    this.removeDocumentBoundEventListener('touchmove')
    this.removeDocumentBoundEventListener('mouseup')
    this.removeDocumentBoundEventListener('touchend')

    if (this.pressActiveLong) {
      event.preventDefault()
    }

    this.pressActive = false

    if (onPressEnded) { onPressEnded(event) }
  }

  this.pressClicked = function(event) {
    event.stopPropagation()
    if (onPress && this.pressClickActive && !this.pressActiveLong) { onPress(event) }

    this.pressClickActive = false
  }

  this.addEventListener('mousedown', this.pressStarted.bind(this))
  this.addEventListener('touchstart', this.pressStarted.bind(this))
  this.addEventListener('click', this.pressClicked.bind(this))
}

////////// Styles //////////

/// Gets the computed style for an element
HTMLElement.prototype.getComputedStyle = function() {
  return window.getComputedStyle(this)
}

////////// Scrolling //////////

// Element/Parent Scrolling

/// Determines if an element can be scrolled in either axis.
Object.defineProperty(HTMLElement.prototype, 'scrollable', {
  get: function scrollable() {
    return this.scrollableX || this.scrollableY
  }
})

/// Determines if an element can be scrolled horizontally.
Object.defineProperty(HTMLElement.prototype, 'scrollableX', {
  get: function scrollable() {
    if (this !== document.documentElement) {
      const overflowX = this.getComputedStyle().overflowX
      if (overflowX !== 'auto' && overflowX !== 'scroll') {
        return false
      }
    }
    return this.scrollWidth > this.clientWidth
  }
})

/// Determines if an element can be scrolled vertically.
Object.defineProperty(HTMLElement.prototype, 'scrollableY', {
  get: function scrollable() {
    if (this !== document.documentElement) {
      const overflowY = this.getComputedStyle().overflowY
      if (overflowY !== 'auto' && overflowY !== 'scroll') {
        return false
      }
    }
    return this.scrollHeight > this.clientHeight
  }
})

/// Determines if an element's parent can be scrolled in either axis.
Object.defineProperty(HTMLElement.prototype, 'scrollableParent', {
  get: function scrollableParent() {
    let node = this.parentNode
    while (node && !node.scrollable) {
      node = node.parentNode
    }
    return node
  }
})

const easeOut = function(t) { /// :nodoc:
  return 1 - (--t) * t * t * t
}
const ELEMENT_SCROLL_PADDING_REM = 5

/// Scrolls an element to a particular position within the viewport.
///
/// You must provide the +endOffsetX+ and +endOffsetY+ arguments, which indicate the final X and Y positions of the
/// element within the viewport. You can also provide the following options:
/// [animated]
///   Specifies whether the scrolling is animated. Animations use an ease-out timing function. This is false by default.
/// [duration]
///   The length of time for the animation in milliseconds. This is 300 by default.
HTMLElement.prototype.scroll = function(endOffsetX, endOffsetY, options = {}) {
  const startOffsetX = this.scrollLeft
  let deltaX
  if (endOffsetX) {
    deltaX = endOffsetX - startOffsetX
  } else {
    deltaX = startOffsetX
  }

  const startOffsetY = this.scrollTop
  let deltaY
  if (endOffsetY) {
    deltaY = endOffsetY - startOffsetY
  } else {
    deltaY = startOffsetY
  }

  if (options.animated) {
    const duration = options.duration || 300

    let startTime = null

    const step = (timestamp) => {
      startTime = startTime || timestamp
      const currentTime = timestamp - startTime
      let progress = Math.min(1, Math.max(0, currentTime / duration))
      progress = easeOut(progress)

      this.scrollLeft = startOffsetX + (progress * deltaX)
      this.scrollTop = startOffsetY + (progress * deltaY)

      if (currentTime < duration) {
        window.requestAnimationFrame(step)
      }
    }
    window.requestAnimationFrame(step)
  } else {
    this.scrollLeft = endOffsetX
    this.scrollTop = endOffsetY
  }
}

/// Scrolls an element into the viewport.
///
/// Only scrolls the minimum amount required to make the element fully visible, plus some padding.
HTMLElement.prototype.scrollIn = function(options = {}) {
  const parent = this.scrollableParent
  if (!parent) {
    return
  }

  let offsetX = null
  let offsetY = null

  const paddingRem = options.paddingRem || ELEMENT_SCROLL_PADDING_REM
  const padding = paddingRem * parseFloat(getComputedStyle(document.documentElement).fontSize)

  if (parent.scrollableX) {
    const parentWidth = parent.offsetWidth
    const parentBoundLeft = parent.scrollLeft
    const parentBoundRight = parentBoundLeft + parentWidth

    const left = this.offsetLeft
    const right = left + this.offsetWidth

    if (right > parentBoundRight - padding) {
      offsetX = right + padding - parentWidth
    } else if (left < parentBoundLeft + padding) {
      offsetX = left - padding
    }
  }

  if (parent.scrollableY) {
    const parentHeight = parent.offsetHeight
    const parentBoundTop = parent.scrollTop
    const parentBoundBottom = parentBoundTop + parentHeight

    const top = this.offsetTop
    const bottom = top + this.offsetHeight

    if (bottom > parentBoundBottom - padding) {
      offsetY = bottom + padding - parentHeight
    } else if (top < parentBoundTop + padding) {
      offsetY = top - padding
    }
  }

  if (offsetX || offsetY) {
    parent.scroll(offsetX, offsetY, options)
  }
}

/// Scrolls an element to the center of the viewport.
///
/// See HTMLElement.prototype.scroll for available options.
HTMLElement.prototype.scrollInCenter = function(options = {}) {
  const parent = this.scrollableParent
  if (!parent) {
    return
  }

  let offsetX = null
  let offsetY = null

  if (parent.scrollableX) {
    const parentCenter = parent.offsetWidth / 2
    const elementCenter = this.offsetLeft + (this.offsetWidth / 2)

    offsetX = elementCenter - parentCenter
  }

  if (parent.scrollableY) {
    const parentCenter = parent.offsetHeight / 2
    const elementCenter = this.offsetTop + (this.offsetHeight / 2)

    offsetY = elementCenter - parentCenter
  }

  if (offsetX || offsetY) {
    parent.scroll(offsetX, offsetY, options)
  }
}

////////// Element Subclass //////////

/// Customised subclass of HTMLElement.
///
/// This is a workaround for an issue in Chrome. When an element is constructed, the inner contents are not always
/// available. This waits for +innerHTML+ to become available before running setup.
///
/// In superclasses, you should use the following callbacks instead of the default:
/// - +runConstructor+ instead of +constructor+
/// - +runConnected+ instead of +connectedCallback+
///
/// In addition, you can also use +runFirstConnected+, which will only be called once, the first time the element is
/// connected.
export default class CustomHTMLElement extends HTMLElement {
  constructor(...args) {
    super()

    this.constructorArguments = args

    const elementType = window.customElements.get(this.tagName.toLowerCase()).name
    const trace = (new Error()).stack.split('\n')

    let traceIndex = trace.length - 1
    if (trace[traceIndex] === '') {
      while(!trace[traceIndex].includes('CustomElementConstructor') && traceIndex > 0) {
        traceIndex--
      }
      if (traceIndex > 0) {
        traceIndex--
      }
    }

    if (trace[traceIndex].includes(elementType)) {
      // created from the dom
    } else if (this._checkBody()) {
      // created by js, we can safely run the setup code
      this._setup = true
      this.runConstructor.apply(this, this.constructorArguments) // forward constructor arguments to setup function
    }
  }

  connectedCallback() {
    if (!this._setup) {
      if (!this._checkBody()) { return }

      this._setup = true

      if (this.innerHTML && this.innerHTML.length && this.runConstructor) {
        this.runConstructor(...this.constructorArguments)
        this.runConnected()
      } else {
        const onwait = () => {
          if (this.runConstructor) {
            this.runConstructor(...this.constructorArguments)
            this.runConnected()
          } else {
            setTimeout(onwait, 100)
          }
        }
        setTimeout(onwait)
      }
    } else if (this.runConnected) {
      this.runConnected()
    }
  }

  disconnectedCallback() {
    this.runDisconnected()
  }

  _checkBody() {
    let parentNode = this.parentNode
    while (parentNode !== null) {
      if (parentNode.tagName === 'BODY') {
        if (parentNode === document.body) {
          return true
        } else {
          return false
        }
      }

      parentNode = parentNode.parentNode
    }

    return true
  }

  runConstructor() {}
  runConnected() {
    if (!this.hasConnected) {
      this.hasConnected = true

      this.runFirstConnected(...this.constructorArguments)
    }
  }
  runFirstConnected() {}
  runDisconnected() {}
}
