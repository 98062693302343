import CustomHTMLElement from '../../components/html_element'
import DropDown from '../dropdown'

/// An element which wraps a filter field.
///
/// This element inherits from DropDown, utilising it's two parts:
/// - The dropdown's anchor is the field display. It shows the current filter values and responds to clicks.
/// - The dropdown's menu contains controls to change the filter value. This may include one or many modes, which allow
///   the filtering to be customised depending on the underlying data type.
export default class FilterField extends DropDown {
  /// Sets up the filter field element.
  ///
  /// Locates required children and creates new elements, adding events where necessary.
  runConstructor() {
    super.runConstructor()

    this.classList.add('drop-down')

    const inputName = this.getAttribute('name')
    this.modeInputName = `${inputName}[mode]`
    this.valueInputName = `${inputName}[values][]`
    this.valuesElement = this.querySelector('.filter-field__anchor__values')

    this.singleValue = this.getBooleanAttribute('single-value')

    this.setupModes()
  }

  //////////

  /// Shows the menu.
  ///
  /// This overrides DropDown.showMenu to do the following:
  /// - Activate the current mode after the menu is open.
  showMenu() {
    super.showMenu()

    this.activateCurrentMode()
  }

  ////////// Modes

  /// Sets up the mode elements contained within this filter field.
  ///
  /// If there is only one mode, this just adds a title. If there are multiple, this adds a control to switch between
  /// them.
  setupModes() {
    this.modeInput = document.createElement('input')
    this.modeInput.type = 'hidden'
    this.modeInput.name = this.modeInputName
    this.append(this.modeInput)

    this.modeElements = Array.from(this.menu.children)
    this.modeElements.forEach((modeElement) => {
      modeElement.key = modeElement.getAttribute('key')
      modeElement.name = modeElement.getAttribute('name')
    })

    if (this.modeElements.length === 1) {
      this.currentMode = this.modeElements[0]

      const headingElement = document.createElement('div')
      headingElement.classList.add('filter-field__menu__title')
      headingElement.innerText = this.currentMode.name
      this.menu.prepend(headingElement)
    } else {
      const buttonGroup = document.createElement('div')
      buttonGroup.classList.add('onspace-button-group')
      this.menu.prepend(buttonGroup)

      this.modeElements.forEach((modeElement) => {
        const button = document.createElement('div')
        button.classList.add('onspace-button')
        button.classList.add('onspace-button--color-border')
        button.classList.add('onspace-button--outline')
        button.innerText = modeElement.name
        buttonGroup.append(button)

        modeElement.button = button
        button.addEventListener('click', (_event) => this.currentMode = modeElement)
      })

      const defaultMode = this.getAttribute('mode')
      if (defaultMode) {
        this.currentMode = this.modeElements.find((m) => m.key === defaultMode)
      } else {
        this.currentMode = this.modeElements[0]
      }
    }
  }

  /// Retrives the current mode.
  get currentMode() {
    return this._currentMode
  }

  /// Sets the current mode.
  ///
  /// This will update the class of the new mode (and previous mode if necessary), then activate it.
  set currentMode(mode) {
    if (!mode) { return }

    if (this._currentMode) {
      this._currentMode.classList.remove('filter-field__mode--current')

      if (this._currentMode.button) {
        this._currentMode.button.classList.add('onspace-button--outline')
      }
    }

    this._currentMode = mode
    this._currentMode.classList.add('filter-field__mode--current')
    this.modeInput.value = mode.key

    if (this.menuActive) {
      this._currentMode.activate()
    }
    if (mode.button) {
      mode.button.classList.remove('onspace-button--outline')
    }
  }

  /// Tells the current mode to activate itself.
  activateCurrentMode() {
    this.currentMode.activate()
    if (this.currentMode.button) {
      this.currentMode.button.scrollInCenter()
    }
  }

  ////////// Values

  /// Locates all value elements.
  get valueElements() {
    return Array.from(this.valuesElement.children)
  }

  /// Adds a new value element.
  ///
  /// If the current value was set using +updateValue+, that value is removed.
  addValue(value, title) {
    if (this.updatedValue || this.singleValue) {
      this.valueElements.forEach((element) => element.remove())
    }

    this.appendValue(value, title)
    this.updatedValue = false
  }

  /// Updates an existing value element.
  ///
  /// This removes all existing values and creates a new one using +appendValue+. This should only be used for modes which
  /// only support a single value.
  updateValue(value, title) {
    this.valueElements.forEach((element) => element.remove())
    this.appendValue(value, title)

    this.updatedValue = true
  }

  /// Creates a new FilterFieldValue and adds it to the anchor's DOM.
  appendValue(value, title) {
    const valueElement = new FilterFieldValue(this.valueInputName, value, title)
    this.valuesElement.append(valueElement)
  }

  /// Finds a value element with the given value and removes it from the DOM.
  removeValue(value) {
    const valueElement = this.valueElements.find((v) => v.value == value)
    if (valueElement) {
      valueElement.remove()
    }
  }

  /// Callback for when a value element is removed.
  ///
  /// This notifies the current mode.
  valueElementRemoved(valueElement) {
    this.currentMode.valueElementRemoved(valueElement)
  }

  //////////

  /// Callback for when a turbo frame element finishes loading.
  ///
  /// This overrides DropDown.turboFrameLoaded and forwards the event to each mode element, if supported.
  turboFrameLoaded(event) {
    super.turboFrameLoaded(event)

    this.modeElements.forEach((mode) => {
      if (typeof mode.turboFrameLoaded === 'function') {
        mode.turboFrameLoaded(event)
      }
    })
  }
}

/// An element which contains a selected filter value.
///
/// These are created and modified by the parent FilterField as filters are changed/added.
export class FilterFieldValue extends CustomHTMLElement {
  /// Sets up a new value element.
  ///
  /// This will automatically add a remove icon. You can optionally pass a +value+ and +title+ in the constructor which
  /// will add a hidden input and span element. When not passing, you should set these up manually.
  runConstructor(name, value, title) {
    super.runConstructor()

    if (value) {
      this.value = value

      const hiddenInput = document.createElement('input')
      hiddenInput.type = 'hidden'
      hiddenInput.name = name
      hiddenInput.value = value
      this.append(hiddenInput)

      if (!title) { title = value }

      const titleSpan = document.createElement('span')
      titleSpan.innerText = title
      this.append(titleSpan)
    } else {
      const hiddenInput = this.querySelector('input[type=hidden]')
      this.value = hiddenInput.value
    }

    const removeIcon = SVGElement.createOnspaceSpritemapSvg('onspace/icon_cross')
    removeIcon.addEventListener('click', this.removeClicked.bind(this))
    this.append(removeIcon)
  }

  /// Locates the parent FilterField element in the DOM tree.
  get filterFieldElement() {
    return this.closest('filter-field')
  }

  /// Detects whether this element is disabled, based on a parent fieldset.
  get disabled() {
    const fieldset = this.closest('fieldset')
    if (fieldset) {
      return fieldset.disabled
    } else {
      return false
    }
  }

  /// Callback for clicking the remove icon.
  ///
  /// This removes this element from the DOM, unless disabled. Propagation is always prevented, so the containing
  /// dropdown doesn't receive any click events.
  removeClicked(event) {
    event.stopPropagation()

    if (this.disabled) {
      event.preventDefault()
      return false
    }

    const filterFieldElement = this.filterFieldElement
    this.remove()
    filterFieldElement.valueElementRemoved(this)
  }
}

////////// Modes

/// An element which is responsible for a filter mode.
///
/// This is an abstract class and should not ever be used directly.
class FilterFieldMode extends CustomHTMLElement {
  /// Sets up a new filter mode element.
  runConstructor() {
    super.runConstructor()

    this.classList.add('filter-field__mode')
  }

  /// Locates the parent FilterField element in the DOM tree.
  get filterFieldElement() {
    return this.closest('filter-field')
  }

  /// Detects whether this element is disabled, based on a parent fieldset.
  get disabled() {
    const fieldset = this.closest('fieldset')
    if (fieldset) {
      return fieldset.disabled
    } else {
      return false
    }
  }

  //////////

  /// Become the active mode.
  ///
  /// This should be overridden in a subclass to update the UI and focus. The default implementation does nothing.
  activate() {}

  /// Callback for when a value element is removed in the parent.
  ///
  /// This should be overridden in a subclass to update the state. The default implementation does nothing.
  valueElementRemoved(_valueElement) {}
}

/// An element which is responsible for a filter mode for a set of discrete choices.
///
/// This mode contains a list of checkboxes, and will add and remove values from the parent filter field.
class FilterFieldModeEnum extends FilterFieldMode {
  /// Sets up a new enum filter mode element.
  runConstructor() {
    super.runConstructor()

    this.setupOptions()
    this.setupSearching()
  }

  /// Locates the checkboxes and registers change events for them.
  setupOptions() {
    this.options = this.querySelectorAll('.onspace-button-list > .label--onspace-button')
    this.options.forEach((option) => {
      const title = option.querySelector('span').innerText
      const checkbox = option.querySelector('input[type=checkbox]')

      checkbox.addEventListener('change', (_event) => {
        if (checkbox.checked) {
          this.filterFieldElement.addValue(checkbox.value, title)
        } else {
          this.filterFieldElement.removeValue(checkbox.value, title)
        }

        this.updateCheckboxStates()
      })

      option.checkbox = checkbox
      option.searchText = title.toLowerCase()
    })
  }

  /// Callback for when a turbo frame element finishes loading.
  ///
  /// This responds by setting up checkbox elements.
  turboFrameLoaded(_event) {
    this.setupOptions()
    this.updateCheckboxStates()

    if (this.searchInput) {
      this.updateSearchedElements()
    }
  }

  ////////

  /// Become the active mode.
  ///
  /// This overrides FilterFieldMode.activate, and updates the checkbox state.
  activate() {
    this.updateCheckboxStates()

    if (this.searchInput) {
      this.clearSearching()
      this.searchInput.focus()
    }
  }

  /// Callback for when a value element is removed in the parent.
  ///
  /// This overrides FilterFieldMode.activate, and updates the checkbox state.
  valueElementRemoved() {
    this.updateCheckboxStates()
  }

  /// Update the checked state of all checkboxes.
  ///
  /// This retrieves the current set of values from the parent filter field, and synchronises them with the state of the
  /// checkboxes.
  updateCheckboxStates() {
    const valueElements = this.filterFieldElement.valueElements
    const values = valueElements.map((v) => v.value)

    this.options.forEach((option) => {
      if (values.includes(option.checkbox.value)) {
        option.checkbox.checked = true
      } else {
        option.checkbox.checked = false
      }
    })
  }

  ////////// Searching

  /// Locates and configures searching elements.
  setupSearching() {
    this.searchInput = this.querySelector('.filter-field__mode__search__input')
    if (this.searchInput) {
      this.searchInput.addEventListener('input', this.updateSearchedElements.bind(this))
    }
  }

  /// Filters elements based on the current search.
  updateSearchedElements() {
    const text = this.searchInput.value.toLowerCase()

    if (text.length > 0) {
      this.options.forEach((option) => {
        option.style.display = option.searchText.includes(text) ? '' : 'none'
      })
    } else {
      this.options.forEach((option) => {
        option.style.display = ''
      })
    }
  }

  /// Clears the current search.
  clearSearching() {
    if (this.searchInput) {
      this.searchInput.value = ''
      this.updateSearchedElements()
    }
  }
}

/// An element which is responsible for a filter mode for a single input.
///
/// This mode contains a single input and adds new values when the return key is pressed.
class FilterFieldModeInput extends FilterFieldMode {
  /// Sets up a new input filter mode element.
  ///
  /// This locates the input, and registers events to it.
  runConstructor() {
    super.runConstructor()

    this.input = this.querySelector('input')
    this.input.addEventListener('keypress', this.keyPressed.bind(this))

    this.addButton = this.querySelector('a')
    this.addButton.addEventListener('click', this.addClicked.bind(this))
  }

  /// Become the active mode.
  ///
  /// This overrides FilterFieldMode.activate, and focuses the input.
  activate() {
    this.clearInputValue()
    this.input.focus()
  }

  //////////

  /// Responds to keypress events on the input.
  ///
  /// This captures key presses for enter and calls +useInputValue+.
  keyPressed(event) {
    if (this.disabled) { return }

    if (event.key === 'Enter') {
      event.stopPropagation()
      event.preventDefault()

      if (event.repeat) { return }

      this.useInputValue()
    }
  }

  /// Responds to click events on the add button.
  ///
  /// This calls +useInputValue+ when not disabled.
  addClicked(event) {
    if (this.disabled) {
      event.preventDefault()
      event.stopPropagation()
      return
    }

    this.useInputValue()
  }

  /// Uses the current input value as a filter value.
  ///
  /// This takes the current input value and sends it to the parent FilterField using +addValue+. The input is then
  /// cleared.
  useInputValue() {
    const value = this.input.value
    if (!value || value.length === 0) { return }

    this.filterFieldElement.addValue(value)
    this.clearInputValue()
  }

  /// Clears any value in the input.
  clearInputValue() {
    this.input.value = ''
  }
}

/// An element which is responsible for a filter mode for a range input.
///
/// This mode contains one input for each end of the range. It updates values when the input changes.
class FilterFieldModeRange extends FilterFieldMode {
  /// Sets up a new range filter mode element.
  ///
  /// This locates the inputs and registers events to it.
  runConstructor() {
    super.runConstructor()

    const inputs = this.querySelectorAll('input')
    inputs.forEach((input) => {
      input.addEventListener('input', this.inputChanged.bind(this))
      input.addEventListener('keypress', this.keyPressed.bind(this))
    })

    this.fromInput = inputs[0]
    this.toInput = inputs[1]
  }

  /// Become the active mode.
  ///
  /// This overrides FilterFieldMode.activate, updates the range state, and focuses the from input.
  activate() {
    this.updateRangeState()
    this.fromInput.focus()
  }

  //////////

  /// Responds to changes on the inputs' values.
  ///
  /// This calls +useInputsValues+ when not disabled.
  inputChanged(_event) {
    if (this.disabled) { return }

    this.useInputsValues()
  }

  /// Responds to keypress events on the inputs.
  ///
  /// This captures key presses for enter and disregards them.
  keyPressed(event) {
    if (this.disabled) { return }

    if (event.key === 'Enter') {
      event.stopPropagation()
      event.preventDefault()

      return
    }
  }

  /// Uses the current input range as a filter value.
  ///
  /// This takes the values from the input range and sends it to the parent FilterField using +updateValue+.
  useInputsValues() {
    const fromValue = this.fromInput.value
    const toValue = this.toInput.value

    const value = `range:${fromValue},${toValue}`
    const title = `${fromValue} - ${toValue}`
    this.filterFieldElement.updateValue(value, title)
  }

  //////////

  /// Callback for when a value element is removed in the parent.
  ///
  /// This overrides FilterFieldMode.activate, and updates the range state.
  valueElementRemoved() {
    this.updateRangeState()
  }

  /// Clears any values in the inputs.
  clearRangeState() {
    this.fromInput.value = ''
    this.toInput.value = ''
  }

  /// Updates the values of the inputs.
  ///
  /// This retrieves the current set of values from the parent filter field, and synchronises them with the inputs. If
  /// the parent filter field does not have a range value, the inputs are cleared.
  updateRangeState() {
    const valueElements = this.filterFieldElement.valueElements
    if (valueElements.length !== 1) {
      this.clearRangeState()
      return
    }

    const rangeValue = valueElements[0].value
    if (!rangeValue.match(/^range:.*,.*/)) {
      this.clearRangeState()
      return
    }

    const [fromValue, toValue] = rangeValue.replace(/^range:/, '').split(',')
    this.fromInput.value = fromValue
    this.toInput.value = toValue
  }
}

//////////

window.customElements.define('filter-field', FilterField)
window.customElements.define('filter-field-value', FilterFieldValue)
window.customElements.define('filter-field-mode-enum', FilterFieldModeEnum)
window.customElements.define('filter-field-mode-input', FilterFieldModeInput)
window.customElements.define('filter-field-mode-range', FilterFieldModeRange)
