// cspell:words undos

import Trix from 'trix'
import CustomHTMLElement from '@onpace/onspace-core/components/html_element'
import DropDown from '@onpace/onspace-core/elements/dropdown'
import { OnspaceDialogSelect } from '@onpace/onspace-core/elements/dialog'

Trix.config.blockAttributes.default.breakOnReturn = true

const HEADING_LEVELS = [1, 2, 3, 4, 5, 6]
HEADING_LEVELS.forEach((level) => {
  Trix.config.blockAttributes[`heading${level}`] = {
    tagName: `h${level}`,
    terminal: true,
    breakOnReturn: true,
    group: false
  }
})

const _createNodes = Trix.views.PreviewableAttachmentView.prototype.createNodes

/// Creates the html nodes for an attachment preview.
///
/// This overrides Trix.views.PreviewableAttachmentView.createNodes to add additional Onspace elements using
/// RichText.createAttachmentContentNodes().
Trix.views.PreviewableAttachmentView.prototype.createNodes = function() {
  const richText = this.rootView.element.closest('rich-text')

  const nodes = _createNodes.apply(this)
  const figure = nodes[1]

  const additions = richText.createAttachmentContentNodes(this.attachment).reverse()
  additions.forEach((element) => { figure.insertBefore(element, figure.children[1]) })

  return nodes
}

/// An element which wraps a Trix editor.
///
/// This requires the following children:
/// - A +trix-editor+ element, and it's hidden input field.
/// - A toolbar containing the buttons allowed for this field.
export default class RichText extends CustomHTMLElement {
  ////////// Editor

  /// Sets up the +trix-editor+ inside this element.
  ///
  /// This locates the element and adds events where necessary, then sets up the toolbar.
  ///
  /// Note that on initialisation, this element may not appear within the DOM. This method should be called from both
  /// the +runConstructor+ and from the +trix-initialise+ event on the document. It is safe to call this function
  /// multiple times.
  setupTrixEditor() {
    if (this.trixElement) { return }

    this.trixElement = this.querySelector('trix-editor')
    if (!this.trixElement) { return }

    this.trixToolbar = this.querySelector('trix-toolbar')

    this.trixElement.addEventListener('trix-change', this.trixEditorChanged.bind(this))
    this.trixElement.addEventListener('trix-selection-change', this.trixSelectionChanged.bind(this))
    this.trixElement.addEventListener('trix-blur', this.trixEditorBlurred.bind(this))

    this.trixElement.addEventListener('trix-attachment-before-toolbar', this.trixEditorAddingToolbar.bind(this))

    this.setupToolbar()

    this.trixEditor.composition.attachments.forEach((attachment) => {
      if (attachment.delegate) { attachment.delegate.attachmentDidChangeAttributes(attachment) }
    })
  }

  /// Retrieves the editor from the Trix element.
  get trixEditor() {
    return this.trixElement.editor
  }

  /// Retrieves the editor controller from the Trix element.
  get trixEditorController() {
    return this.trixElement.editorController
  }

  /// Determines if a given attribute is active for the current selection.
  attributeIsActive(attribute) {
    return this.trixEditor.attributeIsActive(attribute)
  }

  ///// Actions

  /// Focuses the editor element to the current selection.
  focus() {
    const range = this.trixEditor.getSelectedRange()
    this.trixEditor.setSelectedRange(range)
  }

  /// Toggles a given style attribute on the current selection.
  toggleStyleAttribute(attribute) {
    this.trixEditor.recordUndoEntry(attribute)

    if (this.trixEditor.attributeIsActive(attribute)) {
      this.trixEditor.deactivateAttribute(attribute)
    } else {
      this.clearBlockAttributes()
      this.trixEditor.activateAttribute(attribute)
    }

    this.focus()
  }

  /// Adds a link attribute to the current selection.
  ///
  /// If the current selection already includes a link, this will expand the selection to encompass the entire link. If
  /// the given url is not valid, this will not add the link and return false, otherwise this returns true.
  addLink(url) {
    try {
      url = new URL(url)
    } catch (_error) {
      return false
    }
    if (url.protocol !== 'http:' && url.protocol !== 'https:') { return false }

    this.trixEditor.recordUndoEntry('link')
    this.trixEditor.composition.expandSelectionForEditing()
    this.trixEditor.activateAttribute('href', url.toString())

    return true
  }

  /// Removes a link attribute from the current selection.
  removeLink() {
    this.trixEditor.recordUndoEntry('link')
    this.trixEditor.composition.expandSelectionForEditing()
    this.trixEditor.deactivateAttribute('href')
  }

  /// Toggles a given block attribute on the block of the current selection.
  toggleBlockAttribute(attribute) {
    this.trixEditor.recordUndoEntry(attribute)

    if (this.trixEditor.attributeIsActive(attribute)) {
      this.trixEditor.deactivateAttribute(attribute)
    } else {
      this.clearBlockAttributes()
      this.trixEditor.activateAttribute(attribute)
    }
  }

  /// Clears all block attributes on the block of the current selection.
  clearBlockAttributes() {
    const block = this.trixEditor.composition.getBlock()
    block.attributes.forEach((attribute) => this.trixEditor.deactivateAttribute(attribute))
  }

  /// Determines if the block of the current selection can have its nesting level decreased.
  get canDecreaseNestingLevel() {
    return this.trixEditor.canDecreaseNestingLevel() && !this.attributeIsActive('quote')
  }

  /// Decreases the nesting level of the block of the current selection.
  decreaseNestingLevel() {
    this.trixEditor.recordUndoEntry('outdent')
    this.trixEditor.decreaseNestingLevel()
  }

  /// Determines if the block of the current selection can have its nesting level increased.
  get canIncreaseNestingLevel() {
    return this.trixEditor.canIncreaseNestingLevel() && !this.attributeIsActive('quote')
  }

  /// Increases the nesting level of the block of the current selection.
  increaseNestingLevel() {
    this.trixEditor.recordUndoEntry('outdent')
    this.trixEditor.increaseNestingLevel()
  }

  /// Creates and inserts an attachment at the current insertion point.
  insertAttachment(attachmentType, sgid, content) {
    this.trixEditor.recordUndoEntry('attachment')

    const attributes = {
      type: attachmentType
    }

    const attachmentItem = this.attachmentItems.find((item) => item.attachmentType == attachmentType)
    if (attachmentItem.attachmentOptions) {
      attributes.options = {}
      attachmentItem.attachmentOptions.forEach((option) => {
        attributes.options[option.key] = option.default
      })
    }

    const attachment = new Trix.Attachment({
      sgid: sgid,
      content: content,
      previewable: true,
      onspace: JSON.stringify(attributes)
    })
    this.trixEditor.insertAttachment(attachment)
  }

  /// Creates a Horizontal Rule attachment at the current insertion point.
  insertHorizontalRule() {
    const attachment = new Trix.Attachment({
      content: '<hr>',
      contentType: 'vnd.rubyonrails.horizontal-rule.html'
    })
    this.trixEditor.insertAttachment(attachment)
  }

  /// Determines if the editor has anything to undo.
  get canUndo() {
    return this.trixEditor.canUndo()
  }

  /// Undoes the last change in the editor.
  undo() {
    this.trixEditor.undo()
  }

  /// Determines if the editor has anything to redo.
  get canRedo() {
    return this.trixEditor.canRedo()
  }

  /// Redoes the last change in the editor.
  redo() {
    this.trixEditor.redo()
  }

  ///// Events

  /// Responds to changes in the Trix editor's content.
  ///
  /// This notifies the toolbar sections to update their state.
  trixEditorChanged(_event) {
    this.updateToolbarStyleState()
    this.updateToolbarBlockState()
    this.updateToolbarUndoState()

    this.changedForBlurEvent = true
  }

  /// Responds to changes in the Trix editor's selection.
  ///
  /// This notifies the toolbar sections to update their state.
  trixSelectionChanged(_event) {
    this.updateToolbarStyleState()
    this.updateToolbarBlockState()
  }

  trixEditorBlurred(_event) {
    if (this.changedForBlurEvent) {
      this.triggerEvent('onspace:rich-text:change')
    }
    this.changedForBlurEvent = false
  }

  /// Responds to key down events on the Trix editor.
  ///
  /// If the Enter key was pressed and there isn't any active block attributes, this will insert a block break. This
  /// overrides the default functionality, which is to insert a line break.
  trixEditorKeyedDown(event) {
    if (event.target !== this.trixElement) { return }

    if (event.key === 'Enter') {
      const block = this.trixEditor.composition.getBlock()
      if (block.attributes.length == 0) {
        this.trixEditor.recordUndoEntry('blockbreak')
        this.trixEditor.composition.insertBlockBreak()
        event.preventDefault()
      }
    }
  }

  /// Responds to an attachment preparing to add it's toolbar.
  ///
  /// This hides the built-in toolbar, adding a custom one which fits the Onspace style, using createAttachmentToolbar.
  trixEditorAddingToolbar(event) {
    event.toolbar.style.display = 'none'

    const toolbar = this.createAttachmentToolbar(event.attachment)
    event.target.appendChild(toolbar)
  }

  ////////// Toolbar

  /// Sets up the toolbar.
  ///
  /// This simply calls the setup method for each of the toolbar's sections.
  setupToolbar() {
    this.setupToolbarStyle()
    this.setupToolbarBlock()
    this.setupToolbarAttachment()
    this.setupToolbarUndo()

    this.updateToolbarState()
  }

  /// Updates the state of the toolbar items.
  ///
  /// This simply calls the update method for each of the toolbar's sections.
  updateToolbarState() {
    if (!this.trixElement) { return }

    this.updateToolbarStyleState()
    this.updateToolbarBlockState()
    this.updateToolbarUndoState()
  }

  ///// Style

  /// Sets up the style section of the toolbar.
  ///
  /// This locates each element and adds event listeners as required.
  setupToolbarStyle() {
    this.boldToggle = this.querySelector('[data-rich-text-bold]')
    this.boldToggle.addEventListener('click', this.boldTogglePressed.bind(this))

    this.italicToggle = this.querySelector('[data-rich-text-italic]')
    this.italicToggle.addEventListener('click', this.italicTogglePressed.bind(this))

    this.strikethruToggle = this.querySelector('[data-rich-text-strikethru]')
    this.strikethruToggle.addEventListener('click', this.strikethruTogglePressed.bind(this))

    this.linkDropdown = this.querySelector('[data-rich-text-link]')
    this.linkDropdown.addEventListener('onspace:dropdown:show', this.linkDropdownShown.bind(this))
    this.linkButton = this.linkDropdown.querySelector(':scope > .onspace-button')
    this.linkInput = this.linkDropdown.querySelector('input')
    this.linkInput.addEventListener('keypress', this.linkInputKeyPressed.bind(this))
    this.linkAddButton = this.linkDropdown.querySelector('[data-rich-text-link-add]')
    this.linkAddButton.addEventListener('click', this.linkAddButtonPressed.bind(this))
    this.linkRemoveButton = this.linkDropdown.querySelector('[data-rich-text-link-remove]')
    this.linkRemoveButton.addEventListener('click', this.linkRemoveButtonPressed.bind(this))

    this.horizontalRuleButton = this.querySelector('[data-rich-text-horizontal-rule]')
    this.horizontalRuleButton.addEventListener('click', this.horizontalRuleButtonPressed.bind(this))
  }

  /// Updates the style section of the toolbar.
  ///
  /// This checks the style attributes of the current selection, and updates the state of each item to match.
  updateToolbarStyleState() {
    this.boldToggle.toggleAttribute('data-toolbar-active', this.attributeIsActive('bold'))
    this.italicToggle.toggleAttribute('data-toolbar-active', this.attributeIsActive('italic'))
    this.strikethruToggle.toggleAttribute('data-toolbar-active', this.attributeIsActive('strike'))
    this.linkButton.toggleAttribute('data-toolbar-active', this.attributeIsActive('href'))
  }

  /// Responds to clicks on the toolbar bold toggle.
  ///
  /// This sends the attribute change using +toggleStyleAttribute+.
  boldTogglePressed(_event) {
    this.toggleStyleAttribute('bold')
  }

  /// Responds to clicks on the toolbar italic toggle.
  ///
  /// This sends the attribute change using +toggleStyleAttribute+.
  italicTogglePressed(_event) {
    this.toggleStyleAttribute('italic')
  }

  /// Responds to clicks on the toolbar strikethru toggle.
  ///
  /// This sends the attribute change using +strikethruTogglePressed+.
  strikethruTogglePressed(_event) {
    this.toggleStyleAttribute('strike')
  }

  /// Responds to show events on the toolbar link dropdown.
  ///
  /// This updates the input to show the link set on the current selection (if set), as well as the state of the remove
  /// button. It then focuses the input.
  linkDropdownShown(_event) {
    const currentHref = this.trixEditor.composition.currentAttributes.href
    if (currentHref) {
      this.linkInput.value = currentHref
      this.linkRemoveButton.removeAttribute('disabled')
    } else {
      this.linkInput.value = 'https://'
      this.linkRemoveButton.setAttribute('disabled', '')
    }

    this.linkInput.removeAttribute('invalid')

    this.linkInput.focus()
  }

  /// Responds to key presses on the toolbar link input.
  ///
  /// This takes the current value of the link input and sets it as the current link using +addLink+.
  linkInputKeyPressed(event) {
    if (event.key === 'Enter') {
      if (this.addLink(this.linkInput.value)) {
        this.linkDropdown.hideMenu()
      } else {
        this.linkInput.setAttribute('invalid', '')
      }

      event.preventDefault()
      return false
    }
  }

  /// Responds to clicks on the toolbar link add button.
  ///
  /// This takes the current value of the link input and sets it as the current link using +addLink+.
  linkAddButtonPressed(_event) {
    if (this.addLink(this.linkInput.value)) {
      this.linkDropdown.hideMenu()
    } else {
      this.linkInput.setAttribute('invalid', '')
    }
  }

  /// Responds to clicks on the toolbar link remove button.
  ///
  /// This removes the current link using +removeLink+.
  linkRemoveButtonPressed(_event) {
    this.removeLink()
    this.linkDropdown.hideMenu()
  }

  /// Responds to clicks on the horizontal rule button.
  ///
  /// This inserts a horizontal rule using +insertHorizontalRule+.
  horizontalRuleButtonPressed(_event) {
    this.insertHorizontalRule()
  }

  ///// Block

  /// Sets up the block section of the toolbar.
  ///
  /// This locates each element and adds events listeners as required.
  setupToolbarBlock() {
    this.headingDropdown = this.querySelector('[data-rich-text-heading]')
    this.headingButton = this.headingDropdown.querySelector(':scope > .onspace-button')
    this.headingButtonIcon = this.headingButton.querySelector('svg')
    this.headingLevelItems = this.querySelectorAll('[data-rich-text-heading-level]')
    this.headingLevelItems.forEach((levelButton) => {
      levelButton.level = levelButton.getIntegerAttribute('data-rich-text-heading-level')
      levelButton.addEventListener('click', this.headingLevelItemPressed.bind(this))
    })

    this.quoteToggle = this.querySelector('[data-rich-text-quote]')
    this.quoteToggle.addEventListener('click', this.quoteTogglePressed.bind(this))

    this.htmlToggle = this.querySelector('[data-rich-text-html]')
    this.htmlToggle.addEventListener('click', this.htmlTogglePressed.bind(this))

    this.bulletsToggle = this.querySelector('[data-rich-text-bullets]')
    this.bulletsToggle.addEventListener('click', this.bulletsTogglePressed.bind(this))

    this.numbersToggle = this.querySelector('[data-rich-text-numbers]')
    this.numbersToggle.addEventListener('click', this.numbersTogglePressed.bind(this))

    this.outdentButton = this.querySelector('[data-rich-text-outdent]')
    this.outdentButton.addEventListener('click', this.outdentButtonPressed.bind(this))

    this.indentButton = this.querySelector('[data-rich-text-indent]')
    this.indentButton.addEventListener('click', this.indentButtonPressed.bind(this))
  }

  /// Updates the block section of the toolbar.
  ///
  /// This checks the block attributes of the current selection, and updates the state of each item to match.
  updateToolbarBlockState() {
    const activeHeadingLevel = HEADING_LEVELS.find((level) => this.attributeIsActive(`heading${level}`))
    if (activeHeadingLevel) {
      this.headingLevelItems.forEach((levelItem) => levelItem.toggleAttribute('data-toolbar-active', activeHeadingLevel == levelItem.level))
      this.headingButton.setAttribute('data-toolbar-active', '')
      this.headingButtonIcon.setOnspaceSpritemapSvg(`onspace/editor_heading_level${activeHeadingLevel}`)
    } else {
      this.headingLevelItems.forEach((levelItem) => levelItem.removeAttribute('data-toolbar-active'))
      this.headingButton.removeAttribute('data-toolbar-active')
      this.headingButtonIcon.setOnspaceSpritemapSvg('onspace/editor_heading')
    }

    this.quoteToggle.toggleAttribute('data-toolbar-active', this.attributeIsActive('quote'))
    this.htmlToggle.toggleAttribute('data-toolbar-active', this.attributeIsActive('code'))
    this.bulletsToggle.toggleAttribute('data-toolbar-active', this.attributeIsActive('bullet'))
    this.numbersToggle.toggleAttribute('data-toolbar-active', this.attributeIsActive('number'))
    this.outdentButton.toggleAttribute('disabled', !this.canDecreaseNestingLevel)
    this.indentButton.toggleAttribute('disabled', !this.canIncreaseNestingLevel)
  }

  /// Responds to clicks on a heading level item.
  ///
  /// This sends the attribute change using +toggleBlockAttribute+.
  headingLevelItemPressed(event) {
    const level = event.target.level
    this.toggleBlockAttribute(`heading${level}`)
    this.headingDropdown.hideMenu()
  }

  /// Responds to clicks on the toolbar quote toggle.
  ///
  /// This sends the attribute change using +toggleBlockAttribute+.
  quoteTogglePressed(_event) {
    this.toggleBlockAttribute('quote')
  }

  /// Responds to clicks on the toolbar html toggle.
  ///
  /// This sends the attribute change using +toggleBlockAttribute+
  htmlTogglePressed(_event) {
    this.toggleBlockAttribute('code')
  }

  /// Responds to clicks on the toolbar bullets toggle.
  ///
  /// This sends the attribute change using +toggleBlockAttribute+.
  bulletsTogglePressed(_event) {
    this.toggleBlockAttribute('bullet')
  }

  /// Responds to clicks on the toolbar numbers toggle.
  ///
  /// This sends the attribute change using +toggleBlockAttribute+.
  numbersTogglePressed(_event) {
    this.toggleBlockAttribute('number')
  }

  /// Responds to clicks on the toolbar outdent button.
  ///
  /// This calls +decreaseNestingLevel+.
  outdentButtonPressed(_event) {
    this.decreaseNestingLevel()
  }

  /// Responds to clicks on the toolbar indent button.
  ///
  /// This calls +increaseNestingLevel+.
  indentButtonPressed(_event) {
    this.increaseNestingLevel()
  }

  ///// Attachments

  /// Sets up the attachment section of the toolbar.
  ///
  /// This locates each element and adds event listeners as required.
  setupToolbarAttachment() {
    this.attachmentDropdown = this.querySelector('[data-rich-text-attach]')
    this.attachmentItems = Array.from(this.querySelectorAll('[data-rich-text-attachment]'))
    this.attachmentItems.forEach((item) => {
      item.attachmentType = item.getAttribute('data-rich-text-attachment')
      item.selectionUrl = item.getAttribute('data-rich-text-attachment-selection')
      item.attachmentOptions = item.getJsonAttribute('data-rich-text-attachment-options')

      item.addEventListener('click', this.attachmentItemPressed.bind(this))
    })
  }

  /// Responds to clicks on an attachment item.
  ///
  /// This opens a selection dialog with the item's selection controller.
  attachmentItemPressed(event) {
    if (this.selectionDialog) { return }

    this.selectionDialog = new OnspaceDialogSelect()
    this.selectionDialog.attachmentType = event.target.attachmentType
    this.selectionDialog.addEventListener('onspace:dialog:close', this.selectionDialogClosed.bind(this))
    this.selectionDialog.addEventListener('onspace:dialog:selected', this.selectionDialogValueSelected.bind(this))
    this.selectionDialog.loadUrl(event.target.selectionUrl)

    this.attachmentDropdown.hideMenu()
    document.body.appendChild(this.selectionDialog)
  }

  /// Responds to the selection dialog closing.
  ///
  /// This clears the selection dialog variable.
  selectionDialogClosed(_event) {
    this.selectionDialog = null
  }

  /// Responds to the selection dialog selecting a value.
  ///
  /// This adds the selected value as an attachment in the editor.
  selectionDialogValueSelected(event) {
    const selection = event.detail
    this.insertAttachment(this.selectionDialog.attachmentType, selection.value, selection.content)
  }

  /// Creates a toolbar for modifying attachments.
  ///
  /// This toolbar appears in the upper-right of attachments when focused. It always includes a close (⨉) button, but if
  /// configured will also include an options dropdown. This includes selectable options configured on the attachable's
  /// formatter to customise the attachment.
  createAttachmentToolbar(attachment) {
    let attributes = attachment.attributes.values.onspace
    if (!attributes) {
      attributes = {}
    } else if (typeof attributes === 'string') {
      attributes = JSON.parse(attributes)
    }

    let attachmentItem = {}
    if (attributes.type) {
      attachmentItem = this.attachmentItems.find((item) => item.attachmentType == attributes.type)
    }

    ///// Container

    const container = document.createElement('div')
    container.classList.add('onspace-button-group')
    container.setAttribute('data-trix-mutable', 'true')
    container.addEventListener('click', (event) => {
      event.preventDefault()
      event.stopPropagation()
    })

    ///// Dropdown

    if (attachmentItem.attachmentOptions) {
      const dropdownAnchor = document.createElement('a')
      dropdownAnchor.classList.add('onspace-button')
      dropdownAnchor.classList.add('onspace-button--outline')
      dropdownAnchor.classList.add('onspace-button--color-border')
      dropdownAnchor.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_options--outline'))

      const dropdownMenu = document.createElement('div')
      dropdownMenu.classList.add('onspace-dropdown__list')

      attachmentItem.attachmentOptions.forEach((option) => {
        const headingElement = document.createElement('div')
        headingElement.classList.add('onspace-dropdown__list__heading')
        headingElement.innerText = option.title
        dropdownMenu.appendChild(headingElement)

        const optionItemElements = []

        option.choices.forEach((choice) => {
          const itemElement = document.createElement('a')
          itemElement.classList.add('onspace-dropdown__list__item')
          if (attributes.options[option.key] === choice.choice) {
            itemElement.svg = SVGElement.createOnspaceSpritemapSvg('onspace/icon_checkmark')
          } else {
            itemElement.svg = SVGElement.createOnspaceSpritemapSvg(null)
          }
          itemElement.appendChild(itemElement.svg)

          const itemTextElement = document.createElement('span')
          itemTextElement.innerText = choice.title
          itemElement.appendChild(itemTextElement)

          itemElement.addEventListener('click', (_event) => {
            attributes.options[option.key] = choice.choice

            optionItemElements.forEach((element) => element.svg.setOnspaceSpritemapSvg(null))
            itemElement.svg.setOnspaceSpritemapSvg('onspace/icon_checkmark')
          })

          optionItemElements.push(itemElement)
          dropdownMenu.appendChild(itemElement)
        })
      })

      const dropdown = new DropDown({ anchor: dropdownAnchor, menu: dropdownMenu })
      dropdown.addEventListener('onspace:dropdown:hide', (_event) => {
        const attachmentAttributes = attachment.attributes.values
        const onspaceAttribute = JSON.stringify(attributes)

        if (attachmentAttributes.onspace != onspaceAttribute) {
          const newAttachment = new Trix.Attachment({
            sgid: attachmentAttributes.sgid,
            content: attachmentAttributes.content,
            previewable: true,
            onspace: JSON.stringify(attributes)
          })
          this.trixEditor.insertAttachment(newAttachment)

          this.trixEditor.moveCursorInDirection('backward')
        }
      })
      container.appendChild(dropdown)
    }

    ///// Remove Button

    const removeButton = document.createElement('a')
    removeButton.classList.add('onspace-button')
    removeButton.classList.add('onspace-button--outline')
    removeButton.classList.add('onspace-button--color-border')
    removeButton.setAttribute('data-trix-action', 'remove')
    removeButton.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_cross'))
    removeButton.addEventListener('click', (_event) => this.trixEditorController.removeAttachment(attachment))
    container.appendChild(removeButton)

    /////

    return container
  }

  /// Creates additional content nodes for an attachment.
  ///
  /// This creates optional UI to appear below an attachment's preview content.
  createAttachmentContentNodes(attachment) {
    if (!this.attachmentItems) { return [] }

    const nodes = []

    let attributes = attachment.attributes.values.onspace
    if (!attributes) {
      attributes = {}
    } else if (typeof attributes === 'string') {
      attributes = JSON.parse(attributes)
    }

    let attachmentItem = {}
    if (attributes.type) {
      attachmentItem = this.attachmentItems.find((item) => item.attachmentType == attributes.type)
    }

    if (attachmentItem.attachmentOptions) {
      const optionsElement = document.createElement('div')
      optionsElement.classList.add('onspace-attachment__options')

      attachmentItem.attachmentOptions.forEach((option) => {
        const optionElement = document.createElement('div')
        optionElement.classList.add('onspace-attachment__options__option')

        const optionTitleElement = document.createElement('div')
        optionTitleElement.classList.add('onspace-attachment__options__option__title')
        optionTitleElement.innerText = `${option.title}:`
        optionElement.appendChild(optionTitleElement)

        const selectedValue = attributes.options[option.key] || option.default
        const selectedChoice = option.choices.find((choice) => choice.choice == selectedValue)

        const optionValueElement = document.createElement('div')
        optionValueElement.classList.add('onspace-attachment__options__option__value')
        optionValueElement.innerText = selectedChoice.title
        optionElement.appendChild(optionValueElement)

        optionsElement.appendChild(optionElement)
      })

      nodes.push(optionsElement)
    }

    return nodes
  }

  ///// Undo

  /// Sets up the undo section of the toolbar.
  ///
  /// This locates each element and adds event listeners as required.
  setupToolbarUndo() {
    this.undoButton = this.querySelector('[data-rich-text-undo]')
    this.undoButton.addEventListener('click', this.undoButtonPressed.bind(this))

    this.redoButton = this.querySelector('[data-rich-text-redo]')
    this.redoButton.addEventListener('click', this.redoButtonPressed.bind(this))
  }

  /// Updates the undo section of the toolbar.
  ///
  /// This checks the undo states of the editor, and updates the state of each item to match.
  updateToolbarUndoState() {
    this.undoButton.toggleAttribute('disabled', !this.canUndo)
    this.redoButton.toggleAttribute('disabled', !this.canRedo)
  }

  /// Responds to clicks on the toolbar undo button.
  undoButtonPressed(_event) {
    this.undo()
  }

  /// Responds to clicks on the toolbar redo button.
  redoButtonPressed(_event) {
    this.redo()
  }
}

window.customElements.define('rich-text', RichText)

//////////

document.addEventListener('trix-before-initialize', function(event) {
  const editor = event.target
  if (editor.parentElement.tagName === 'RICH-TEXT') {
    editor.parentElement.addEventListener('keydown', editor.parentElement.trixEditorKeyedDown.bind(editor.parentElement))
  }
})

document.addEventListener('trix-initialize', (event) => {
  const editor = event.target
  if (editor.parentElement.tagName === 'RICH-TEXT') {
    editor.parentElement.setupTrixEditor()
  }
})

document.addEventListener('trix-file-accept', (event) => {
  event.attachment.remove()
  event.preventDefault()
  return false
})
