import CustomHTMLElement from '@onpace/onspace-core/components/html_element'
import client from '@onpace/onspace-cms/components/client'

/// An elements which manages model icons.
///
/// This has the ability to display icons as normal, and can additionally manage a model's pinned status and tags.
export default class OnspaceModelIcons extends CustomHTMLElement {
  /// Sets up the model icons element.
  runConstructor() {
    super.runConstructor()

    this.classList.add('onspace-icons')
  }

  /// Runs when the element is first connected to the DOM.
  runFirstConnected() {
    super.runFirstConnected()

    this.fieldset = this.querySelector('fieldset')
    this.buttonBar = this.querySelector('.onspace-button-bar')

    if (this.fieldset.children.length === 1) {
      this.style.display = 'none'
    }

    setTimeout(() => {
      this.configurePinning()
      this.configureTagging()
    })
  }

  /// Updates the element to indicate it is loading.
  beginLoad(element) {
    this.loading = true
    this.fieldset.setAttribute('disabled', '')

    if (element) {
      this.loadingIcon = SVGElement.createOnspaceSpritemapSvg('onspace/icon_loading')
      element.appendChild(this.loadingIcon)
    }
  }

  /// Updates the element to indicate loading completed.
  finishLoad() {
    this.loading = false
    this.fieldset.removeAttribute('disabled')

    if (this.loadingIcon) {
      this.loadingIcon.remove()
      this.loadingIcon = null
    }
  }

  ////////// Pinning

  /// Configures the elements for changing the model's pinning status.
  configurePinning() {
    this.pinButton = this.querySelector('[onspace-model-icons-pin]')
    if (this.pinButton) {
      this.pinButton.addEventListener('click', this.pinModel.bind(this))
    }

    this.unpinButton = this.querySelector('[onspace-model-icons-unpin]')
    if (this.unpinButton) {
      this.unpinButton.addEventListener('click', this.unpinModel.bind(this))
    }
  }

  /// Sets the model as pinned.
  ///
  /// This will disable the button while requesting, and update the UI on completion.
  async pinModel(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.loading) { return }

    this.beginLoad(this.pinButton)

    const url = this.pinButton.href
    await client.put(url)

    this.finishLoad()
    this.setAttribute('pinned', '')
  }

  /// Sets the model as unpinned.
  ///
  /// This will disable the button while requesting, and update the UI on completion.
  async unpinModel(event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.loading) { return }

    this.beginLoad(this.unpinButton)

    const url = this.unpinButton.href
    await client.delete(url)

    this.finishLoad()
    this.removeAttribute('pinned')
  }

  ////////// Tagging

  /// Configures the elements to add or remove the model's tags.
  configureTagging() {
    const removeTagButtons = this.querySelectorAll('[onspace-model-icons-remove-tag]')
    removeTagButtons.forEach((button) => {
      const tagId = button.getAttribute('onspace-model-icons-remove-tag')
      button.addEventListener('click', () => this.removeTag(tagId))
    })

    this.tagSelector = this.querySelector('select-custom')
    if (this.tagSelector) {
      this.tagSelector.addEventListener('onspace:dropdown:load', this.tagSelectorLoaded.bind(this))
      this.tagSelector.addEventListener('onspace:dropdown:hide', this.tagSelectorHidden.bind(this))
    }
  }

  /// Removes the tag with the given ID.
  async removeTag(tagId) {
    event.preventDefault()
    event.stopPropagation()

    if (this.loading) { return }

    const tagButton = this.querySelector(`[onspace-model-icons-remove-tag="${tagId}"]`)
    const tagElement = tagButton.closest('.onspace-icons__icon')

    tagElement.setAttribute('removing', '')
    this.beginLoad(tagElement)

    const url = tagButton.href
    await client.delete(url)

    this.finishLoad()
    tagElement.remove()

    const tagInput = this.tagSelector.querySelector(`input[value="${tagId}"]`)
    if (tagInput) {
      tagInput.checked = false
    }
  }

  tagSelectorLoaded() {
    this.currentTagIds = this.tagSelector.value
  }

  /// Responds to the tag selector's dropdown hiding.
  async tagSelectorHidden() {
    const tagIds = this.tagSelector.value
    if (this.currentTagIds.length === tagIds.length && this.currentTagIds.every((x) => tagIds.includes(x))) { return }

    this.beginLoad(this.tagSelector.anchor)

    const url = this.tagSelector.getAttribute('href')
    await client.put(url, { params: { tag_ids: tagIds } })

    this.currentTagIds = tagIds
    this.finishLoad()

    const selectedInputs = this.tagSelector.querySelectorAll('input:checked')
    selectedInputs.forEach((input) => {
      const option = input.closest('label')

      const tagButton = this.querySelector(`[onspace-model-icons-remove-tag="${option.input.value}"]`)
      if (!tagButton) {
        const tagElement = document.createElement('div')
        tagElement.classList.add('onspace-icons__icon')
        tagElement.classList.add('onspace-icons__icon--tag')

        const tagIcon = SVGElement.createOnspaceSpritemapSvg('onspace/icon_tag--strokable')
        tagIcon.style.color = option.color
        tagElement.appendChild(tagIcon)

        const tagRemoveButton = document.createElement('a')
        tagRemoveButton.setAttribute('onspace-model-icons-remove-tag', option.input.value)
        tagRemoveButton.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_cross'))
        tagElement.appendChild(tagRemoveButton)

        const tagTitle = document.createElement('span')
        tagTitle.innerText = option.title
        tagElement.appendChild(tagTitle)

        this.fieldset.insertBefore(tagElement, this.buttonBar)
      }
    })

    const removeTagButtons = this.querySelectorAll('[onspace-model-icons-remove-tag]')
    removeTagButtons.forEach((button) => {
      const tagId = button.getAttribute('onspace-model-icons-remove-tag')
      const selectedInput = this.tagSelector.querySelector(`input:checked[value="${tagId}"]`)
      if (!selectedInput) {
        const tagElement = button.closest('.onspace-icons__icon')
        tagElement.remove()
      }
    })
  }
}

window.customElements.define('onspace-model-icons', OnspaceModelIcons)
