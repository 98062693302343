import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// An element to display a dropped-down menu on click.
///
/// This element requires the following children:
/// - A single anchor element. Clicks on this toggle the dropdown.
/// - A single div element. This is shown and hidden.
///
/// This utilises the scrollretain functionality while the dropdown is active.
export default class DropDown extends CustomHTMLElement {
  /// Sets up the dropdown element.
  ///
  /// Locates the required children and adds events where necessary.
  runConstructor(options={}) {
    super.runConstructor()

    this.classList.add('drop-down')

    if (options.anchor) {
      this.anchor = options.anchor
      this.appendChild(this.anchor)
    } else {
      this.anchor = this.querySelector(':scope > a')
    }

    if (options.menu) {
      this.menu = options.menu
      this.appendChild(this.menu)
    } else {
      this.menu = this.querySelector(':scope > div')
    }

    this.menuActive = false

    this.anchor.addEventListener('click', this.anchorClicked.bind(this))
    this.anchor.addEventListener('keypress', this.anchorKeyPressed.bind(this))

    this.menu.querySelectorAll('a[href]').forEach((item) => item.addEventListener('click', (_event) => this.hideMenu()))
    this.menu.addEventListener('mousemove', this.menuMouseMoved.bind(this))
  }

  /// Cleans up the dropdown element.
  ///
  /// This hides the menu if it is active.
  runDisconnected() {
    super.runDisconnected()

    this.hideMenu()
  }

  ////////// State

  /// Detects whether this element is disabled.
  get disabled() {
    return this.anchor.getBooleanAttribute('disabled')
  }

  /// Toggles the visibility of the menu.
  toggleMenu() {
    if (this.menuActive) {
      this.hideMenu()
    } else {
      this.showMenu()
    }
  }

  /// Shows the menu.
  ///
  /// This sets the +active+ attribute on the anchor. Scrolling is retained while the menu is active.
  ///
  /// This automatically detects the direction to show the menu from, and sets a class accordingly.
  showMenu() {
    if (this.menuActive) { return }

    this.menuActive = true
    this.classList.add('onspace-dropdown--active')
    this.anchor.setAttribute('active', '')

    this.loadRemoteContent()

    this.highlightSelectedElement()

    this.onspaceRetainScrolling()
    this.detectAnchorDirection()

    this.addDocumentBoundEventListener('onspace:scrollretain:released', (_event) => this.hideMenu())
    this.addWindowBoundEventListener('resize', this.windowResized)
    this.addDocumentBoundEventListener('keydown', this.documentKeyDowned)
    this.addDocumentBoundEventListener('keypress', this.documentKeyPressed)

    this.triggerEvent('onspace:dropdown:show')
  }

  /// Hides the menu.
  ///
  /// This removes the +active+ attribute on the anchor, and scrolling is released.
  hideMenu() {
    if (!this.menuActive) { return }

    this.menuActive = false
    this.classList.remove('onspace-dropdown--active')
    this.anchor.removeAttribute('active')

    this.onspaceReleaseScrolling()

    this.removeWindowBoundEventListener('resize')
    this.removeDocumentBoundEventListener('keydown')
    this.removeDocumentBoundEventListener('keypress')
    this.removeDocumentBoundEventListener('onspace:scrollretain:released')

    this.triggerEvent('onspace:dropdown:hide')
  }

  /// Detects the direction to show the menu from.
  ///
  /// This is simply calculated based on the center of the element relative to the center of the viewport. The menu will
  /// be positioned towards the viewport center, so it has the maximum possible screen space.
  detectAnchorDirection() {
    if (this.anchorDirection) {
      this.classList.remove(`onspace-dropdown--anchor-${this.anchorDirection}`)
    }

    const elementRect = this.anchor.getBoundingClientRect()
    const bodyRect = document.body.getBoundingClientRect()

    const horizontalCenter = elementRect.left + (elementRect.width / 2)
    const verticalCenter = elementRect.top + (elementRect.height / 2)

    let direction = ''
    if (verticalCenter < (bodyRect.height / 2)) {
      direction += 'top'
    } else {
      direction += 'bottom'
    }
    if (horizontalCenter < (bodyRect.width / 2)) {
      direction += 'left'
    } else {
      direction += 'right'
    }

    this.anchorDirection = direction
    this.classList.add(`onspace-dropdown--anchor-${this.anchorDirection}`)
  }

  ////////// Remote

  /// Loads remote content elements in the dropdown menu.
  ///
  /// This looks for any elements with the attribute +data-remote-url+, and replaces them with +turbo-frame+ elements,
  /// triggering the content to load remotely. The +turboFrameLoaded+ function will be called once each frame finishes
  /// loading.
  loadRemoteContent() {
    const remoteElements = this.menu.querySelectorAll('[data-remote-url]')
    remoteElements.forEach((remoteElement) => {
      const frame = document.createElement('turbo-frame')
      frame.addEventListener('turbo:frame-load', this.turboFrameLoaded.bind(this))
      frame.id = remoteElement.id
      frame.src = remoteElement.getAttribute('data-remote-url')

      const icon = SVGElement.createOnspaceSpritemapSvg('onspace/logo_onspace_loading')
      frame.appendChild(icon)

      remoteElement.parentElement.replaceChild(frame, remoteElement)
    })
  }

  /// Callback for when a turbo frame element finishes loading.
  turboFrameLoaded(_event) {
    this.triggerEvent('onspace:dropdown:load')
  }

  ////////// Selection

  ///// Selection

  /// Retrieves the currently selected item.
  ///
  /// The default implementation of this does nothing, it should be implemented in a subclass.
  get selectedElement() {
    return null
  }

  /// Selects the currently highlighted element.
  ///
  /// The default implementation of this does nothing, it should be implemented in a subclass.
  ///
  /// This should select or activate the currently highlighted item in a list of items.
  selectHighlightedElement() {}

  ///// Highlighting

  /// Retrieves the list of highlightable elements in the menu.
  get highlightableElements() {
    return this._highlightableElements
  }

  /// Sets the list of highlightable elements in the menu.
  set highlightableElements(elements) {
    this._highlightableElements = elements
    this.highlightedElement = null
  }

  /// Retrieves the currently highlighted element.
  get highlightedElement() {
    return this._highlightedElement
  }

  /// Sets the currently highlighted element.
  set highlightedElement(element) {
    if (this.highlightedElement) {
      this.highlightedElement.removeAttribute('highlighted')
    }

    if (element) {
      element.setAttribute('highlighted', '')
      this._highlightedElement = element

      if (this.menu.scrollable) {
        setTimeout(() => this.highlightedElement.scrollInCenter())
      }
    } else {
      this._highlightedElement = null
    }
  }

  ///// Actions

  /// Highlights the next element in a list.
  ///
  /// Makes the next item highlighted. If there is no item highlighted, it highlights the selected item, or if none the
  /// first item.
  highlightNextElement() {
    let index = -1
    if (this.highlightedElement) {
      index = this.highlightableElements.indexOf(this.highlightedElement)
      if (index === this.highlightableElements.length - 1) {
        index = -1
      }
    }

    this.highlightedElement = this.highlightableElements[index + 1]
  }

  /// Highlights the previous element in a list.
  ///
  /// Makes the previous item highlighted. If there is no item highlighted, it highlights the selected item, or if none
  /// the last item.
  highlightPreviousElement() {
    const length = this.highlightableElements.length
    let index = length
    if (this.highlightedElement) {
      index = this.highlightableElements.indexOf(this.highlightedElement)
      if (index === 0) {
        index = length
      }
    }

    this.highlightedElement = this.highlightableElements[index - 1]
  }

  /// Clears the highlighted element.
  ///
  /// This clears the highlighted item, if one exists.
  clearHighlightedElement() {
    this.highlightedElement = null
  }

  /// Highlights the currently selected element.
  ///
  /// Highlights the selected or item. This requires +selectedElement+ to be overridden in a subclass.
  highlightSelectedElement() {
    this.highlightedElement = this.selectedElement
  }

  ////////// Events

  ///// Mouse

  /// Callback for clicking the anchor element.
  ///
  /// This simply toggles the menu visibility, unless the anchor is disabled.
  anchorClicked(event) {
    if (this.disabled) {
      event.preventDefault()
      event.stopPropagation()
      return false
    }

    this.toggleMenu()
  }

  /// Callback for hovering over the menu element.
  menuMouseMoved(_event) {
    this.clearHighlightedElement()
  }

  /// Callback for when the browser window is resized.
  ///
  /// Note that this will only be triggered when the menu is active.
  windowResized(_event) {
    this.hideMenu()
  }

  ///// Keyboard

  /// Callback for keypresses on a focused anchor.
  anchorKeyPressed(event) {
    if (this.disabled) { return false }

    switch (event.key) {
    case ' ':
      this.showMenu()
      event.preventDefault()
      event.stopPropagation()
      return false
    case 'Enter':
      this.anchorEntered()
      break
    }
  }

  /// Callback for keydowns on the document.
  ///
  /// Note that this will only be triggered when the menu is active.
  documentKeyDowned(event) {
    if (this.disabled) { return false }

    switch (event.key) {
    case 'Escape':
      this.menuEscaped()
      event.preventDefault()
      event.stopPropagation()
      return false
    case 'Tab':
      event.preventDefault()
      event.stopPropagation()
      return false
    case 'ArrowUp':
      this.highlightPreviousElement()
      event.preventDefault()
      event.stopPropagation()
      return false
    case 'ArrowDown':
      this.highlightNextElement()
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }

  /// Callback for keypresses on the document.
  ///
  /// Note that this will only be triggered when the menu is active.
  documentKeyPressed(event) {
    if (this.disabled) { return false }

    switch (event.key) {
    case ' ':
      if (event.target.tagName === 'INPUT') { return }
      // fallthrough
    case 'Enter':
      this.selectHighlightedElement()
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }

  ///// Generic

  /// Callback for keypressed on the escape key.
  ///
  /// This hides the menu when visible.
  menuEscaped() {
    this.hideMenu()
  }

  /// Callback for keypressed on the enter key.
  ///
  /// The default implementation of this does nothing, it should be implemented in a subclass.
  anchorEntered() {}
}
window.customElements.define('drop-down', DropDown)
