import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import '@onpace/onspace-core/components/date'
import '@onpace/onspace-core/components/number'

/// An element used to display dates and times.
///
/// This works by taking a datetime, with format options, and displaying it as the relevant string
export default class FormattedTime extends CustomHTMLElement {

  ////////// Date Formats

  /// Retrieves the possible formats.
  static get formats() {
    if (!this._formats) {
      this._formats = {
        time_short: { hour: '2-digit', minute: '2-digit' },

        date: { year: '2-digit', month: 'numeric', day: 'numeric' },
        date_short: { month: 'numeric', day: 'numeric' },
        date_long: { year: 'numeric', month: 'long', weekday: 'short', day: 'numeric' },

        datetime: { year: '2-digit', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' },
        datetime_short: { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' },
        datetime_long: { year: 'numeric', month: 'long', weekday: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' },

        month: { year: '2-digit', month: '2-digit' },
        month_short: { year: '2-digit', month: 'short' },
        month_long: { year: 'numeric', month: 'long' },

        year: { year: 'numeric' },
        year_short: { year: '2-digit' },
        year_long: { year: 'numeric' }
      }
    }

    return this._formats
  }

  /// Sets a new format.
  static set formats(newValue) {
    this._formats = newValue
  }

  /// Retrieves the default format.
  static get defaultFormat() {
    return this._defaultFormat
  }

  /// Sets the default format.
  static set defaultFormat(newValue) {
    this._defaultFormat = newValue
  }

  ////////// Locale Override

  /// Retrieves the default Locale.
  static get defaultLocale() {
    return this._defaultLocale
  }

  /// Sets the default Locale.
  static set defaultLocale(newValue) {
    this._defaultLocale = newValue
  }

  /// Retrieves the default TimeZone.
  static get defaultTimeZone() {
    return this._defaultTimeZone
  }

  /// Sets the default Locale.
  static set defaultTimeZone(newValue) {
    this._defaultTimeZone = newValue
  }

  /// Sets up the formatted time element.
  ///
  /// Sets all values, overriding Locales where necessary.
  runConstructor(options={}) {
    super.runConstructor()

    if (!this.format) {
      this.format = options.format || this.getAttribute('data-format') || this._defaultFormat
    }

    if (!this.locale) {
      this.locale = options.locale || this.getAttribute('data-locale') || FormattedTime.defaultLocale
    }

    if (!this.timeZone) {
      this.timeZone = options.timeZone || this.getAttribute('data-time-zone') || FormattedTime.defaultTimeZone
    }

    if (!this.date) {
      const date = options.date || this.getDateAttribute('data-date')
      if (date) {
        this.date = date
      } else {
        this.timestamp = options.timestamp || this.getIntegerAttribute('data-timestamp')
      }
    }
  }

  ////////// Attributes

  /// Retrieves a timestamp.
  get timestamp() {
    return this._timestamp
  }

  /// Sets a timestamp.
  set timestamp(newValue) {
    this._timestamp = newValue

    if (newValue || newValue === 0) {
      const timestamp = parseInt(newValue) * 1000
      this.date = new Date(timestamp)
    } else {
      this.date = null
    }
  }

  /// Retrieves the date.
  get date() {
    return this._date
  }

  /// Sets the date and updates the display.
  set date(newValue) {
    if (typeof newValue === 'string') {
      this._date = new Date(newValue)
    } else {
      this._date = newValue
    }

    this.updateDisplay()
  }

  ////////// Formatting

  /// Outputs a date in the given format.
  ///
  /// This accepts the following arguments:
  /// [date]
  ///   The date to format.
  /// [format]
  ///   The format to use for the output.
  /// [:locale]
  ///   The locale to use for formatting. This will use +FormattedTime.defaultLocale+ by default.
  /// [:timeZone]
  ///   The time zone to use for formatting. This will use +FormattedTime.defaultTimeZone+ by default.
  static formatDate(date, format, { locale, timeZone } = {}) {
    if (!locale) { locale = FormattedTime.defaultLocale }
    if (!timeZone) { timeZone = FormattedTime.defaultTimeZone }

    if (typeof format === 'string') {
      format = FormattedTime.formats[format]
    }

    const options = Object.assign({}, format)
    options.timeZone = timeZone
    return date.toLocaleString(locale, options)
  }

  /// Retrieves the format.
  get format() {
    return this._format
  }

  /// Sets the format.
  set format(newValue) {
    try {
      this._format = JSON.parse(newValue)
    } catch (e) {
      if (FormattedTime.formats.hasOwnProperty(newValue)) { // eslint-disable-line no-prototype-builtins
        this._format = FormattedTime.formats[newValue]
      } else {
        this._format = newValue
      }
    }
  }

  /// Update the formatted time element.
  ///
  /// This works by using the current format to set the html elements to the desired output.
  updateDisplay() {
    if (!this.date) {
      this.innerText = ''
      this.title = ''
      return
    }

    switch (this.format) {
    case 'relative':
      this.innerText = this.date.toRelativeString()
      this.title = this.date.toLocaleString(this.locale, { timeZone: this.timeZone })
      break
    case 'relative-short':
    case 'relative_short':
      this.innerText = this.date.toRelativeShortString()
      this.title = this.date.toLocaleString(this.locale, { timeZone: this.timeZone })
      break

    case 'duration-short':
    case 'duration_short':
      this.innerText = this.timestamp.toShortDurationString()
      this.title = this.innerText
      break
    case 'duration':
      this.innerText = this.timestamp.toDurationString()
      this.title = this.innerText
      break
    case 'duration-long':
    case 'duration_long':
      this.innerText = this.timestamp.toLongDurationString()
      this.title = this.innerText
      break
    default:
      this.innerText = FormattedTime.formatDate(this.date, this.format, { locale: this.locale, timeZone: this.timeZone })
    }
  }
}

window.customElements.define('formatted-time', FormattedTime)

////////// Setup

export function configureTimeZone() {
  const TIME_ZONE_COOKIE_REGEX = /^.*onspace-time-zone=(.*?)(;.*)?$/
  let existingCookieTimeZone = null
  if (document.cookie.match(TIME_ZONE_COOKIE_REGEX)) {
    existingCookieTimeZone = document.cookie.replace(TIME_ZONE_COOKIE_REGEX, '$1')
  }

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  document.cookie = `onspace-time-zone=${userTimeZone}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/`
  if (existingCookieTimeZone !== userTimeZone && document.referrer !== document.location.href) {
    window.location.reload()
  }

  const metaTimeZone = document.querySelector('meta[name=onspace-time-zone]')
  if (metaTimeZone) {
    FormattedTime.defaultTimeZone = metaTimeZone.content
  }

  const metaLocale = document.querySelector('meta[name=onspace-locale]')
  if (metaLocale) {
    FormattedTime.defaultLocale = metaLocale.content
  }
}

Date.prototype.toFormattedString = function(format) {
  const options = FormattedTime.formats[format]
  return this.toLocaleTimeString(undefined, options)
}
