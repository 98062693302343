import DropDown from '@onpace/onspace-core/elements/dropdown'

/// An element which emulates a HTML Select element.
///
/// This element inherits from DropDown, using the anchor as the form control, and the menu as the list.
export default class SelectCustom extends DropDown {
  /// Sets up the custom select element.
  ///
  /// Locates the required children and adds events where necessary.
  runConstructor() {
    super.runConstructor()

    this.multiple = this.hasAttribute('multiple')
    this.placeholder = this.getAttribute('placeholder')
    this.iconElement = this.querySelector('.select-custom__icon')
    this.textElement = this.querySelector('.select-custom__text')

    setTimeout(() => {
      this.setupOptions()
      this.setupFiltering()
      this.updateSelectedOption()
    })
  }

  /// Sets up listeners for any conditional elements.
  runConnected() {
    super.runConnected()

    this.conditionalIds = []
    const conditionalElements = this.querySelectorAll('[onspace-conditional-on]')
    Array.from(conditionalElements).forEach((element) => {
      const onId = element.getAttribute('onspace-conditional-on')
      if (this.conditionalIds.includes(onId)) { return }

      this.conditionalIds.push(onId)
      this.addDocumentBoundEventListener(`onspace:conditional:${onId}:change`, this.conditionalStateChanged)
    })
  }

  /// Cleans up listeners for any conditional elements.
  runDisconnected() {
    super.runDisconnected()

    if (!this.conditionalIds) { return }

    this.conditionalIds.forEach((onId) => {
      this.removeDocumentBoundEventListener(`onspace:conditional:${onId}:change`)
    })
    this.conditionalIds = []
  }

  ////////// Options

  /// Locates and configures option elements.
  setupOptions() {
    this.options = []

    const options = Array.from(this.querySelectorAll('label.select-custom__option'))
    options.forEach((option) => {
      if (option.input) { return }
      if (option.parentElement.hasAttribute('onspace-conditional-include') && !option.parentElement.hasAttribute('onspace-conditional-match')) { return }

      option.input = option.querySelector('input')
      option.placeholder = option.classList.contains('select-custom__option--placeholder')
      option.title = option.getAttribute('data-title') || ''
      option.info = option.getAttribute('data-info')
      option.iconName = option.getAttribute('data-icon')
      option.color = option.getAttribute('data-color')

      option.input.addEventListener('change', this.optionInputChanged.bind(this))
      option.filterText = option.title.toLowerCase()

      this.options.push(option)
    })

    this.highlightableElements = this.options
  }

  /// Updates the displayed info from the selected option.
  ///
  /// The info will be set from the selected +option+ element, using the following:
  /// - The info text will be set from the +info+ attribute, or of not available will use the inner text.
  /// - The icon will optionally be set from the +icon+ attribute.
  updateSelectedOption() {
    if (!this.textElement) { return }

    if (this.multiple) {
      const texts = []
      const selectedInputs = this.querySelectorAll('input:checked')
      selectedInputs.forEach((input) => {
        const element = input.parentElement
        const text = element.info || element.title
        texts.push(text)
      })

      if (texts.length > 0) {
        this.textElement.innerText = texts.join(', ')
        this.classList.remove('select-custom--placeholder')
      } else {
        this.textElement.innerText = this.placeholder
        this.classList.add('select-custom--placeholder')
      }
    } else {
      const selectedInput = this.querySelector('input:checked')

      if (selectedInput) {
        const selectedElement = selectedInput.parentElement
        if (!selectedElement.parentElement.hasAttribute('onspace-conditional-include') || selectedElement.parentElement.hasAttribute('onspace-conditional-match')) {
          this.textElement.innerText = selectedElement.info || selectedElement.title

          if (selectedElement.iconName) {
            this.iconElement.setOnspaceSpritemapSvg(selectedElement.iconName)
          } else {
            this.iconElement.innerHTML = ''
          }

          if (selectedElement.placeholder) {
            this.classList.add('select-custom--placeholder')
          } else {
            this.classList.remove('select-custom--placeholder')
          }

          return
        }
      }

      this.textElement.innerText = this.placeholder
      this.iconElement.innerHTML = ''
      this.classList.add('select-custom--placeholder')
    }
  }

  /// Retrieves the currently selected value(s).
  get value() {
    if (this.multiple) {
      const selectedInputs = Array.from(this.querySelectorAll('input:checked'))
      return selectedInputs.map((input) => input.value)
    } else {
      const selectedInput = this.querySelector('input:checked')

      if (selectedInput) {
        return selectedInput.value
      } else {
        return false
      }
    }
  }

  ////////// Filtering

  /// Locates and configures filtering elements.
  setupFiltering() {
    this.filterInput = this.querySelector('.onspace-dropdown__search__input')
    if (this.filterInput) {
      this.filterInput.addEventListener('input', this.updateFilteredElements.bind(this))
    }
  }

  /// Filters list items.
  ///
  /// This compares the text in the filter input against each list item's +filterText+, and shows or hides them as
  /// appropriate.
  ///
  /// This automatically highlights the first visible item.
  updateFilteredElements(_event) {
    const unfilteredElements = []
    const text = this.filterInput.value.toLowerCase()

    if (text.length > 0) {
      this.options.forEach((option) => {
        if (option.filterText.includes(text)) {
          option.style.display = ''
          unfilteredElements.push(option)
        } else {
          option.style.display = 'none'
        }
      })
    } else {
      this.options.forEach((option) => {
        option.style.display = ''
        unfilteredElements.push(option)
      })
    }

    this.highlightableElements = unfilteredElements
    this.highlightedElement = unfilteredElements[0]
  }

  /// Clears the current input filter.
  clearFiltering() {
    if (this.filterInput) {
      this.filterInput.value = ''
      this.updateFilteredElements()
    }
  }

  ////////// State

  /// Detects whether the element is disabled.
  ///
  /// This overrides Dropdown.disabled, checking if the parent +fieldset+ is disabled.
  get disabled() {
    const fieldset = this.closest('fieldset')
    if (fieldset) {
      return fieldset.disabled
    } else {
      return false
    }
  }

  /// Shows the menu.
  ///
  /// This overrides Dropdown.showMenu to also focus the filter input, if available.
  showMenu() {
    super.showMenu()

    if (this.filterInput) {
      this.filterInput.focus()
      this.updateMenuSize()
    }
  }

  /// Hides the menu.
  ///
  /// This overrides DropDown.hideMenu to also clear any filtering, and focuses the anchor.
  hideMenu() {
    super.hideMenu()

    this.clearFiltering()
    if (this.anchor) {
      this.anchor.focus()
    }
  }

  /// Calculates the size of the menu and explicitly sets the width.
  ///
  /// This is necessary to prevent the size changing when filtering.
  updateMenuSize() {
    this.menu.style.width = ''
    setTimeout(() => {
      const bounds = this.menu.getBoundingClientRect()
      this.menu.style.width = `${bounds.width}px`
    })
  }

  ////////// Remote

  /// Callback for when a turbo frame element finishes loading.
  ///
  /// This overrides DropDown.turboFrameLoaded to setup the select options and filtering once loaded.
  turboFrameLoaded(event) {
    const existingOptions = this.options

    this.setupOptions()
    this.setupFiltering()

    existingOptions.forEach((option) => {
      if (option.input.checked) {
        const input = this.menu.querySelector(`input[value="${option.input.value}"]`)
        if (input) {
          input.checked = true
        }
      }
    })

    this.updateMenuSize()
    this.highlightSelectedElement()

    super.turboFrameLoaded(event)
  }

  ////////// Selection

  /// Retrieves the currently selected item.
  ///
  /// This overrides DropDown.selectedElement to add support for the select list options.
  get selectedElement() {
    const selectedInput = this.querySelector('input:checked')
    if (selectedInput) {
      return selectedInput.parentElement
    } else {
      return null
    }
  }

  /// Selects the currently highlighted element.
  ///
  /// This overrides DropDown.selectHighlightedElement to add support for the select list options.
  selectHighlightedElement() {
    const highlightedOption = this.highlightedElement
    if (highlightedOption) {
      if (this.multiple) {
        highlightedOption.input.checked = !highlightedOption.input.checked
      } else {
        highlightedOption.input.checked = true
      }

      highlightedOption.input.triggerEvent('change')
    }
  }

  ////////// Events

  /// Callback when an input element changes.
  ///
  /// This hides the menu on selection, after a delay.
  optionInputChanged(_event) {
    this.updateSelectedOption()
    this.triggerEvent('onspace:select-custom:change')

    if (!this.multiple) {
      setTimeout(() => this.hideMenu(), 200)
    }
  }

  /// Callback when a conditional element changes.
  ///
  /// This recalculates the available options, and updates the selected option.
  conditionalStateChanged(_event) {
    if (this.options) {
      this.options.forEach((option) => {
        option.input.checked = false
      })
    }

    this.setupOptions()
    this.updateSelectedOption()
  }

  /// Callback for keypressed on the escape key.
  ///
  /// This overrides DropDown.menuEscaped, to instead clear the filter input when it is not empty.
  menuEscaped() {
    if (this.filterInput && this.filterInput.value !== '') {
      this.clearFiltering()
    } else {
      super.menuEscaped()
    }
  }

  /// Callback for keypressed on the enter key.
  ///
  /// This overrides Dropdown.anchorEntered to submit the form.
  anchorEntered() {
    if (this.menuActive) { return }

    const form = this.closest('form')
    if (form) {
      const button = form.querySelector('input[type=submit], button[type=submit]')
      button.focus()
      button.click()
    }
  }
}

window.customElements.define('select-custom', SelectCustom)
