document.addEventListener('turbo:load', (_event) => setupTables(document))
document.addEventListener('turbo:frame-load', (event) => setupTables(event.target))

const isTouchScreen = window.matchMedia('(pointer: coarse)').matches

function setupTables(element) {
  element.querySelectorAll('table.onspace-table').forEach((table) => {
    let lastClickedIndex = 0

    const rows = Array.from(table.querySelectorAll('tr'))
    rows.forEach((row, rowIndex) => {
      const href = row.getAttribute('data-mobile-href')
      const batchCheckbox = row.querySelector('input[name=onspace_batch_selection]')
      row.batchCheckbox = batchCheckbox

      if (batchCheckbox) {
        batchCheckbox.setChecked = function(checked) {
          if (this.checked === checked) { return }
          this.click()
        }

        batchCheckbox.addEventListener('click', (event) => {
          event.stopPropagation()
        })

        batchCheckbox.addEventListener('change', (_event) => {
          row.classList.toggle('onspace-table__tr__selected', batchCheckbox.checked)
        })
      }

      row.addEventListener('click', (event) => {
        if (event.target.closest('a')) { return }

        const desktopButtons = row.querySelector('td.onspace-table__buttons .onspace-button-group')
        const isDesktop = desktopButtons && desktopButtons.getComputedStyle().display !== 'none'

        if (href && !document.body.classList.contains('onspace-batch-selecting')) {
          if (!isDesktop) {
            window.Turbo.visit(href)
            return
          }
        }

        if (batchCheckbox) {
          if (isTouchScreen) {
            batchCheckbox.setChecked(!batchCheckbox.checked)
          } else {
            if (event.shiftKey) {
              if (rowIndex > lastClickedIndex) {
                for (let i=rowIndex; i>=lastClickedIndex; i--) {
                  const input = rows[i].batchCheckbox
                  if (input) {
                    input.setChecked(true)
                  }
                }
              } else {
                for (let i=rowIndex; i<=lastClickedIndex; i++) {
                  const input = rows[i].batchCheckbox
                  if (input) {
                    input.setChecked(true)
                  }
                }
              }
            } else if (event.metaKey || event.ctrlKey) {
              batchCheckbox.setChecked(!batchCheckbox.checked)
            } else {
              rows.forEach((tableRow) => {
                if (tableRow.batchCheckbox && tableRow != row) {
                  tableRow.batchCheckbox.setChecked(false)
                }

                batchCheckbox.setChecked(true)
              })
            }

            lastClickedIndex = rowIndex
          }

          document.triggerEvent('onspace:batch-selection:changed')
        }
      })
    })
  })
}
