import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import { Uploader } from '@onpace/onspace-media/components/uploads'

let dragEnterCount = 0

document.addEventListener('dragenter', (_event) => {
  dragEnterCount += 1
  document.body.classList.add('onspace-blob-dragging')
})
document.addEventListener('dragleave', (_event) => {
  if (dragEnterCount <= 1) {
    dragEnterCount = 0
    document.body.classList.remove('onspace-blob-dragging')
  } else {
    dragEnterCount -= 1
  }
})
document.addEventListener('dragover', (event) => {
  event.stopPropagation()
  event.preventDefault()
  return false
})
document.addEventListener('drop', (event) => {
  dragEnterCount = 0
  document.body.classList.remove('onspace-blob-dragging')

  event.stopPropagation()
  event.preventDefault()
  return false
})

/// An element which performs blob file uploads.
///
/// This works by pre-uploading any new files to the configured Uploads Controller before the form is submitted. It
/// updates the form with the blob tokens in hidden fields once complete.
///
/// You must set the +name+ attribute on this class to the parameter name in the form, and the following children at
/// root level:
/// - A blank 'hidden' input, which is used in the case where no file is set.
/// - A file input, which should be wrapped in a `label.onspace-button`.
///
/// This supports array values on it's own, rather than needing to use InputArray.
export class InputBlob extends CustomHTMLElement {
  /// Sets up the input blob element.
  ///
  /// Locates the required children and adds events where necessary.
  runConstructor() {
    super.runConstructor()

    this.name = this.getAttribute('name')
    this.maxFileSize = this.getIntegerAttribute('data-max-size')
    this.translations = this.getJsonAttribute('data-translations')

    this.inputElement = this.querySelector('input[type=file]')
    this.inputElement.addEventListener('change', this.inputFilesChanged.bind(this))

    this.fileNameElement = document.querySelector(`input[data-blob-filename="${this.id}"]`)

    const fileTypes = this.inputElement.accept
    if (typeof fileTypes === 'string' && fileTypes.length > 0) {
      this.fileTypes = fileTypes.split(',')
    } else {
      this.fileTypes = null
    }

    this.array = this.inputElement.multiple

    this.errorElements = []

    this.dragAreaElement = this.querySelector('.input-blob__dragarea')
    if (this.dragAreaElement) {
      this.dragAreaElement.addEventListener('dragenter', this.dragEntered.bind(this))
      this.dragAreaElement.addEventListener('dragleave', this.dragLeft.bind(this))
      this.dragAreaElement.addEventListener('drop', this.dragDropped.bind(this))
    }
  }

  /// Detects whether this element is disabled, based on a parent fieldset.
  get disabled() {
    const fieldset = this.closest('fieldset')
    if (fieldset) {
      return fieldset.disabled
    } else {
      return false
    }
  }

  /// Displays an error message below the element.
  showErrorMessage(message) {
    const errorElement = document.createElement('div')
    errorElement.classList.add('onspace-form__field__comment')
    errorElement.classList.add('onspace-form__field__comment--error')
    errorElement.innerText = message

    this.after(errorElement)
    this.errorElements.push(errorElement)
  }

  /// Clears error messages displayed by the element.
  clearErrorMessages() {
    this.errorElements.forEach(el => el.remove())
    this.errorElements = []
  }

  ////////// Input

  /// Listens for changes to the file input.
  ///
  /// This runs +processAddedFiles+, then clears the input's value.
  inputFilesChanged(_event) {
    const files = Array.from(this.inputElement.files)
    this.processAddedFiles(files)

    this.inputElement.value = ''
  }

  ////////// Drag and Drop

  /// Listens for when a file drag enters the dragarea element.
  ///
  /// This adds a class to show that the drop is over this element.
  dragEntered(_event) {
    this.classList.add('blob-input--drag-active')
  }

  /// Listens for when a file drag leaves the dragarea element.
  ///
  /// This removes the class showing that the drop is over this element.
  dragLeft(_event) {
    this.classList.remove('blob-input--drag-active')
  }

  /// Listens for when a file is dropped over the dragarea element.
  ///
  /// This collects the dropped files and sends them to +processAddedFiles+. It also calls +dragLeft+ to clear the
  /// dragging UI.
  dragDropped(event) {
    this.dragLeft(event)

    const files = Array.from(event.dataTransfer.files)
    this.processAddedFiles(files)

    event.preventDefault()
    return false
  }

  ////////// Files

  /// Retrieves the file elements from the DOM.
  get fileElements() {
    return this.querySelectorAll('input-blob-file')
  }

  /// Loops through each file for upload.
  ///
  /// This first ensures that the number of uploaded files is allowed. For non-array fields, only one file can be given.
  /// If more than one file is given, this shows an error. If there is already an existing file, it is replaced with the
  /// newly added file. For array fields, any amount of files are allowed, and existing files are not touched.
  ///
  /// Each file is then looped through. For each file we check that it passes validation, then create an InputBlobFile,
  /// appending that to this element.
  processAddedFiles(files) {
    this.clearErrorMessages()

    if (!this.array) {
      if (files.length > 1) {
        this.showErrorMessage(this.translations['one_file'])
        return
      } else {
        this.fileElements.forEach((e) => e.remove())
      }
    }

    files.forEach((file) => {
      if (!this.validateFile(file)) { return }

      const fileElement = new InputBlobFile(file, this.name)
      this.append(fileElement)
    })

    if (this.fileNameElement && !this.fileNameElement.value) {
      this.fileNameElement.value = files[0].name.replace(/\..*?$/, '')
    }

    this.triggerEvent('onspace:input-blob:change')
  }

  /// Checks that a file is valid.
  ///
  /// This performs two checks:
  /// - Ensures that the content type of the file matches the file types supported by the element.
  /// - Ensures that the file size is under the maximum file size of the element.
  validateFile(file) {
    let acceptFile = true

    if (this.fileTypes) {
      let acceptFileType = false
      this.fileTypes.forEach((fileType) => {
        const match = fileType.match(/^(.+\/)\*$/)
        if (match) {
          if (file.type.startsWith(match[1])) {
            acceptFileType = true
          }
        } else {
          if (file.type == fileType) {
            acceptFileType = true
          }
        }
      })

      if (!acceptFileType) {
        acceptFile = false
        this.showErrorMessage(this.translations['file_type'])
      }
    }

    if (this.maxFileSize && file.size > this.maxFileSize) {
      acceptFile = false
      this.showErrorMessage(this.translations['file_size'])
    }

    return acceptFile
  }
}

/// An elements which operates a single file within an InputBlob.
///
/// This is responsible for the file-specific functionality of it's parent element. There are two ways to use this:
/// - Create a new element from Javascript. Simply pass a file and the parent's name as arguments.
/// - As an existing file in the DOM. It should include some text with the file name, and a hidden element with the
///   file token/id.
class InputBlobFile extends CustomHTMLElement {
  /// Sets up the file element.
  ///
  /// For a new element, creates elements then adds events where necessary. For an existing element, locates the
  /// children then adds events where necessary.
  runConstructor(file, name) {
    super.runConstructor()

    if (file) {
      this.file = file
      this.name = name
      this.requiresUpload = true

      this.classList.add('input-blob-file--uploading')

      const nameElement = document.createElement('span')
      nameElement.innerText = file.name
      this.appendChild(nameElement)
    }

    this.iconElement = SVGElement.createOnspaceSpritemapSvg('onspace/icon_cross')

    this.buttonElement = document.createElement('a')
    this.buttonElement.classList.add('input-blob-file__button')
    this.buttonElement.appendChild(this.iconElement)
    this.buttonElement.addEventListener('click', this.buttonClicked.bind(this))
    this.appendChild(this.buttonElement)
  }

  /// Detects whether this element is disabled, based on a parent fieldset.
  get disabled() {
    const fieldset = this.closest('fieldset')
    if (fieldset) {
      return fieldset.disabled
    } else {
      return false
    }
  }

  /// Callback for clicking the button element.
  ///
  /// This removes this element from the DOM.
  buttonClicked(event) {
    if (this.disabled) { return }

    const blob = this.closest('input-blob')
    this.remove()
    event.stopPropagation()

    blob.triggerEvent('onspace:input-blob:change')
  }

  //////////

  /// Prepares the element to upload a file.
  ///
  /// This updates the UI to indicate that this file is being uploaded. It also creates an Uploader instance, which will
  /// handle the networking for the upload.
  prepareUpload() {
    this.classList.remove('input-blob-file--error')
    if (this.errorElement) {
      this.errorElement.remove()
      this.errorElement = null
    }

    this.uploader = new Uploader(this)

    this.progressElement = document.createElement('div')
    this.progressElement.classList.add('input-blob-file__progress')
    this.appendChild(this.progressElement)
  }

  /// Cleans up from a file upload.
  ///
  /// This removes any UI used during the upload, and removes the Uploader instance.
  cleanupUpload() {
    this.uploader = null

    this.progressElement.remove()
    this.progressElement = null
  }

  /// Begins the file upload.
  ///
  /// This simply calls +start()+ on the Uploader instance.
  upload() {
    return this.uploader.start()
  }

  /// Callback which runs whenever the upload partially progresses.
  ///
  /// This updates the UI to show the current progress of the file upload.
  uploadDidProgress(progress) {
    this.progressElement.style.width = `${progress * 100}%`
  }

  /// Callback which runs whenever the upload fails.
  ///
  /// This updates the UI to indicate there was an error, and adds the error message to the DOM.
  uploadDidError(error) {
    this.cleanupUpload()

    this.classList.add('input-blob-file--error')

    this.errorElement = document.createElement('div')
    this.errorElement.classList.add('onspace-form__field__comment')
    this.errorElement.classList.add('onspace-form__field__comment--error')
    this.errorElement.innerText = error

    this.after(this.errorElement)
  }

  /// Callback which runs whenever the upload succeeds.
  ///
  /// This updates the UI to indicate the upload was successful, and adds a hidden input to the DOM containing the newly
  /// created blob's token.
  uploadDidFinish(blob) {
    this.cleanupUpload()

    this.classList.remove('input-blob-file--uploading')
    this.classList.add('input-blob-file--uploaded')

    this.requiresUpload = false

    const hiddenElement = document.createElement('input')
    hiddenElement.name = this.name
    hiddenElement.setAttribute('type', 'hidden')
    hiddenElement.setAttribute('value', blob.token)
    this.appendChild(hiddenElement)
  }
}

window.customElements.define('input-blob', InputBlob)
window.customElements.define('input-blob-file', InputBlobFile)
