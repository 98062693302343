////////// Attributes //////////

/// Returns the date for the current day at midnight.
Date.today = function() {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

/// Returns the date for the next day at midnight.
Date.tomorrow = function() {
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  tomorrow.setHours(0, 0, 0, 0)
  return tomorrow
}

////////// Calculations //////////

/// Return the number of milliseconds between a Time, and now.
Object.defineProperty(Date.prototype, 'intervalUntilNow', {
  get: function intervalUntilNow() {
    const now = new Date()
    return this.getTime() - now.getTime()
  }
})

/// Return a boolean as to whether a Date has passed, by comparing said Date to now.
Object.defineProperty(Date.prototype, 'hasPassed', {
  get: function hasPassed() {
    const now = new Date()
    return this <= now
  }
})

/// Return a boolean as to whether a Date is today.
Object.defineProperty(Date.prototype, 'isToday', {
  get: function isToday() {
    const now = new Date()
    return this.getDate() === now.getDate() && this.getMonth() === now.getMonth() && this.getFullYear() === now.getFullYear()
  }
})

////////// ISO Formats //////////

/// Return a Date as YYYY-MM-DD.
Date.prototype.toISODateString = function() {
  return this.toISOString().substring(0,10)
}

/// Return a Date as YYYY-MM.
Date.prototype.toISOMonthString = function() {
  return this.toISOString().substring(0,7)
}

////////// Relative Timings //////////

/// Return a human readable description for the amount of time that has either passed, or is left to go, based on the
///  difference between a Time and now.
///
/// Return words in long format.
Date.prototype.toRelativeString = function() {
  let difference = this.intervalUntilNow / 1000
  const past = (difference <= 0)
  difference = Math.abs(difference)

  if (this.isToday) {
    let content = ''
    if (difference < 90) {
      content = '1 minute'
    } else if (difference < 3600) {
      const minutes = Math.round(difference / 60)
      content = `${minutes} minutes`
    } else if (difference < 5400) { // 1.5 hours
      content = '1 hour'
    } else {
      const hours = Math.round(difference / 3600)
      content = `${hours} hours`
    }

    if (past) {
      return `${content} ago`
    } else {
      return `in ${content}`
    }
  } else {
    if (difference < 604800) { // 7 days
      return this.weekdayName
    } else {
      return this.toLocaleDateString()
    }
  }
}

/// Return words in short format.
Date.prototype.toRelativeShortString = function() {
  let difference = this.intervalUntilNow / 1000
  const past = (difference <= 0)
  const sign = past ? '-' : ''
  difference = Math.abs(difference)

  if (this.isToday) {
    if (difference < 90) {
      const seconds = Math.round(difference)
      return `${sign}${seconds}s`
    } else if (difference < 3600) {
      const minutes = Math.round(difference / 60)
      return `${sign}${minutes}m`
    } else {
      const hours = Math.round(difference / 3600)
      return `${sign}${hours}h`
    }
  } else {
    if (difference < 604800) { // 7 days
      return this.shortWeekdayName
    } else {
      return this.toShortDateString()
    }
  }
}

////////// Variables //////////


/// List all weekday names as full words.
Object.defineProperty(Date.prototype, 'weekdayName', {
  get: function weekdayName() {
    return this.toLocaleString(undefined, { weekday: 'long' })
  }
})

/// List all weekday names as their shortened words.
Object.defineProperty(Date.prototype, 'shortWeekdayName', {
  get: function shortWeekdayName() {
    return this.toLocaleString(undefined, { weekday: 'short' })
  }
})

/// List all month names as full words.
Object.defineProperty(Date.prototype, 'monthName', {
  get: function monthName() {
    return this.toLocaleString(undefined, { month: 'long' })
  }
})

/// List all month names as their shortened words.
Object.defineProperty(Date.prototype, 'shortMonthName', {
  get: function shortMonthName() {
    return this.toLocaleString(undefined, { month: 'short' })
  }
})
