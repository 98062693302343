const CHANGE_EVENT_INPUTS = ['checkbox', 'color', 'file', 'radio', 'range']

/// Responds to change events on the this.
///
/// When an event is received, it is first checked that the event is a valid change event, as there are some events that
/// may be triggered here that don't indicate something on the form is changed. It then marks the form as changed, and
/// triggers the +onspace:change+ event.
///
/// This is setup by HTMLFormElement.onspaceListenForChanges and should not be called directly.
HTMLFormElement.prototype.onspaceElementChanged = function(event) {
  const eventElement = event.target.closest('input, input-association, rich-text, select, textarea')
  if (!eventElement) { return }

  switch (eventElement.tagName) {
  case 'INPUT':
    if (event.type === 'change' && !CHANGE_EVENT_INPUTS.includes(eventElement.type)) { return }
    if (event.type !== 'change' && CHANGE_EVENT_INPUTS.includes(eventElement.type)) { return }
    break
  case 'TEXTAREA':
    if (event.type === 'change') { return }
  }

  this.onspaceHasChanges = true
  this.triggerEvent('onspace:form:change')
}

/// Listens for changes on all of the form's controls.
///
/// This adds listeners to itself, which will trigger when that event propagates from any of it's children. All event
/// listeners will use HTMLFormElement.onspaceElementChanged as their callback.
HTMLFormElement.prototype.onspaceListenForChanges = function() {
  this.addEventListener('submit', (_event) => {
    this.onspaceHasChanges = false
  })

  this.addEventListener('input', this.onspaceElementChanged.bind(this))
  this.addEventListener('change', this.onspaceElementChanged.bind(this))

  this.addEventListener('onspace:input-association:change', this.onspaceElementChanged.bind(this))
  this.addEventListener('onspace:rich-text:change', this.onspaceElementChanged.bind(this))
}

document.addEventListener('turbo:load', (_event) => {
  document.querySelectorAll('form[onspace-prompt-changes]').forEach((form) => form.onspaceListenForChanges())
})

document.addEventListener('turbo:frame-load', (_event) => {
  document.querySelectorAll('form[onspace-prompt-changes]').forEach((form) => form.onspaceListenForChanges())
})

/// Determines if the element has a child form which has changed.
Object.defineProperty(HTMLElement.prototype, 'onspaceHasChangedForm', {
  get: function() {
    const forms = Array.from(this.querySelectorAll('form'))
    return forms.some(f => f.onspaceHasChanges)
  }
})
Object.defineProperty(document, 'onspaceHasChangedForm', {
  get: function() {
    const forms = Array.from(this.querySelectorAll('form'))
    return forms.some(f => f.onspaceHasChanges)
  }
})

//////////

HTMLFormElement.prototype.onspaceDisableForm = function(button) {
  this.querySelectorAll('fieldset').forEach((element) => element.disabled = true)

  if (button) {
    const icon = SVGElement.createOnspaceSpritemapSvg('onspace/icon_loading')
    button.appendChild(icon)
    button.turboLoadingIcon = icon
  }
}

HTMLFormElement.prototype.onspaceEnableForm = function enableForm(button) {
  this.querySelectorAll('fieldset').forEach((element) => element.disabled = false)

  if (button) {
    if (button.turboLoadingIcon) {
      button.turboLoadingIcon.remove()
      button.turboLoadingIcon = null
    }
  }
}
