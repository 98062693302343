import { register } from '@onpace/onspace-core/components/translations'

register({
  "en": {
    "onspace": {
      "media": {
        "player": {
          "playback": {
            "click_to_play": "Click to play",
            "click_to_activate": "Click to enable sound",
            "media_remote": "This media is playing on an external screen",
            "video_picture_in_picture": "This video is playing in picture in picture"
          },
          "chapter": {
            "skip": {
              "recap": "Skip Recap",
              "intro": "Skip Intro",
              "preview": "Skip Preview",
              "credits": "Skip Credits"
            }
          },
          "up_next": {
            "title": "Up Next",
            "play": "Play"
          },
          "multi_screen": {
            "selection_tab": "Choose Media",
            "max_limit": {
              "singular": "This screen size only supports %{limit} concurrent player",
              "plural": "This screen size only supports %{limit} concurrent players"
            }
          },
          "controls": {
            "live": "LIVE"
          },
          "advertisement": {
            "sponsored": "Sponsored",
            "continue_after": "Media plays\nafter ad",
            "continue_skip": "Skip Ad",
            "continue_skip_in": "Skip in"
          },
          "error": {
            "source_missing": "The source could not be found.",
            "source_load_error": "The source could not be loaded.",
            "source_unavailable": "The source is no longer available.",
            "unknown_fatal": "An error occurred loading the media.",
            "retry": "Retry"
          }
        }
      }
    }
  }
})
