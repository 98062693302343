document.addEventListener('turbo:load', (_event) => {
  setupBackButtons(document)
})
document.addEventListener('turbo:frame-load', (event) => {
  setupBackButtons(event.target)
})

function setupBackButtons(target) {
  target.querySelectorAll('.onspace-form .onspace-heading__back__link[data-form-change-title]').forEach((link) => {
    const form = link.closest('.onspace-form')

    link.formChanged = function(_event) {
      const changeTitle = link.getAttribute('data-form-change-title')
      const span = link.querySelector('span')
      span.innerText = changeTitle

      form.removeEventListener('onspace:form:change', link.formChanged)
    }

    form.addEventListener('onspace:form:change', link.formChanged)
  })
}
