import RestService, { createQueryString, decodeQueryString } from '@onpace/api_service/rest_service'
import Chart from 'chart.js/auto'

import CustomHTMLElement from '@onpace/onspace-core/components/html_element'
import DropDown from '@onpace/onspace-core/elements/dropdown'
import FormattedTime from '@onpace/onspace-core/elements/formatted_time'

import translation from '@onpace/onspace-core/components/translations'
import WorldMap from '@onpace/onspace-analytics/components/world_map'

const client = new RestService()

const DISPLAY_TYPE_BAR = 'bar'
const DISPLAY_TYPE_LINE = 'line'
const DISPLAY_TYPE_PIE = 'pie'
const DISPLAY_TYPE_MAP = 'map'
const DISPLAY_TYPE_TABLE = 'table'
const DISPLAY_TYPE_TABLE_MAP = 'table-map'
const DISPLAY_TYPES = [DISPLAY_TYPE_BAR, DISPLAY_TYPE_LINE, DISPLAY_TYPE_PIE, DISPLAY_TYPE_MAP, DISPLAY_TYPE_TABLE, DISPLAY_TYPE_TABLE_MAP]

const NUMERIC_DIMENSION_DATASETS = 'datasets'
const NUMERIC_DIMENSION_KEYS = 'keys'
const NUMERIC_DIMENSIONS = [NUMERIC_DIMENSION_DATASETS, NUMERIC_DIMENSION_KEYS]

const KEY_FORMAT_DATE = 'date'
const KEY_FORMAT_HOUR = 'hour'
const KEY_FORMAT_MINUTE = 'minute'
const KEY_FORMAT_MONTH = 'month'
const KEY_FORMATS_DATETIME = [KEY_FORMAT_DATE, KEY_FORMAT_HOUR, KEY_FORMAT_MINUTE, KEY_FORMAT_MONTH]

const PAGINATION_LIMITS = [5, 10, 25, 50]

////////// Metric

/// An element which displays an analytics metric.
export default class OnspaceMetric extends CustomHTMLElement {
  /// Sets up the metric element.
  ///
  /// This creates a new metric from options and/or attributes. The following parameters are available:
  /// [href]
  ///   A path or URL to the endpoint which contains the metric data for this element.
  /// [display]
  ///   The type of UI used to display the data. Available values are:
  ///   [bar]
  ///     Displays the data in a bar chart.
  ///   [line]
  ///     Displays the data in a line chart.
  ///   [pie]
  ///     Displays the data in a pie chart.
  ///     Displays the data as a list large numbers.
  ///   [table]
  ///     Displays the data in a table.
  ///   [table-map]
  ///     Displays a hybrid with a map above a table.
  /// [numeric]
  ///   Displays numeric data alongside the main display. This can be configured to use one of the following as the
  ///   primary dimension:
  ///   [datasets]
  ///     Displays the +total+ figure for each dataset, using each dataset's title.
  ///   [keys]
  ///     Uses the keys for the metric, and displays the corresponding values in each dataset.
  ///
  /// [key-location]
  ///   An optional URL which will be used as a link on each key. A filter using the key will be appended to the URL.
  ///
  ///   This is only supported when using the +table+ display type.
  runConstructor() {
    super.runConstructor()

    this.href = this.getAttribute('href')
    this.displayType = this.getAttribute('display')
    this.keyLocation = this.getAttribute('key-location')
    this.numeric = this.getAttribute('numeric') || null

    this.summary = this.getBooleanAttribute('summary')
    this.live = this.getBooleanAttribute('live')

    if (this.summary || this.live) {
      this.paginationPage = 1
      this.paginationLimit = 5
    } else {
      this.paginationPage = 1
      this.paginationLimit = 10
    }

    if (!DISPLAY_TYPES.includes(this.displayType)) { console.error(`Invalid chart display type "${this.displayType}"`) } // eslint-disable-line no-console
    if (this.numeric && !NUMERIC_DIMENSIONS.includes(this.numeric)) { console.error(`Invalid numeric display mode "${this.numeric}"`) } // eslint-disable-line no-console

    this.classList.add('onspace-metric')
    this.classList.add(`onspace-metric--${this.displayType}`)
    if (this.numeric) { this.classList.add(`onspace-metric--${this.displayType}-numeric`) }
  }

  /// Runs when the element is first connected to the DOM.
  runFirstConnected() {
    super.runFirstConnected()

    if (this.numeric) {
      this.setupNumeric()
    }

    switch (this.displayType) {
    case DISPLAY_TYPE_BAR:
    case DISPLAY_TYPE_LINE:
    case DISPLAY_TYPE_PIE:
      this.setupChart()
      break
    case DISPLAY_TYPE_MAP:
      this.setupMap()
      break
    case DISPLAY_TYPE_TABLE:
      this.setupTable()
      break
    case DISPLAY_TYPE_TABLE_MAP:
      this.setupMap()
      this.setupTable()
      break
    }

    this.setupControls()

    this.loadRemoteMetric()
  }

  /// Run when the element is connected to the DOM.
  runConnected() {
    super.runConnected()

    switch (this.displayType) {
    case DISPLAY_TYPE_BAR:
    case DISPLAY_TYPE_LINE:
    case DISPLAY_TYPE_PIE:
      this.chartConnected()
      break
    }
  }

  /// Run when the element is disconnected from the DOM.
  runDisconnected() {
    super.runDisconnected()

    switch (this.displayType) {
    case DISPLAY_TYPE_BAR:
    case DISPLAY_TYPE_LINE:
    case DISPLAY_TYPE_PIE:
      this.chartDisconnected()
      break
    }
  }

  ////////// Remote

  /// Loads the metric data from a remote location.
  ///
  /// This attempts to load the metric data from the given.
  async loadRemoteMetric({ reload = false } = {}) {
    if (!reload) { this.showOverlay({ icon: 'onspace/logo_onspace_loading' }) }

    const params = {}

    if (this.summary) {
      params.summary = true
    }
    if (this.live) {
      params.date = 'live'
    }

    if (this.paginationPage && this.paginationLimit) {
      params.pagination = {
        page: this.paginationPage,
        limit: this.paginationLimit
      }
    }

    try {
      const response = await client.GET(this.href, { params })
      const content = await response.content()
      this.displayMetric(content.metric)
      this.displayPagination(content.pagination)
      this.removeOverlay()
    } catch (error) {
      this.showError(translation('onspace.analytics.metric.error.unknown_fatal'))
    }
  }

  /// Tells the browser to download an export of the current metric.
  exportMetric(format) {
    let url = this.href
    let urlQueryParams = {}
    if (url.includes('?')) {
      const queryString = url.split('?')[1]
      url = url.replace(/\?.*$/, '')
      urlQueryParams = decodeQueryString(queryString)
    }

    const params = {
      ...urlQueryParams,
      export: true
    }

    const queryString = createQueryString(params)
    url = `${url}.${format}?${queryString}`
    window.location = url
  }

  /// Configures and displays a metric.
  ///
  /// This decides how the data needs to be displayed based on the metric type.
  displayMetric(metric) {
    this.metricKeys = metric.keys
    this.metricKeyFormat = metric.key_format

    switch (this.displayType) {
    case DISPLAY_TYPE_BAR:
    case DISPLAY_TYPE_LINE:
    case DISPLAY_TYPE_PIE:
      this.displayChartMetric(metric)
      break
    case DISPLAY_TYPE_MAP:
      this.displayMapMetric(metric)
      break
    case DISPLAY_TYPE_TABLE:
      this.displayTableMetric(metric)
      break
    case DISPLAY_TYPE_TABLE_MAP:
      this.displayTableMetric(metric)
      this.displayMapMetric(metric)
      break
    }

    if (this.numeric) { this.displayNumericMetric(metric) }
  }

  ////////// Keys

  /// Outputs a dataset title in a formatted state.
  ///
  /// This parses the raw title and determines how to display it, if necessary.
  formatDatasetTitle(title, { html = false } = {}) {
    if (typeof title !== 'string' || title.length === 0) {
      if (html) {
        return `<span class="onspace-metric__blank">${translation('onspace.analytics.metric.key.blank')}</span>`
      } else {
        return translation('onspace.analytics.metric.key.blank')
      }
    }

    return title
  }

  /// Outputs a key in a formatted state.
  ///
  /// This parses the raw key and determines how to display it, if necessary.
  formatKey(key, { full = false, html = false } = {}) {
    if (typeof key === 'number') {
      const date = new Date(key)
      switch (this.metricKeyFormat) {
      case KEY_FORMAT_DATE:
        if (full) {
          return FormattedTime.formatDate(date, 'date_long')
        } else {
          return FormattedTime.formatDate(date, 'date')
        }
      case KEY_FORMAT_MONTH:
        if (full) {
          return FormattedTime.formatDate(date, 'month_long')
        } else {
          return FormattedTime.formatDate(date, 'month')
        }
      case KEY_FORMAT_HOUR:
        if (full) {
          return FormattedTime.formatDate(date, 'datetime_long')
        } else {
          return FormattedTime.formatDate(date, 'date')
        }
      case KEY_FORMAT_MINUTE:
        return FormattedTime.formatDate(date, 'time_short')
      default:
        if (full) {
          return FormattedTime.formatDate(date, 'datetime_long')
        } else {
          return FormattedTime.formatDate(date, 'datetime')
        }
      }
    } else if (typeof key !== 'string' || key.length === 0) {
      if (html) {
        return `<span class="onspace-metric__blank">${translation('onspace.analytics.metric.key.blank')}</span>`
      } else {
        return translation('onspace.analytics.metric.key.blank')
      }
    }

    return key
  }

  ////////// Chart

  /// Creates and configures the chart element.
  setupChart() {
    this.chartContainer = document.createElement('div')
    this.chartContainer.classList.add('onspace-metric__chart')
    this.appendChild(this.chartContainer)

    this.canvasElement = document.createElement('canvas')
    this.chartContainer.appendChild(this.canvasElement)

    let chartType = null
    const options = {
      animation: false,
      interaction: {
        intersect: false
      },
      plugins: {
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            usePointStyle: true
          }
        },
        tooltip: {
          mode: 'index',
          axis: 'xy',
          position: 'nearest',
          usePointStyle: true,
          callbacks: {
            title: (context) => this.formatKey(context[0].parsed.x, { full: true })
          }
        }
      }
    }

    switch (this.displayType) {
    case DISPLAY_TYPE_BAR:
      chartType = 'bar'
      options.datasets = {
        bar: {
          barPercentage: 0.9,
          categoryPercentage: 1
        }
      }
      options.scales = {
        x: {
          border: {
            display: false
          },
          grid: {
            display: false
          },
          ticks: {
            callback: (value, _index, _ticks) => this.formatKey(value)
          }
        },
        y: {
          border: {
            display: false
          },
          ticks: {
            maxTicksLimit: 5
          }
        }
      }

      if (this.live) {
        options.scales.x.display = false
        options.plugins.legend = false
      } else if (this.summary) {
        options.scales.x.display = false
      }

      break
    case DISPLAY_TYPE_LINE:
      chartType = 'line'
      options.scales = {
        x: {
          border: {
            display: false
          },
          grid: {
            display: false
          },
          ticks: {
            align: 'inner',
            maxRotation: 0,
            callback: (value, _index, _ticks) => { return this.formatKey(value) }
          }
        },
        y: {
          beginAtZero: true,
          border: {
            display: false
          },
          ticks: {
            maxTicksLimit: 5
          }
        }
      }

      if (this.live) {
        options.scales.x.display = false
        options.plugins.legend = false
      } else if (this.summary) {
        options.scales.x.display = false
      }

      break
    case DISPLAY_TYPE_PIE:
      chartType = 'doughnut'
      options.datasets = {
        doughnut: {
          borderWidth: 0
        }
      }
      break
    }

    if (this.numeric) {
      options.plugins.legend = false
    }

    this.chart = new Chart(this.canvasElement, { type: chartType, data: {}, options })
  }

  /// Runs when the element is connected to the DOM with a chart.
  ///
  /// This sets up listeners to automatically update the chart's styles as necessary.
  chartConnected() {
    if (window.matchMedia) {
      this.colorSchemeMatcher = window.matchMedia('(prefers-color-scheme: dark)')
      this.colorSchemeListener = this.updateChartColors.bind(this)
      this.colorSchemeMatcher.addEventListener('change', this.colorSchemeListener)
    }

    this.updateChartStyles()
  }

  /// Runs when the element is disconnected from the DOM with a chart.
  ///
  /// This cleans up the chart and any listeners set up when connected.
  chartDisconnected() {
    if (this.colorSchemeMatcher) {
      this.colorSchemeMatcher.removeEventListener('change', this.colorSchemeListener)
      this.colorSchemeMatcher = null
      this.colorSchemeListener = null
    }
  }

  /// Displays a metric in the chart.
  ///
  /// This processes the metric's data and configures the chart to dispay.
  displayChartMetric(metric) {
    const data = {}

    switch (this.displayType) {
    case DISPLAY_TYPE_BAR:
    case DISPLAY_TYPE_LINE:
      data.datasets = metric.datasets.map((dataset) => {
        return {
          label: this.formatDatasetTitle(dataset.title),
          data: dataset.data.reduce((object, datum, i) => {
            object[metric.keys[i]] = datum
            return object
          }, {})
        }
      })
      data.labels = metric.keys

      if (this.metricKeyFormat && KEY_FORMATS_DATETIME.includes(this.metricKeyFormat)) {
        this.chart.options.scales.x.type = 'time'
        this.chart.options.scales.x.time = { unit: this.metricKeyFormat }
      } else {
        delete this.chart.options.scales.x.type
        delete this.chart.options.scales.x.time
      }

      break
    case DISPLAY_TYPE_PIE:
      data.datasets = metric.datasets.map((dataset) => {
        return {
          label: this.formatDatasetTitle(dataset.title),
          data: dataset.data
        }
      })
      data.labels = metric.keys.map((key) => this.formatKey(key, { full: true }))
      break
    }

    this.chart.data = data
    this.updateChartColors()
  }

  /// Updates the chart's styles.
  ///
  /// This retrieves fonts and other styles from the element's styles.
  updateChartStyles() {
    const computedStyle = this.chartContainer.getComputedStyle()

    const aspectRatio = computedStyle.aspectRatio
    if (aspectRatio.match(/^\d+\s*\/\s*\d+/)) {
      const parts = aspectRatio.split(/\s*\/\s*/)
      const numerator = parseFloat(parts[0])
      const denominator = parseFloat(parts[1])

      this.chart.options.aspectRatio = numerator / denominator
    }

    const primaryFont = {
      family: computedStyle.getPropertyValue('--font-family-primary'),
    }

    const headingFont = {
      family: computedStyle.getPropertyValue('--font-family-heading'),
      weight: computedStyle.getPropertyValue('--font-weight-semibold')
    }

    if (this.chart.options.plugins.legend) {
      this.chart.options.plugins.legend.labels.font = headingFont
    }

    this.chart.options.plugins.tooltip.titleFont = headingFont
    this.chart.options.plugins.tooltip.labelFont = primaryFont

    switch (this.displayType) {
    case DISPLAY_TYPE_LINE:
      this.chart.options.scales.x.ticks.font = primaryFont
      this.chart.options.scales.y.ticks.font = primaryFont
      break
    }

    this.updateChartColors()
  }

  /// Updates the chart's colors.
  ///
  /// This retrieves colors from the element's styles, which account for light or dark mode.
  updateChartColors() {
    const computedStyle = this.getComputedStyle()

    let colorNames = computedStyle.getPropertyValue('--chart-color-names')
    colorNames = colorNames.split(/,\s+/)

    const textColor = computedStyle.getPropertyValue('--color-text')
    const borderColor = computedStyle.getPropertyValue('--color-border')
    const bgHoverColor = computedStyle.getPropertyValue('--color-bg-hover')

    this.chart.options.color = textColor

    this.chart.options.plugins.tooltip.backgroundColor = bgHoverColor
    this.chart.options.plugins.tooltip.titleColor = textColor
    this.chart.options.plugins.tooltip.bodyColor = textColor

    switch (this.displayType) {
    case DISPLAY_TYPE_BAR:
    case DISPLAY_TYPE_LINE:
      this.chart.data.datasets.forEach((dataset, index) => {
        const colorIndex = index % colorNames.length
        const colorName = colorNames[colorIndex]

        const color = computedStyle.getPropertyValue(`--color-${colorName}`)

        dataset.borderColor = color
        dataset.backgroundColor = color
      })

      this.chart.options.scales.x.ticks.color = textColor
      this.chart.options.scales.y.ticks.color = textColor
      this.chart.options.scales.x.grid.color = borderColor
      this.chart.options.scales.y.grid.color = borderColor
      break
    case DISPLAY_TYPE_PIE: {
      const colors = []
      const hoverColors = []
      if (this.metricKeys) {
        this.metricKeys.forEach((_key, index) => {
          const colorIndex = index % colorNames.length
          const colorName = colorNames[colorIndex]

          colors.push(computedStyle.getPropertyValue(`--color-${colorName}`))
          hoverColors.push(computedStyle.getPropertyValue(`--color-${colorName}-hover`))
        })

        this.chart.data.datasets.forEach((dataset) => {
          dataset.backgroundColor = colors
          dataset.hoverBackgroundColor = hoverColors
        })
      }

      break
    }
    }

    this.chart.update()
  }

  ////////// Numeric

  /// Creates and configures the numeric element.
  setupNumeric() {
    this.numericElement = document.createElement('div')
    this.numericElement.classList.add('onspace-metric__numeric')
    this.appendChild(this.numericElement)
  }

  /// Displays a metric in the numeric element.
  displayNumericMetric(metric) {
    this.numericElement.innerHTML = ''

    const computedStyle = this.getComputedStyle()
    let colorNames = computedStyle.getPropertyValue('--chart-color-names')
    colorNames = colorNames.split(/,\s+/)

    switch (this.numeric) {
    case NUMERIC_DIMENSION_DATASETS:
      metric.datasets.forEach((dataset, datasetIndex) => {
        const itemElement = document.createElement('div')
        itemElement.classList.add('onspace-metric__numeric__item')
        itemElement.classList.add(`onspace-metric__numeric__item--${colorNames[datasetIndex]}`)
        this.numericElement.appendChild(itemElement)

        const titleElement = document.createElement('div')
        titleElement.classList.add('onspace-metric__numeric__item__title')
        titleElement.innerText = this.formatDatasetTitle(dataset.title, { html: true })
        itemElement.appendChild(titleElement)

        const valueElement = document.createElement('div')
        valueElement.classList.add('onspace-metric__numeric__item__value')
        valueElement.innerText = dataset.total
        itemElement.appendChild(valueElement)
      })
      break
    case NUMERIC_DIMENSION_KEYS:
      metric.keys.forEach((key, keyIndex) => {
        const itemElement = document.createElement('div')
        itemElement.classList.add('onspace-metric__numeric__item')
        itemElement.classList.add(`onspace-metric__numeric__item--${colorNames[keyIndex]}`)
        this.numericElement.appendChild(itemElement)

        const titleElement = document.createElement('div')
        titleElement.classList.add('onspace-metric__numeric__item__title')
        titleElement.innerHTML = this.formatKey(key, { full: true, html: true })
        itemElement.appendChild(titleElement)

        metric.datasets.forEach((dataset) => {
          const valueElement = document.createElement('div')
          valueElement.classList.add('onspace-metric__numeric__item__value')
          valueElement.innerHTML = dataset.data[keyIndex]
          itemElement.appendChild(valueElement)
        })
      })
      break
    }
  }

  ////////// Map

  /// Creates and configures the map element.
  setupMap() {
    this.map = WorldMap()
    this.map.classList.add('onspace-metric__map')

    this.appendChild(this.map)
  }

  /// Displays a metric in the map element.
  displayMapMetric(metric) {
    const computedStyle = this.getComputedStyle()
    let colorNames = computedStyle.getPropertyValue('--chart-color-names')
    colorNames = colorNames.split(/,\s+/)
    const colorValue = `var(--color-${colorNames[0]})`

    const max = Math.max(...metric.datasets[0].data)
    metric.raw_keys.forEach((key, keyIndex) => {
      const value = metric.datasets[0].data[keyIndex]
      const percentage = value / max * 100
      const keyElement = this.map.querySelector(`#${key}`)
      keyElement.setAttribute('fill', `color-mix(in srgb, ${colorValue} ${percentage}%, currentcolor)`)
    })
  }

  ////////// Table

  /// Creates and configures the table element.
  setupTable() {
    const table = document.createElement('table')

    const thead = document.createElement('thead')
    table.appendChild(thead)

    this.tableHeadRow = document.createElement('tr')
    thead.appendChild(this.tableHeadRow)

    this.tableTotalsRow = document.createElement('tr')
    thead.appendChild(this.tableTotalsRow)

    this.tableBody = document.createElement('tbody')
    table.appendChild(this.tableBody)

    this.appendChild(table)
  }

  /// Displays a metric in the table.
  displayTableMetric(metric) {
    this.tableHeadRow.innerHTML = ''
    this.tableTotalsRow.innerHTML = ''
    this.tableBody.innerHTML = ''

    // Head

    const keyCell = document.createElement('th')
    keyCell.innerText = metric.key_title
    this.tableHeadRow.appendChild(keyCell)

    metric.datasets.forEach((dataset) => {
      const cell = document.createElement('th')
      cell.innerText = this.formatDatasetTitle(dataset.title, { html: true })
      this.tableHeadRow.appendChild(cell)
    })

    // Totals

    const keyTotalCell = document.createElement('td')
    keyTotalCell.innerText = translation('onspace.analytics.metric.key.total')
    this.tableTotalsRow.appendChild(keyTotalCell)

    metric.datasets.forEach((dataset) => {
      const cell = document.createElement('td')
      cell.innerText = dataset.total
      this.tableTotalsRow.appendChild(cell)
    })

    // Rows

    metric.keys.forEach((key, keyIndex) => {
      const row = document.createElement('tr')

      const rowKeyCell = document.createElement('th')

      if (this.keyLocation) {
        const rawKey = metric.raw_keys[keyIndex]

        const rowKeyLink = document.createElement('a')
        rowKeyLink.href = this.keyLocation.replace('%24value', rawKey)
        rowKeyLink.innerHTML = this.formatKey(key, { full: true, html: true })
        rowKeyCell.appendChild(rowKeyLink)
      } else {
        rowKeyCell.innerHTML = this.formatKey(key, { full: true, html: true })
      }
      row.appendChild(rowKeyCell)

      metric.datasets.forEach((dataset) => {
        const cell = document.createElement('td')
        cell.innerText = dataset.data[keyIndex]
        row.appendChild(cell)
      })

      this.tableBody.appendChild(row)
    })
  }

  ////////// Controls

  /// Creates the controls element
  setupControls() {
    this.controlsElement = document.createElement('div')
    this.controlsElement.classList.add('onspace-metric__controls')
    this.appendChild(this.controlsElement)

    if (!this.summary && !this.live) {
      this.setupExport()
    }

    const spacer = document.createElement('div')
    spacer.classList.add('onspace-metric__controls__spacer')
    this.controlsElement.appendChild(spacer)
  }

  ///// Export

  /// Sets up the element to handle exports.
  setupExport() {
    const anchorElement = document.createElement('a')
    anchorElement.classList.add('onspace-button')
    anchorElement.classList.add('onspace-button--outline')

    const anchorTextElement = document.createElement('span')
    anchorTextElement.innerText = translation('onspace.analytics.metric.export.title')
    anchorElement.appendChild(anchorTextElement)
    anchorElement.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_chevron_down'))

    const menuElement = document.createElement('div')
    menuElement.classList.add('onspace-dropdown__list')

    let dropDown // eslint-disable-line prefer-const

    const formats = translation('onspace.analytics.metric.export.format')
    Object.keys(formats).forEach((format) => {
      const itemElement = document.createElement('a')
      itemElement.classList.add('onspace-dropdown__list__item')
      itemElement.innerText = formats[format]
      itemElement.addEventListener('click', (_event) => {
        this.exportMetric(format)
        dropDown.hideMenu()
      })

      menuElement.appendChild(itemElement)
    })

    dropDown = new DropDown({ anchor: anchorElement, menu: menuElement })
    this.controlsElement.appendChild(dropDown)
  }

  ///// Pagination

  /// Creates a pagination element and appends it to the metric.
  displayPagination(pagination) {
    this.removePagination()
    if (!pagination || this.summary || this.live) { return }

    this.paginationPage = pagination.page
    this.paginationLimit = pagination.limit

    // Limit

    this.paginationLimitElement = document.createElement('div')
    this.paginationLimitElement.classList.add('onspace-button-group')
    this.controlsElement.appendChild(this.paginationLimitElement)

    const limitElement = document.createElement('a')
    limitElement.classList.add('onspace-button')
    limitElement.classList.add('onspace-button--outline')
    limitElement.classList.add('onspace-metric__controls__button--text')
    limitElement.setAttribute('disabled', '')
    limitElement.innerText = translation('onspace.analytics.metric.pagination.limit')
    this.paginationLimitElement.appendChild(limitElement)

    const paginationLimitAnchor = document.createElement('a')
    paginationLimitAnchor.classList.add('onspace-button')
    paginationLimitAnchor.classList.add('onspace-button--outline')

    const paginationLimitText = document.createElement('span')
    paginationLimitText.innerText = pagination.limit
    paginationLimitAnchor.appendChild(paginationLimitText)
    paginationLimitAnchor.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_chevron_down'))

    const paginationLimitMenu = document.createElement('div')
    paginationLimitMenu.classList.add('onspace-dropdown__list')

    PAGINATION_LIMITS.forEach((limit) => {
      const itemElement = document.createElement('a')
      itemElement.classList.add('onspace-dropdown__list__item')

      if (limit === pagination.limit) {
        itemElement.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_checkmark'))
      } else {
        itemElement.appendChild(SVGElement.createOnspaceSpritemapSvg(null))
      }

      const itemTextElement = document.createElement('span')
      itemTextElement.innerText = limit
      itemElement.appendChild(itemTextElement)

      itemElement.addEventListener('click', (_event) => {
        this.paginationLimit = limit
        this.paginationPage = 1
        this.loadRemoteMetric()
      })
      paginationLimitMenu.appendChild(itemElement)
    })

    const paginationLimitDropDown = new DropDown({ anchor: paginationLimitAnchor, menu: paginationLimitMenu })
    this.paginationLimitElement.appendChild(paginationLimitDropDown)

    // Pagination

    this.paginationElement = document.createElement('div')
    this.paginationElement.classList.add('onspace-button-group')
    this.controlsElement.appendChild(this.paginationElement)

    const previousButton = document.createElement('a')
    previousButton.classList.add('onspace-button')
    previousButton.classList.add('onspace-button--outline')
    previousButton.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_chevron_left'))
    if (pagination.page === 1) {
      previousButton.setAttribute('disabled', '')
    } else {
      previousButton.addEventListener('click', this.paginationPreviousPressed.bind(this))
    }
    this.paginationElement.appendChild(previousButton)

    const statusElement = document.createElement('a')
    statusElement.classList.add('onspace-button')
    statusElement.classList.add('onspace-button--outline')
    statusElement.classList.add('onspace-metric__controls__button--text')
    statusElement.setAttribute('disabled', '')
    statusElement.innerText = `${pagination.page_start}-${pagination.page_end} ${translation('onspace.analytics.metric.pagination.of')} ${pagination.total}`
    this.paginationElement.appendChild(statusElement)

    const nextButton = document.createElement('a')
    nextButton.classList.add('onspace-button')
    nextButton.classList.add('onspace-button--outline')
    nextButton.appendChild(SVGElement.createOnspaceSpritemapSvg('onspace/icon_chevron_right'))
    if (pagination.page === pagination.pages) {
      nextButton.setAttribute('disabled', '')
    } else {
      nextButton.addEventListener('click', this.paginationNextPressed.bind(this))
    }
    this.paginationElement.appendChild(nextButton)
  }

  /// Removes pagination elements and attributes from the metric.
  removePagination() {
    if (this.paginationElement) {
      this.paginationElement.remove()
      this.paginationElement = null
    }
    if (this.paginationLimitElement) {
      this.paginationLimitElement.remove()
      this.paginationLimitElement = null
    }

    this.paginationPage = null
    this.paginationLimit = null
  }

  /// Updates the pagination page to the previous number, and performs a reload.
  paginationPreviousPressed(_event) {
    this.paginationPage -= 1
    this.loadRemoteMetric()
  }

  /// Updates the pagination page to the next number, and performs a reload.
  paginationNextPressed(_event) {
    this.paginationPage += 1
    this.loadRemoteMetric()
  }

  ////////// Overlays

  /// Displays an element which covers the metric.
  ///
  /// This should be used to display information that renders the metric unusable at the time. The following options are
  /// accepted:
  /// [icon]
  ///   An icon to be shown in the overlay.
  /// [message]
  ///   Text to display within the overlay.
  /// [action]
  ///   A callback which will be run when a button is pressed. The button will not be shown if this is not present.
  /// [actionTitle]
  ///   Text to display on the action button. The button will not be shown if this is not present.
  showOverlay({ icon, message, actionTitle, action } = {}) {
    this.removeOverlay()

    this.overlayElement = document.createElement('div')
    this.overlayElement.classList.add('onspace-metric__overlay')

    if (icon) {
      const iconElement = SVGElement.createOnspaceSpritemapSvg(icon)
      this.appendChild(iconElement)
      this.overlayElement.appendChild(iconElement)
    }

    if (message) {
      const text = document.createElement('div')
      text.innerText = message
      this.overlayElement.appendChild(text)
    }

    if (action && actionTitle) {
      const actionButton = document.createElement('a')
      actionButton.classList.add('onspace-button')
      actionButton.innerText = actionTitle
      actionButton.addEventListener('click', action)
      this.overlayElement.appendChild(actionButton)
    }

    this.appendChild(this.overlayElement)
  }

  /// Displays an error message as an overlay.
  ///
  /// This calls +showOverlay+ with a warning icon and retry button, using the given +message+ argument. All options can
  /// be overridden using the +options+ argument.
  showError(message, options = {}) {
    options.message = message

    if (!options.icon) { options.icon = 'onspace/icon_warning' }
    if (!options.action) {
      options.actionTitle = translation('onspace.analytics.metric.error.retry')
      options.action = this.loadRemoteMetric.bind(this)
    }

    this.showOverlay(options)
  }

  /// Removes an active overlay, if present.
  removeOverlay() {
    if (!this.overlayElement) { return }

    this.overlayElement.remove()
    this.overlayElement = null
  }
}

window.customElements.define('onspace-metric', OnspaceMetric)

////////// Reloader

/// An element which reloads other +onspace-metric+ elements.
///
/// This controls metric child elements, by automatically reloading them at regular intervals. This will trigger a
/// reload when the current time's seconds reaches 0, which is when new data becomes available from the metrics API.
class OnspaceMetricReloader extends CustomHTMLElement {
  /// Run when the element is connected to the DOM.
  runConnected() {
    super.runConnected()

    this.waitForNextMinute()
  }

  /// Run when the element is disconnected from the DOM.
  runDisconnected() {
    super.runDisconnected()

    this.stopWaiting()
  }

  /// Sets up a timeout to trigger the next reload.
  waitForNextMinute() {
    const date = new Date()
    date.setSeconds(60)
    this.waitTimeout = window.setTimeout(this.reloadTriggered.bind(this), date - Date.now())
  }

  /// Stops waiting for the next reload trigger.
  stopWaiting() {
    if (this.waitTimeout) {
      window.clearTimeout(this.waitTimeout)
      this.waitTimeout = null
    }
  }

  /// Responds to a reload timeout finishing.
  ///
  /// This notifies all metrics to reload their data.
  reloadTriggered() {
    const metrics = this.querySelectorAll('onspace-metric[live]')
    metrics.forEach((metric) => {
      metric.loadRemoteMetric({ reload: true })
    })

    this.waitForNextMinute()
  }
}

window.customElements.define('onspace-metric-reloader', OnspaceMetricReloader)
