import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// An element that can copy text to a user's clipboard.
///
/// This requires the following setup:
/// - A child span with the text that is to be copied.
/// - A child SVG that will update when the text is copied.
///
/// When the clipboard-copy element is clicked, the text will be copied to the user's clipboard, and the image
/// will update to the checkmark icon.
export default class ClipboardCopy extends CustomHTMLElement {
  /// Sets up the clipboard-button element.
  ///
  /// Sets the listener on the clipboard-copy
  runConstructor() {
    super.runConstructor()

    this.svgElement = this.querySelector('svg')
    this.addEventListener('click', this.addClickEvent.bind(this))
  }

  /// Support for older browsers.
  ///
  /// While support for the Clipboard API is pretty high across the board this fallback function will handle the
  /// copy event for older browsers.
  fallbackCopyToClipboard(str) {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  /// Copy to Clipboard.
  ///
  /// Utilising the Clipboard API - https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API - this function
  /// will copy a string to a user's clipboard. If the Clipboard API is not supported, it will call the fallback function.
  copyToClipboard(str) {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(str)
    }

    this.fallbackCopyToClipboard(str)
  }

  /// Add button click event.
  ///
  /// Find the URL we wish to copy by querying this element for a 'span' tag. When found, copy it's text to the
  /// user's clipboard, and update the icon from the copy icon to the checkmark icon.
  addClickEvent(_event) {
    const textToCopy = this.querySelector('span')

    if (textToCopy) {
      this.copyToClipboard(textToCopy.innerText)
      this.svgElement.setOnspaceSpritemapSvg('onspace/icon_checkmark')

      setTimeout(() => this.svgElement.setOnspaceSpritemapSvg('onspace/icon_copy'), 2000)
    }
  }
}

window.customElements.define('clipboard-copy', ClipboardCopy)
