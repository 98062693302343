import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// An element which wraps a HTML date input.
///
/// This requires the following children:
/// - A +formatted-time+ element.
/// - An +input[type=date]+ element.
///
/// With the help of some creative CSS, this allows the user to click on the custom button and show a date picker, while
/// hiding the underlying input. The input is placed above the content, but made transparent, so it still receives the
/// clicks. this listens for changes in the element and updates the info.
export default class DateCustom extends CustomHTMLElement {
  /// Sets up the custom date element.
  ///
  /// Locates the required children and adds events where necessary.
  runConstructor() {
    super.runConstructor()

    this.timeElement = this.querySelector('formatted-time')
    this.dateInput = this.querySelector('input[type=date],input[type=month]')

    if (!window.onspaceHasTouch()) {
      this.dateInput.addEventListener('click', this.inputClicked.bind(this))
      this.dateInput.addEventListener('focus', this.inputFocused.bind(this))
      this.dateInput.addEventListener('blur', this.inputBlurred.bind(this))
    }

    this.dateInput.addEventListener('change', (_event) => this.updateCurrentDate())
    setTimeout(() => this.updateCurrentDate())
  }

  /// Enables the scrollretain release listener.
  runConnected() {
    this.addDocumentBoundEventListener('onspace:scrollretain:released', (_event) => this.dateInput.blur())
  }

  /// Removes the scrollretain release listener.
  runDisconnected() {
    this.removeDocumentBoundEventListener('onspace:scrollretain:released')
  }

  /// Updates the displayed info from the chosen date.
  updateCurrentDate() {
    const value = this.dateInput.value

    if (value === '') {
      this.timeElement.date = null
    } else {
      this.timeElement.date = value
    }
  }

  //////////

  /// Responds to clicks on the input.
  ///
  /// If the input is already active, this makes it inactive.
  inputClicked() {
    if (this.retainedScrolling) {
      this.dateInput.blur()
    }
  }

  /// Responds to the input becoming the focus.
  ///
  /// This retains scrolling so other elements cannot be used.
  inputFocused() {
    setTimeout(() => this.onspaceRetainScrolling(), 200)
  }

  /// Responds to the input losing focus.
  ///
  /// This releases scrolling so other elements can be used.
  inputBlurred() {
    setTimeout(() => this.onspaceReleaseScrolling(), 200)
  }
}

window.customElements.define('date-custom', DateCustom)
