import OnspaceMediaPlayer from '@onpace/onspace-media/elements/player'

import { registerDialogPlayerClass } from '@onpace/onspace-media/elements/player/dialog'

import OnspaceAudioPlayerControls from '@onpace/onspace-media/elements/player/audio/controls'
import OnspaceAudioPlayerDebug from '@onpace/onspace-media/elements/player/audio/debug'

/// The Onspace Audio Player is a concrete subclass of OnspaceMediaPlayer capable of playing audio content.
export default class OnspaceAudioPlayer extends OnspaceMediaPlayer {
  /// Runs when the media player is first connected to the DOM.
  runFirstConnected() {
    super.runFirstConnected()

    this.classList.add('onspace-player--audio')
  }

  ////////// Native Player

  /// Creates the native player DOM element.
  ///
  /// This overrides OnspaceMediaPlayer.createNativePlayerElement to create an +audio+ element.
  createNativePlayerElement() {
    return document.createElement('audio')
  }

  ////////// Audio

  /// Indicates if the player requires the audio to be played.
  ///
  /// This overrides OnspaceMediaPlayer.requiresAudio to return true.
  get requiresAudio() {
    return true
  }

  ////////// Controls

  /// Creates a controls element.
  createControlsElement() {
    return new OnspaceAudioPlayerControls({ player: this })
  }

  ////////// Analytics

  /// Configures analytics parameters.
  ///
  /// This overrides OnspaceMediaPlayer.configureAnalytics to additionally set the +viewer_type+.
  configureAnalytics() {
    super.configureAnalytics()

    this.analyticsParams.content_type = 'audio'
  }

  ////////// Debugging

  /// Creates a debug element.
  createDebugElement() {
    return OnspaceAudioPlayerDebug({ player: this })
  }
}

window.customElements.define('onspace-audio', OnspaceAudioPlayer)
registerDialogPlayerClass('audio', OnspaceAudioPlayer)
