import OnspaceMediaPlayer from '../player'

import translation from '@onpace/onspace-core/components/translations'
import { registerDialogPlayerClass } from '@onpace/onspace-media/elements/player/dialog'

import OnspaceVideoPlayerControls from '@onpace/onspace-media/elements/player/video/controls'
import OnspaceVideoPlayerDebug from '@onpace/onspace-media/elements/player/video/debug'

const PRESENTATION_MODE_INLINE = 'inline'
const PRESENTATION_MODE_MULTISCREEN = 'multiscreen'
const PRESENTATION_MODE_PICTURE_IN_PICTURE = 'pictureinpicture'
const PRESENTATION_MODE_FULL_SCREEN = 'fullscreen'
const PRESENTATION_MODE_REMOTE = 'remote'

const isTouchScreen = window.matchMedia('(pointer: coarse)').matches

/// The Onspace Video Player is a concrete subclass of OnspaceMediaPlayer capable of playing video content.
export default class OnspaceVideoPlayer extends OnspaceMediaPlayer {
  /// Runs when the video player is first connected to the DOM.
  runFirstConnected(options = {}) {
    super.runFirstConnected(options)

    this.classList.add('onspace-player--video')

    this.addEventListener('fullscreenchange', this.fullscreenChanged.bind(this))

    if (!this.autoplay) {
      this.setupPlaceholder()
    }
  }

  ////////// Events

  ///// Interaction

  /// Responds to presses on the element.
  ///
  /// If the player is not active, this will activate it. This otherwise behaves differently depending on the pointer
  /// type:
  /// - For mice, this will toggle playback.
  /// - For touch, this will toggle the visibility of the element.
  pressed(event) {
    super.pressed(event)

    if (!this.playbackStarted) {
      this.startPlayer()
    } else if (!this.activated) {
      this.activate()
    } else if (this.showingUpNext) {
      this.removeUpNext()
    } else if (this.controlsElement.tabsElement.expanded) {
      this.controlsElement.tabsElement.selectedTabIndex = null
    } else if (isTouchScreen) {
      this.controlsElement.toggleVisibility()
    } else {
      this.togglePlayback()
    }
  }

  ///// Keyboard

  /// Responds to keyboard presses beginning on the document.
  ///
  /// This overrides OnspaceMediaPlayer.keyPressBegan to respond to additional key commands specific to video content.
  keyPressBegan(event) {
    if (this.playingAdvertisement) { return }

    switch (event.key) {
    case 'f':
      this.toggleFullscreen()
      break
    case 'p':
      this.togglePictureInPicture()
      break
    default:
      return super.keyPressBegan(event)
    }

    event.preventDefault()
    event.stopPropagation()
    return false
  }

  /// Responds to full screen changes on the element.
  fullscreenChanged(_event) {
    this.detectPresentationMode()
  }

  ////////// Actions

  /// Initialises and starts the media player.
  ///
  /// This overrides OnspaceMediaPlayer.startPlayer to also destroy the placeholder element if required.
  async startPlayer(options) {
    this.destroyPlaceholder()

    await super.startPlayer(options)

    this.detectPresentationMode()
  }

  /// Initialises and resumes the media player using an existing playback session.
  ///
  /// This overrides OnspaceMediaPlayer.continuePlayer to also destroy the placeholder element if required.
  continuePlayer() {
    this.destroyPlaceholder()

    super.continuePlayer()

    this.detectPresentationMode()
  }

  /// Stops and destroys the media player components.
  ///
  /// This overrides OnspaceMediaPlayer.destroyPlayer to also exit Picture-In-Picture if necessary.
  destroyPlayer() {
    this.exitPictureInPicture()

    super.destroyPlayer()
  }

  ////////// Native Player

  /// Initialises and configures the native player element.
  ///
  /// This overrides OnspaceMediaPlayer.setupNativePlayer to add additional configuration specific to video content.
  setupNativePlayer() {
    if (this.nativePlayer) { return }

    super.setupNativePlayer()

    this.nativePlayer.addEventListener('enterpictureinpicture', this.nativePlayerEnteredPictureInPicture.bind(this))
    this.nativePlayer.addEventListener('leavepictureinpicture', this.nativePlayerLeftPictureInPicture.bind(this))
  }

  /// Creates the native player DOM element.
  ///
  /// This overrides OnspaceMediaPlayer.createNativePlayerElement to create a +video+ element.
  createNativePlayerElement() {
    const element = document.createElement('video')
    element.setAttribute('playsinline', '')

    return element
  }

  /// Responds to the native player loading metadata.
  ///
  /// This overrides OnspaceMediaPlayer.nativePlayerLoadedMetadata to trigger events specific to video media.
  nativePlayerLoadedMetadata(event) {
    super.nativePlayerLoadedMetadata(event)

    this.triggerEvent('onspace:media:player:video-resolution-changed')
  }

  /// Responds to the native player updating its airplay target device.
  ///
  /// This overrides OnspaceMediaPlayer.nativePlayerAirplayTargetChanged to update the current presentation mode.
  nativePlayerAirplayTargetChanged(event) {
    super.nativePlayerAirplayTargetChanged(event)

    this.detectPresentationMode()
  }

  /// Responds to the native player entering picture in picture mode.
  nativePlayerEnteredPictureInPicture(_event) {
    this.detectPresentationMode()
  }

  /// Responds to the native player leaving picture in picture mode.
  nativePlayerLeftPictureInPicture(_event) {
    this.detectPresentationMode()
  }

  ////////// HLS

  /// Responds to the currently playing hls level updating.
  ///
  /// This overrides OnspaceMediaPlayer.nativePlayerLoadedMetadata to trigger events specific to video media.
  hlsLevelSwitched(event, data) {
    super.hlsLevelSwitched(event, data)

    this.triggerEvent('onspace:media:player:video-resolution-changed')
  }

  ////////// Google Cast

  /// Responds to a change in the Google Cast connection state.
  ///
  /// This overrides OnspaceMediaPlayer.googleCastConnectedChanged to update the current presentation mode.
  googleCastConnectedChanged(event) {
    super.googleCastConnectedChanged(event)

    this.detectPresentationMode()
  }

  /// Responds to a change in the Google Cast player's loaded media.
  ///
  /// This overrides OnspaceMediaPlayer.googleCastMediaLoadedChanged to update the current presentation mode.
  googleCastMediaLoadedChanged(event) {
    super.googleCastMediaLoadedChanged(event)

    this.detectPresentationMode()
  }

  ////////// Placeholder

  ///// Element

  /// Initialises and configures the placeholder element.
  setupPlaceholder() {
    if (this.placeholderElement) { return }

    this.placeholderElement = this.createPlaceholderElement()
    this.contentElement.prepend(this.placeholderElement)
  }

  /// Creates a placeholder element.
  createPlaceholderElement() {
    const element = document.createElement('div')
    element.classList.add('onspace-player__placeholder')

    if (this.metadata.artwork) {
      element.style.backgroundImage = `url(${this.metadata.artwork})`
    }

    const iconElement = SVGElement.createOnspaceSpritemapSvg('onspace/player_play')
    element.appendChild(iconElement)

    return element
  }

  /// Removes the placeholder from the DOM and clears the reference.
  destroyPlaceholder() {
    if (this.placeholderElement) {
      this.placeholderElement.remove()
      this.placeholderElement = null
    }
  }

  ////////// Controls

  /// Creates a controls element.
  createControlsElement() {
    return new OnspaceVideoPlayerControls({ player: this })
  }

  ////////// Presentation

  /// Determines the current presentation mode, updating the state if required.
  detectPresentationMode() {
    let presentationMode
    if (this.multiScreenActive) {
      presentationMode = PRESENTATION_MODE_MULTISCREEN
    } else if (this.fullscreenActive) {
      presentationMode = PRESENTATION_MODE_FULL_SCREEN
    } else if (this.pictureInPictureActive) {
      presentationMode = PRESENTATION_MODE_PICTURE_IN_PICTURE
    } else if (this.remotePlaybackActive) {
      presentationMode = PRESENTATION_MODE_REMOTE
    } else {
      presentationMode = PRESENTATION_MODE_INLINE
    }

    if (presentationMode !== this.presentationMode) {
      const previousPresentationMode = this.presentationMode
      this.presentationMode = presentationMode

      this.classList.remove(`onspace-player--presentation-${previousPresentationMode}`)
      this.classList.add(`onspace-player--presentation-${presentationMode}`)

      if (presentationMode === PRESENTATION_MODE_PICTURE_IN_PICTURE) {
        this.showOverlayMessage(translation('onspace.media.player.playback.video_picture_in_picture'), { icon: 'onspace/player_pip' })
        this.beginPersisting()
      } else if (previousPresentationMode === PRESENTATION_MODE_PICTURE_IN_PICTURE) {
        this.clearMessages()
        this.endPersisting(true)
      }
    }

    this.triggerEvent('onspace:media:player:remote-changed')
    this.triggerEvent('onspace:media:player:video-presentation-changed')
  }

  ///// Multi Screen

  /// Indicates if the video supports multi screen playback.
  get supportsMultiScreen() {
    return !!this.multiScreen
  }

  /// Indicates if multiple screens are active.
  get multiScreenActive() {
    return this.multiScreen && this.multiScreen.hasMultiplePlayers
  }

  /// Indicates if the player can control multi screen.
  get canShowMultiScreenControls() {
    return this.multiScreen && this.multiScreen.playerCanShowControls
  }

  /// Indicates if the player can control the multi screen layout.
  get canControlMultiScreenLayout() {
    return this.multiScreen && this.multiScreen.playerCanControlLayout
  }

  /// Toggles the multi screen elements controls.
  toggleMultiScreenControls() {
    this.multiScreen.toggleControls()
  }

  /// Toggles between the multi screen layouts.
  toggleMultiScreenLayout() {
    this.multiScreen.togglePlayersLayout()
  }

  ///// Picture-in-Picture

  /// Indicates if the video supports picture in picture.
  get supportsPictureInPicture() {
    return document.pictureInPictureEnabled
  }

  /// Indicates if the player can currently enter picture in picture.
  get canEnterPictureInPicture() {
    return this.supportsPictureInPicture && (!this.multiScreen || this.multiScreen.playerCanEnterPictureInPicture) && this.presentationMode === PRESENTATION_MODE_INLINE
  }

  /// Indicates if the player is currently in picture in picture mode.
  get pictureInPictureActive() {
    return !!this.nativePlayer && document.pictureInPictureElement === this.nativePlayer
  }

  /// Enters or exits picture in picture mode, depending on the current state.
  togglePictureInPicture() {
    if (this.pictureInPictureActive) {
      this.exitPictureInPicture()
    } else {
      this.enterPictureInPicture()
    }
  }

  /// Enters the player into picture in picture mode.
  ///
  /// This will do nothing unless the player +canEnterPictureInPicture+.
  enterPictureInPicture() {
    if (!this.canEnterPictureInPicture) { return }

    this.nativePlayer.requestPictureInPicture()
  }

  /// Exists the player from picture in picture mode.
  exitPictureInPicture() {
    if (!this.pictureInPictureActive) { return }

    document.exitPictureInPicture()
  }

  ///// Full Screen

  /// Indicates if the browser supports taking any element full screen.
  get supportsDocumentFullscreen() {
    return document.fullscreenEnabled
  }

  /// Indicates if the video supports entering full screen mode.
  get supportsVideoFullscreen() {
    return true
  }

  /// Indicates if the player can currently enter full screen mode.
  get canEnterFullscreen() {
    if (this.multiScreen) {
      return this.multiScreen.playerCanEnterFullscreen
    } else {
      return (this.supportsDocumentFullscreen || this.supportsVideoFullscreen) && this.presentationMode === PRESENTATION_MODE_INLINE
    }
  }

  /// Indicates if the player is currently in full screen mode.
  get fullscreenActive() {
    if (this.multiScreen) {
      return this.multiScreen.fullscreenActive
    } else {
      return !!document.fullscreenElement
    }
  }

  /// Enters or exits full screen mode, depending on the current state.
  toggleFullscreen() {
    if (this.multiScreen) {
      this.multiScreen.toggleFullscreen()
    } else if (this.fullscreenActive) {
      this.exitFullscreen()
    } else {
      this.enterFullscreen()
    }
  }

  /// Enters the player into full screen mode.
  ///
  /// This will attempt to enter full screen mode using the Document Full Screen API, otherwise the HTMLVideoElement
  /// API. This will do nothing unless the player +canEnterFullScreen+.
  enterFullscreen() {
    if (this.multiScreen) { return this.multiScreen.enterFullscreen() }
    if (!this.canEnterFullscreen) { return }

    if (this.supportsDocumentFullscreen) {
      this.requestFullscreen()
    } else {
      this.nativePlayer.requestFullscreen()
    }
  }

  /// Exits the player from full screen mode.
  exitFullscreen() {
    if (this.multiScreen) { return this.multiScreen.exitFullscreen() }
    if (!this.fullscreenActive) { return }

    document.exitFullscreen()
  }

  ////////// Analytics

  /// Configures analytics parameters.
  ///
  /// This overrides OnspaceMediaPlayer.configureAnalytics to additionally set the +viewer_type+.
  configureAnalytics() {
    super.configureAnalytics()

    this.analyticsParams.content_type = 'video'
  }

  ////////// Debugging

  /// Enables debugging mode.
  enableDebugMode() {
    super.enableDebugMode()

    this.controlsElement.becomeVisible()
  }

  /// Creates a debug element.
  createDebugElement() {
    const element = new OnspaceVideoPlayerDebug({ player: this })
    return element
  }
}

window.customElements.define('onspace-video', OnspaceVideoPlayer)
registerDialogPlayerClass('video', OnspaceVideoPlayer)
