import CustomHTMLElement from '@onpace/onspace-core/components/html_element'
import { OnspaceDialogSelect } from '@onpace/onspace-core/elements/dialog'

////////// Control Elements

/// A form control for selecting associations.
///
/// This presents a CMS controller's +select+ action inside a dialog, and captures it's selection changes. It maintains
/// a set of hidden fields which are submitted along with the form.
///
/// You must set the +name+ attribute on this class to the parameter name in the form, and the following children at
/// root level:
/// - A blank 'hidden' input, which is used in the case where there are no selections.
/// - A select button, which is used to present the dialog.
///
/// This supports array values on it's own, simply set the +multiple+ attribute.
export class InputAssociation extends CustomHTMLElement {
  /// Sets up the input association element.
  ///
  /// Locates the required children and adds events where necessary.
  runConstructor() {
    super.runConstructor()

    this.name = this.getAttribute('name')
    this.multiple = this.hasAttribute('multiple')
    this.href = this.getAttribute('href')

    this.selectElement = this.querySelector('.input-association__select')
    this.selectionsContainer = this.querySelector('.input-association__selections')
    this.selectElement.addEventListener('click', this.selectElementClicked.bind(this))
    this.selectElement.addEventListener('keypress', this.selectElementKeyPressed.bind(this))

    this.createButtons = this.querySelectorAll('[data-create]')
    this.createButtons.forEach((button) => {
      button.createUrl = button.getAttribute('data-create')
      button.addEventListener('click', this.createButtonClicked.bind(this))
      button.addEventListener('keypress', this.createButtonKeyPressed.bind(this))
    })
  }

  ////////// Select

  /// Responds to click events on the select element.
  ///
  /// This presents the selection dialog.
  selectElementClicked(_event) {
    this.presentSelectionDialog()
  }

  /// Responds to key presses on the focused select element.
  ///
  /// This presents the selection dialog for the "space" key.
  selectElementKeyPressed(event) {
    if (event.key == ' ') {
      this.presentSelectionDialog()
      event.preventDefault()
      event.stopPropagation()
    }
  }

  ////////// Create

  /// Responds to click events on a create button.
  ///
  /// This presents the creation dialog.
  createButtonClicked(event) {
    let element = event.target
    if (!element.hasAttribute('[data-create]')) {
      element = element.closest('[data-create]')
    }

    const dropdown = element.closest('drop-down')
    if (dropdown) { dropdown.hideMenu() }

    this.presentSelectionDialog(element.createUrl)
  }

  /// Responds to key presses on a focused create button.
  ///
  /// This presents the creation dialog for the "space" key.
  createButtonKeyPressed(event) {
    if (event.key == ' ') {
      const dropdown = event.target.closest('drop-down')
      if (dropdown) { dropdown.hideMenu() }

      this.presentSelectionDialog(event.target.createUrl)
      event.preventDefault()
      event.stopPropagation()
    }
  }

  ////////// Dialog

  /// Creates a new selection dialog instance and presents it.
  ///
  /// If a dialog is already exists, this does nothing.
  presentSelectionDialog(url) {
    if (this.selectionDialog) { return }

    this.selectionDialog = new OnspaceDialogSelect({ selectedValues: this.selectedValues })
    this.selectionDialog.addEventListener('onspace:dialog:close', this.dialogClosed.bind(this))
    this.selectionDialog.addEventListener('onspace:dialog:selected', this.dialogValueSelected.bind(this))
    this.selectionDialog.addEventListener('onspace:dialog:deselected', this.dialogValueDeselected.bind(this))
    this.selectionDialog.loadUrl(url || this.href)

    document.body.appendChild(this.selectionDialog)
  }

  /// Closes the current selection dialog.
  ///
  /// If no dialog exists, this does nothing.
  closeSelectionDialog() {
    if (!this.selectionDialog) { return }

    this.selectionDialog.close()
  }

  /// Responds to close events on the selection dialog.
  dialogClosed(_event) {
    this.selectionDialog = null
    this.focus()
  }

  /// Responds to selection events on the selection dialog.
  dialogValueSelected(event) {
    if (!this.multiple) {
      this.selections.forEach(selection => selection.remove())
    }

    this.addSelection(event.detail.value, event.detail.title, event.detail.subtitle, event.detail.imageUrl)
  }

  /// Responds to deselection events on the selection dialog.
  dialogValueDeselected(event) {
    this.removeSelection(event.detail.value)
  }

  ////////// Selections

  /// Retrieves the current set of selections.
  get selections() {
    return Array.from(this.querySelectorAll('input-association-selection'))
  }

  /// Retrieves the values from the current set of selections.
  get selectedValues() {
    return this.selections.map(selection => selection.value)
  }

  /// Adds a new selection for the given value and title.
  addSelection(value, title, subtitle, imageUrl) {
    if (this.selectedValues.includes(value)) { return }

    const selectionElement = new InputAssociationSelection(this.name, value, title, subtitle, imageUrl)
    this.selectionsContainer.append(selectionElement)

    this.triggerEvent('onspace:input-association:change')
  }

  /// Removes an existing selection with the given value.
  removeSelection(value) {
    this.selections.forEach((selection) => {
      if (selection.value === value) {
        selection.remove()
        this.triggerEvent('onspace:input-association:change')
      }
    })
  }
}

/// An element which operates a selection within an input association.
///
/// This is responsible for the selection specific functionality fo it's parent. There are two ways to use this:
/// - Create a new element from Javascript. Pass the parent's input name, along with the value, title and optional
///   subtitle.
/// - As an existing element in the DOM. It should include the title and optional subtitle in span elements, plus a
///   hidden input with the selection value.
export class InputAssociationSelection extends CustomHTMLElement {
  /// Sets up the selection element.
  ///
  /// For a new element, creates children then adds events where necessary. For an existing element, locates the
  /// children then adds events where necessary.
  runConstructor(name, value, title, subtitle, imageUrl) {
    super.runConstructor()

    if (name) {
      const inputElement = document.createElement('input')
      inputElement.type = 'hidden'
      inputElement.name = name
      inputElement.value = value
      this.append(inputElement)

      if (typeof imageUrl === 'string' && imageUrl.length > 0) {
        const imageElement = document.createElement('div')
        imageElement.classList.add('input-association-selection__image')
        imageElement.style.backgroundImage = `url(${imageUrl})`
        this.append(imageElement)
      }

      const titleElement = document.createElement('span')
      titleElement.classList.add('input-association-selection__title')
      titleElement.innerText = title
      this.append(titleElement)

      if (subtitle) {
        const subtitleElement = document.createElement('span')
        subtitleElement.classList.add('input-association-selection__subtitle')
        subtitleElement.innerText = subtitle
        this.append(subtitleElement)
      }

      this.value = value
    } else {
      const input = this.querySelector('input[type=hidden]')
      this.value = input.value
    }

    this.iconElement = SVGElement.createOnspaceSpritemapSvg('onspace/icon_cross')

    this.buttonElement = document.createElement('a')
    this.buttonElement.classList.add('input-association-selection__button')
    this.buttonElement.appendChild(this.iconElement)
    this.buttonElement.addEventListener('click', this.removeButtonClicked.bind(this))
    this.appendChild(this.buttonElement)
  }

  /// Responds to click events on the remove button.
  ///
  /// This removes this element from the DOM.
  removeButtonClicked(event) {
    const association = this.closest('input-association')

    this.remove()
    event.stopPropagation()

    association.triggerEvent('onspace:input-association:change')
  }
}

//////////

window.customElements.define('input-association', InputAssociation)
window.customElements.define('input-association-selection', InputAssociationSelection)
