import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en'

zxcvbnOptions.setOptions({
  translations: zxcvbnEnPackage.translations,
  graphs: zxcvbnCommonPackage.adjacencyGraphs,
  dictionary: {
    ...zxcvbnCommonPackage.dictionary,
    ...zxcvbnEnPackage.dictionary,
  }
})

/// An element which wraps a HTML password input.
///
/// This requires the following children:
/// - A password input.
/// - A strength bar.
/// - Validation requirements.
export default class PasswordCustom extends CustomHTMLElement {
  /// Sets up the custom password element.
  ///
  /// Locates the required children and adds events where necessary.
  runConstructor() {
    super.runConstructor()

    const symbols = this.getAttribute('data-symbols').replace(/(\(|\)|\[|\])/g, '\\$1')
    this.symbolsRegex = new RegExp(`[${symbols}]`, 'g')

    this.passwordInput = this.querySelector('input[type="password"]')
    this.passwordInput.addEventListener('input', this.passwordInputChanged.bind(this))

    this.strengthElement = this.querySelector('.password__strength')
    this.validationElements = Array.from(this.querySelectorAll('[data-validation]'))
    this.validationElements.forEach((el) => el.validationText = el.querySelector('span').innerText)

    this.detectValidations()
  }

  ////////// Events

  /// Responds to changes to the password input.
  ///
  /// This checks the value in the input, and updates the strength and validation elements.
  passwordInputChanged(_event) {
    const value = this.passwordInput.value
    this.updateStrength(value)
    this.updateValidations(value)
  }

  ////////// Strength

  /// Updates the strength UI element based on the given password.
  updateStrength(password) {
    if (typeof password !== 'string' || password.length === 0) {
      this.strengthElement.removeAttribute('data-strength')
      return
    }

    const response = zxcvbn(password)
    this.strengthElement.setAttribute('data-strength', response.score)
  }

  ////////// Validation

  /// Detects existing validations for the field.
  detectValidations() {
    const field = this.closest('.onspace-form__field')
    if (!field) { return }

    const errorElements = field.querySelectorAll('.onspace-form__field__comment--error')
    errorElements.forEach((errorElement) => {
      const matchingValidation = this.validationElements.find((val) => val.validationText == errorElement.innerText)
      if (matchingValidation) {
        errorElement.remove()
        matchingValidation.setAttribute('data-valid', 'false')
      }
    })
  }

  /// Updates the validation elements based on the given password.
  updateValidations(password) {
    if (typeof password !== 'string' || password.length === 0) {
      this.validationElements.forEach((element) => element.removeAttribute('data-valid'))
      return
    }

    this.validationElements.forEach((element) => {
      const type = element.getAttribute('data-validation')
      const value = element.getIntegerAttribute('data-value')
      let matches = null

      switch (type) {
      case 'min_length':
        matches = password
        break
      case 'min_lower_cases':
        matches = password.match(/[a-z]/g)
        break
      case 'min_upper_cases':
        matches = password.match(/[A-Z]/g)
        break
      case 'min_digits':
        matches = password.match(/[0-9]/g)
        break
      case 'min_symbols':
        matches = password.match(this.symbolsRegex)
        break
      }

      if (matches && matches.length >= value) {
        element.setAttribute('data-valid', 'true')
      } else {
        element.setAttribute('data-valid', 'false')
      }
    })
  }
}

window.customElements.define('password-custom', PasswordCustom)
