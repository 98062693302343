import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// An element which wraps a HTML color input.
///
/// This requires the following children:
/// - A text input
/// - A color input
///
/// This dynamically updates each input with the other's value when either is changed.
export default class ColorCustom extends CustomHTMLElement {
  /// Sets up the custom color element.
  ///
  /// Locates the required children and adds events where necessary.
  runConstructor() {
    super.runConstructor()

    this.textInput = this.querySelector('input[type="text"]')
    this.colorInput = this.querySelector('input[type="color"]')

    this.textInput.addEventListener('input', this.textInputChanged.bind(this))
    this.colorInput.addEventListener('change', this.colorInputChanged.bind(this))
  }

  ////////// Events

  /// Responds to changes to the text input.
  ///
  /// This checks the value in the input, then updates the color input.
  textInputChanged(_event) {
    let value = this.textInput.value
    if (value === '') {
      value = ''
      this.textInput.value = value
    } else if (!value.startsWith('#')) {
      value = `#${value}`
      this.textInput.value = value
    }

    this.colorInput.value = value
  }

  /// Responds to changes to the color input.
  ///
  /// This updates the text input with the corresponding value.
  colorInputChanged(_event) {
    this.textInput.value = this.colorInput.value.toUpperCase()
  }
}

window.customElements.define('color-custom', ColorCustom)
