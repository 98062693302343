document.addEventListener('turbo:load', (_event) => setupGrids(document))
document.addEventListener('turbo:frame-load', (event) => setupGrids(event.target))

const isTouchScreen = window.matchMedia('(pointer: coarse)').matches

function setupGrids(element) {
  element.querySelectorAll('div.onspace-grid').forEach((grid) => {
    let lastClickedIndex = 0

    const cells = Array.from(grid.querySelectorAll('.onspace-grid__cell'))
    cells.forEach((cell, cellIndex) => {
      const batchCheckbox = cell.querySelector('input[name=onspace_batch_selection]')
      cell.batchCheckbox = batchCheckbox

      if (batchCheckbox) {
        batchCheckbox.setChecked = function(checked) {
          if (this.checked === checked) { return }
          this.click()
        }

        batchCheckbox.addEventListener('click', (event) => {
          event.stopPropagation()
        })

        batchCheckbox.addEventListener('change', (_event) => {
          cell.classList.toggle('onspace-grid__cell--selected', batchCheckbox.checked)
        })

        cell.addEventListener('click', (event) => {
          if (!document.body.classList.contains('onspace-batch-selecting')) { return }

          if (isTouchScreen) {
            batchCheckbox.setChecked(!batchCheckbox.checked)
          } else {
            if (event.shiftKey) {
              if (cellIndex > lastClickedIndex) {
                for (let i=cellIndex; i>=lastClickedIndex; i--) {
                  const input = cells[i].batchCheckbox
                  if (input) {
                    input.setChecked(true)
                  }
                }
              } else {
                for (let i=cellIndex; i<=lastClickedIndex; i++) {
                  const input = cells[i].batchCheckbox
                  if (input) {
                    input.setChecked(true)
                  }
                }
              }
            } else if (event.metaKey || event.ctrlKey) {
              batchCheckbox.setChecked(!batchCheckbox.checked)
            } else {
              cells.forEach((gridCell) => {
                if (gridCell.batchCheckbox && gridCell != cell) {
                  gridCell.batchCheckbox.setChecked(false)
                }

                batchCheckbox.setChecked(true)
              })
            }

            lastClickedIndex = cellIndex
          }

          document.triggerEvent('onspace:batch-selection:changed')

          event.preventDefault()
          event.stopPropagation()
        })
      }
    })
  })
}
