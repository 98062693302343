import { register } from '@onpace/onspace-core/components/translations'

register({
  "en": {
    "onspace": {
      "analytics": {
        "metric": {
          "key": {
            "total": "Total",
            "blank": "Blank"
          },
          "pagination": {
            "limit": "Rows per page",
            "of": "of"
          },
          "export": {
            "title": "Export",
            "format": {
              "csv": "Comma-Separated Values (.csv)",
              "json": "Javascript Object Notation (.json)",
              "pdf": "Portable Document Format (.pdf)",
              "xml": "Extensible Markup Language (.xml)"
            }
          },
          "error": {
            "unknown_fatal": "An error occurred loading the chart.",
            "retry": "Retry"
          }
        }
      }
    }
  }
})
