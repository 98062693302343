import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import translation from '@onpace/onspace-core/components/translations'
import OnspaceVideoPlayer from '@onpace/onspace-media/elements/player/video'
import OnspacePlayerBar from '@onpace/onspace-media/elements/player/bar'

import OnspaceVideoSelector from '@onpace/onspace-media/elements/player/video/selector'

const PLAYERS_LAYOUT_GRID = 'grid'
const PLAYERS_LAYOUT_ASYMMETRIC = 'asymmetric'

const PLAYERS_ORIENTATION_PORTRAIT = 'portrait'
const PLAYERS_ORIENTATION_LANSCAPE = 'landscape'

const MAX_PLAYERS_MOBILE = 2
const MAX_PLAYERS_NORMAL = 4

/// The Onspace Multi Screen Video is a component which can display multiple video players.
export default class OnspaceVideoMultiScreen extends CustomHTMLElement {
  /// Sets up the Multi Screen Player element.
  ///
  /// The element can be configured either from an object passed through the constructor, or as attributes on the
  /// element. The following options are supported:
  /// [videos]
  ///   An array of video objects. See OnspaceVideoMultiScreen.parseVideos for more information.
  /// [sourcesLoader]
  ///   An asynchronous function which can load a source.
  /// [defaultVideoId]
  ///   The ID of the video to play by default. If not provided, the first item in +videos+ will be used.
  /// [hideSelectionControls]
  ///   Removes the selection overlay controls for inline mode, if they are to be provided externally. The layout toggle
  ///   will be included in the main controls element.
  ///
  ///   When using full screen, the selection overlay will be used.
  /// [autoplay]
  ///   When +true+, the media will start as soon as the player enters the DOM. This is +false+ by default.
  ///
  ///   Note that most browsers will prevent auto-playing audio unless it was triggered via a user interaction. See
  ///   OnspaceMediaPlayer.resumePlayback for more info.
  /// [analytics]
  ///   Optional information to be sent with analytics events. This should be an object, and inherits options from the
  ///   +analytics+ object in OnspaceMediaPlayer.
  runConstructor(_options = {}) {
    super.runConstructor()

    this._maxPlayers = MAX_PLAYERS_NORMAL

    this.addPressEventListeners({ onPress: this.pressed.bind(this) })

    this.addEventListener('fullscreenchange', this.fullscreenChanged.bind(this))
    this.resizeObserver = new ResizeObserver(this.resizeObserved.bind(this))
  }

  /// Runs when the element is first connected to the DOM.
  runFirstConnected(options = {}) {
    super.runFirstConnected()

    this.classList.add('onspace-multi-player')

    this.hideSelectionControls = options.hideSelectionControls || this.getBooleanAttribute('hideSelectionControls')
    this.autoplay = options.autoplay || this.getBooleanAttribute('autoplay')

    const videos = options.videos || this.getJsonAttribute('data-videos') || []
    this.videos = this.parseVideos(videos)

    this.sourcesLoader = this.sourcesLoader || options.sourcesLoader || null

    this.analyticsParams = options.analytics || this.getJsonAttribute('analytics') || {}
    if (!this.analyticsParams.player_type) { this.analyticsParams.player_type = 'multi-screen' }

    if (this.videos.length === 0) {
      this.showCoverMessage(translation('onspace.media.player.error.source_missing'))
    } else {
      this.setupPlayers()
      this.setupControls()

      const defaultVideoId = options.defaultVideoId || this.getAttribute('data-default-video-id') || this.videos[0].id
      this.addPlayer(defaultVideoId)
    }

    const computedStyle = this.getComputedStyle()
    const rawBreakpoint = computedStyle.getPropertyValue('--onspace-multi-player-breakpoint')
    this.mobileBreakpoint = parseInt(rawBreakpoint.replace('px', ''))
  }

  /// Runs when the media player is connected to the DOM.
  runConnected() {
    super.runConnected()

    this.keysDown = []
    this.addDocumentBoundEventListener('keydown', this.documentKeyDowned)
    this.addDocumentBoundEventListener('keyup', this.documentKeyUpped)

    this.resizeObserver.observe(this)
    this.detectLayoutOrientation()
    this.detectPlayersLayoutPreference()
  }

  /// Runs when the video player is disconnected from the DOM.
  ///
  /// This cleans up events relating to the document.
  runDisconnected() {
    super.runDisconnected()

    this.removeDocumentBoundEventListener('keydown')
    this.removeDocumentBoundEventListener('keyup')

    this.resizeObserver.unobserve(this)
  }

  ////////// Events

  /// Responds to changes in the element's size.
  resizeObserved(_entries) {
    this.detectLayoutOrientation()
  }

  ///// Interaction

  /// Responds to presses on the element.
  pressed(_event) {
    if (this.controlsActive) {
      this.hideControls()
    }
  }

  ///// Keyboard

  /// Callback for keyboard presses on the document.
  ///
  /// This checks and ignores any repeated key presses until the key is lifted. It then delegates functionality to
  /// +keyPressBegan+.
  ///
  /// Note that this will only be triggered when the menu is active.
  documentKeyDowned(event) {
    if (this.keysDown.includes(event.key)) { return }

    this.keysDown.push(event.key)

    this.keyPressBegan(event)
  }

  /// Callback for keyboard releases on the document.
  ///
  /// This delegates functionality to +keyPressEnded+.
  documentKeyUpped(event) {
    const index = this.keysDown.indexOf(event.key)
    this.keysDown.splice(index, 1)

    this.keyPressEnded(event)
  }

  /// Responds to keyboard presses beginning on the document.
  keyPressBegan(event) {
    switch (event.key) {
    case 'Escape':
      if (this.controlsActive) {
        this.hideControls()
      } else if (this.activePlayer) {
        return this.activePlayer.keyPressBegan(event)
      } else {
        return
      }
      break
    case 'f':
      this.toggleFullscreen()
      break
    case 'v':
      this.toggleControls()
      break
    case 'l':
      this.togglePlayersLayout()
      break
    default:
      if (this.activePlayer) {
        return this.activePlayer.keyPressBegan(event)
      } else {
        return
      }
    }

    event.preventDefault()
    event.stopPropagation()
    return false
  }

  /// Responds to keyboard presses ending on the document.
  keyPressEnded(event) {
    if (this.activePlayer) {
      return this.activePlayer.keyPressEnded(event)
    }
  }

  ////////// Controls

  /// Initialises and configures the controls elements.
  setupControls() {
    if (this.controlsElement) { return }

    this.controlsElement = this.createControlsElement()
    this.appendChild(this.controlsElement)

    this.setupVideosSelector()
  }

  /// Creates a new controls element.
  createControlsElement() {
    const controlsElement = document.createElement('div')
    controlsElement.classList.add('onspace-multi-player__controls')

    const barLeftElement = new OnspacePlayerBar()
    barLeftElement.classList.add('onspace-player-bar--left')
    controlsElement.appendChild(barLeftElement)

    this.layoutGridButton = barLeftElement.addButton('layout_grid', { pressed: this.layoutGridPressed.bind(this) })
    this.layoutAsymmetricButton = barLeftElement.addButton('layout_asymmetric', { pressed: this.layoutAsymmetricPressed.bind(this) })

    const barRightElement = new OnspacePlayerBar()
    barRightElement.classList.add('onspace-player-bar--left')
    controlsElement.appendChild(barRightElement)

    barRightElement.addButton('close', { icon: 'onspace/icon_cross', pressed: this.closeButtonPressed.bind(this) })

    return controlsElement
  }

  /// Indicates if the controls are currently active.
  get controlsActive() {
    return this.classList.contains('onspace-multi-player--controls-active')
  }

  /// Indicates if the controls can be displayed.
  get canControl() {
    return !!this.activePlayer && this.activePlayer.presentationMode !== 'pictureinpicture'
  }

  /// Indicates if the player can toggle the controls.
  get playerCanShowControls() {
    return this.canControl && (!this.hideSelectionControls || this.fullscreenActive)
  }

  /// Makes the controls active or inactive, depending on the current state.
  toggleControls() {
    if (this.controlsActive) {
      this.hideControls()
    } else {
      this.showControls()
    }
  }

  /// Makes the controls active.
  showControls() {
    if (!this.canControl) { return }

    this.classList.add('onspace-multi-player--controls-active')
  }

  /// Makes the controls inactive.
  hideControls() {
    this.classList.remove('onspace-multi-player--controls-active')
  }

  ///// Events

  /// Responds to clicks on the grid layout button.
  layoutGridPressed(_event) {
    this.playersLayout = PLAYERS_LAYOUT_GRID
  }

  /// Responds to clicks on the asymmetric layout button.
  layoutAsymmetricPressed(_event) {
    this.playersLayout = PLAYERS_LAYOUT_ASYMMETRIC
  }

  /// Responds to clicks on the close button.
  closeButtonPressed(_event) {
    this.hideControls()
  }

  ////////// Videos

  /// Determines the available videos for the players.
  ///
  /// Each object can contain the following attributes:
  /// [id]
  ///   A unique string representing the video.
  /// [title]
  ///   The title of the video.
  /// [subtitle]
  ///   The subtitle of the video.
  /// [artwork]
  ///   An image url representing the video.
  /// [sources]
  ///   An array of source objects. See OnspaceMediaPlayer.parseSources for more information.
  /// [analytics]
  ///   Optional information to be sent with analytics events. This should be an object, and inherits options from the
  ///   +analytics+ object in OnspaceMediaPlayer.
  ///
  ///   Note that this will also inherit options from the multi-screen's +analytics+ argument.
  ///
  /// While the only required attribute is +sources+, it is recommended to provide at least one of +title+ or +artwork+
  /// otherwise the UI doesn't make much sense.
  parseVideos(videoObjects) {
    const videos = []

    videoObjects.forEach((videoObject) => {
      if (typeof videoObject.id !== 'string' || videoObject.id.length === 0) { return }

      videos.push(videoObject)
    })

    return videos
  }

  /// Initialises and configures the videos selector element.
  setupVideosSelector() {
    if (this.videosSelector) { return }

    this.videosSelector = this.createVideosSelectorElement()
    this.videos.forEach((video) => {
      this.videosSelector.addVideo(video)
    })

    this.videosSelector.addEventListener('onspace:media:video-selector:video-clicked', this.videoSelected.bind(this))

    this.controlsElement.appendChild(this.videosSelector)
  }

  /// Creates a new videos selector element.
  createVideosSelectorElement() {
    return new OnspaceVideoSelector()
  }

  /// Responds to the video selector selecting a video.
  videoSelected(event) {
    this.togglePlayer(event.detail)
  }

  ////////// Players

  /// Initialises and configures the players container element.
  setupPlayers() {
    if (this.playersContainerElement) { return }

    this.playersContainerElement = this.createPlayersContainerElement()
    this.appendChild(this.playersContainerElement)
  }

  /// Creates a players container element.
  createPlayersContainerElement() {
    const playersContainerElement = document.createElement('div')
    playersContainerElement.classList.add('onspace-multi-player__players')

    return playersContainerElement
  }

  /// Indicates if an additional player can be added.
  get canAddPlayer() {
    return this.players.length < this.maxPlayers && !!this.activePlayer && this.activePlayer.presentationMode !== 'pictureinpicture'
  }

  /// Indicates if an existing player can be removed.
  get canRemovePlayer() {
    return this.hasMultiplePlayers
  }

  /// Adds or removes the player with the given +videoId+.
  togglePlayer(videoId) {
    const players = Array.from(this.players)
    const existingPlayer = players.find((player) => player.multiScreenVideoId === videoId)

    if (existingPlayer) {
      this.removePlayer(videoId)
    } else {
      this.addPlayer(videoId)
    }
  }

  /// Adds a player with the given +videoId+.
  addPlayer(videoId) {
    if (!this.canAddPlayer && this.players.length > 0) { return }

    const video = this.videos.find((video) => video.id === videoId)
    if (!video) {
      console.error(`Unable to find video with ID "${videoId}"`) // eslint-disable-line no-console
      return
    }

    const player = new OnspaceVideoPlayer({
      sourcesLoader: async (options) => {
        if (typeof this.sourcesLoader === 'function') {
          return await this.sourcesLoader(options)
        } else {
          return { sources: video.sources }
        }
      },
      multiScreen: this,
      metadata: {
        id: video.id,
        title: video.title,
        subtitle: video.subtitle,
        artwork: video.artwork
      },
      autoplay: (this.players.length > 0 || this.autoplay),
      analytics: {
        ...this.analyticsParams,
        ...(video.analytics || {})
      }
    })
    player.multiScreenVideoId = video.id
    player.addEventListener('onspace:media:player:activated-changed', this.playerActivatedChanged.bind(this))

    const playerActiveIcon = SVGElement.createOnspaceSpritemapSvg('onspace/player_volume_loud')
    playerActiveIcon.classList.add('onspace-multi-player__player__active')
    playerActiveIcon.style.display = null
    player.appendChild(playerActiveIcon)

    const playerIndexIcon = document.createElement('div')
    playerIndexIcon.classList.add('onspace-multi-player__player__index')
    player.appendChild(playerIndexIcon)
    player.setIndex = (index) => playerIndexIcon.innerText = index.toString()

    const playerWrapper = document.createElement('div')
    playerWrapper.classList.add('onspace-multi-player__player-wrapper')
    playerWrapper.appendChild(player)

    this.playersContainerElement.appendChild(playerWrapper)
    this.playersStateChanged()

    this.triggerEvent('onspace:media:multi-screen:players-changed')
  }

  /// Removes the player with the given +videoId+.
  removePlayer(videoId) {
    if (!this.canRemovePlayer) { return }

    const players = Array.from(this.players)
    const existingPlayer = players.find((player) => player.multiScreenVideoId === videoId)
    if (!existingPlayer) { return }

    if (existingPlayer.activated) {
      if (players[0] === existingPlayer) {
        players[1].activate()
      } else {
        players[0].activate()
      }
    }

    existingPlayer.parentElement.remove()
    this.playersStateChanged()

    this.triggerEvent('onspace:media:multi-screen:players-changed')
  }

  /// Retrieves all players.
  get players() {
    return this.playersContainerElement.querySelectorAll('onspace-video')
  }

  /// Indicates if there is more than 1 player.
  get hasMultiplePlayers() {
    return this.players.length > 1
  }

  /// Retrieves the player which is currently active.
  get activePlayer() {
    return this._activePlayer
  }

  /// Retrieves the maximum number of players that can be shown.
  get maxPlayers() {
    return this._maxPlayers
  }

  /// Sets the maximum number of players that can be shown.
  ///
  /// If the current number of players is greater than the new value, players will be removed.
  set maxPlayers(value) {
    this._maxPlayers = value

    const currentPlayers = Array.from(this.players)
    if (currentPlayers && currentPlayers.length > value) {
      const extraPlayers = currentPlayers.slice(value)
      extraPlayers.forEach((player) => {
        this.removePlayer(player.multiScreenVideoId)
      })

      const message = translation('onspace.media.player.multi_screen.max_limit.plural').replace('%{limit}', value)
      this.showOverlayMessage(message, { icon: 'onspace/icon_warning', timeout: 5000 })
    }
  }

  /// Sets a player as the active player.
  set activePlayer(value) {
    if (this.activePlayer === value) { return }

    let existingVolume = null
    if (this.activePlayer) {
      existingVolume = this.activePlayer.audioVolume
      this.activePlayer.deactivate({ pause: false })
    }

    this._activePlayer = value

    if (typeof existingVolume === 'number' && existingVolume > 0) {
      value.audioVolume = existingVolume
    }
  }

  /// Notifies all players that they should update their presentation state.
  playersStateChanged() {
    const players = Array.from(this.players)

    this.playersContainerElement.setAttribute('data-players', this.players.length)

    this.updateLayoutButtons()

    const selectedPlayers = {}

    players.forEach((player, index) => {
      selectedPlayers[player.multiScreenVideoId] = index + 1
      player.setIndex(index + 1)

      if (player.playbackStarted) {
        player.detectPresentationMode()
      }
    })

    this.videosSelector.selectedVideos = selectedPlayers
  }

  /// Updates the state and icons of the layout buttons.
  updateLayoutButtons() {
    if (this.hasMultiplePlayers) {
      const playerCount = this.players.length

      this.layoutGridButton.style.display = ''
      this.layoutGridButton.setIcon(`onspace/player_multiscreen_${this.playersOrientation}_grid${playerCount}`)
      this.layoutGridButton.setHighlighted(this.playersLayout === PLAYERS_LAYOUT_GRID)

      this.layoutAsymmetricButton.style.display = ''
      this.layoutAsymmetricButton.setIcon(`onspace/player_multiscreen_${this.playersOrientation}_asymmetric${playerCount}`)
      this.layoutAsymmetricButton.setHighlighted(this.playersLayout === PLAYERS_LAYOUT_ASYMMETRIC)
    } else {
      this.layoutGridButton.style.display = 'none'
      this.layoutAsymmetricButton.style.display = 'none'
    }
  }

  /// Retrieves the name of the icon to use for a toggle button.
  get toggleLayoutButtonIcon() {
    let layoutName
    switch (this.playersLayout) {
    case PLAYERS_LAYOUT_GRID:
      layoutName = PLAYERS_LAYOUT_ASYMMETRIC
      break
    case PLAYERS_LAYOUT_ASYMMETRIC:
      layoutName = PLAYERS_LAYOUT_GRID
      break
    }

    return `onspace/player_multiscreen_${this.playersOrientation}_${layoutName}${this.players.length}`
  }

  ///// Layout

  /// Indicates if the player can control the layout.
  get playerCanControlLayout() {
    return this.canControl && this.players.length > 1
  }

  /// Detects which players layout to use.
  ///
  /// When the layout is changed, that preference is set in +localStorage+. This retrieves that preference and will
  /// re-enable that same layout.
  detectPlayersLayoutPreference() {
    if (!this.playersContainerElement) { return }

    const preference = localStorage.getItem('onspace-multi-video-layout') || PLAYERS_LAYOUT_GRID
    this.playersLayout = preference
  }

  /// Detects whether to use a mobile or regular layout, and the orientation.
  ///
  /// This is called automatically when the size of the element is changed.
  detectLayoutOrientation() {
    if (!this.playersContainerElement) { return }

    const clientRect = this.getBoundingClientRect()
    if (clientRect.width < this.mobileBreakpoint || clientRect.height < this.mobileBreakpoint) {
      this.maxPlayers = MAX_PLAYERS_MOBILE
    } else {
      this.maxPlayers = MAX_PLAYERS_NORMAL
    }

    if (clientRect.width * 1.2 < clientRect.height) {
      this.playersOrientation = PLAYERS_ORIENTATION_PORTRAIT
    } else {
      this.playersOrientation = PLAYERS_ORIENTATION_LANSCAPE
    }
  }

  /// Cycles through the players layouts.
  togglePlayersLayout() {
    switch (this.playersLayout) {
    case PLAYERS_LAYOUT_GRID:
      this.playersLayout = PLAYERS_LAYOUT_ASYMMETRIC
      break
    case PLAYERS_LAYOUT_ASYMMETRIC:
      this.playersLayout = PLAYERS_LAYOUT_GRID
      break
    }
  }

  /// Retrieves the current layout for players.
  get playersLayout() {
    return this._playersLayout
  }

  /// Sets the layout for players.
  ///
  /// If the number of active players is more than can be shown in the layout, some players will be removed.
  set playersLayout(value) {
    if (this.playersLayout === value) { return }

    if (this.playersLayout) {
      this.playersContainerElement.classList.remove(`onspace-multi-player__players--${this.playersLayout}`)
    }

    this._playersLayout = value
    localStorage.setItem('onspace-multi-video-layout', value)

    this.playersContainerElement.classList.add(`onspace-multi-player__players--${this.playersLayout}`)
    this.playersStateChanged()
  }

  /// Retrieves the current orientation for players.
  get playersOrientation() {
    return this._playersOrientation
  }

  /// Sets the orientation for players.
  set playersOrientation(value) {
    if (this.playersOrientation === value) { return }

    if (this.playersOrientation) {
      this.playersContainerElement.classList.remove(`onspace-multi-player__players--${this.playersOrientation}`)
    }

    this._playersOrientation = value
    this.playersContainerElement.classList.add(`onspace-multi-player__players--${this.playersOrientation}`)
    this.playersStateChanged()
  }

  ///// Events

  /// Callback for when a player changes its activation state.
  ///
  /// If the player is now active, this updates the +activePlayer+.
  playerActivatedChanged(event) {
    const player = event.target

    if (player.activated) {
      this.activePlayer = player
    }

    this.triggerEvent('onspace:media:multi-screen:players-changed')
  }

  ////////// Presentation

  /// Indicates if a player is allowed to enter full screen mode.
  get playerCanAirplay() {
    return this.players.length === 1
  }

  /// Indicates if a player is allowed to enter picture in picture mode.
  get playerCanEnterPictureInPicture() {
    return this.players.length === 1 && !this.fullscreenActive
  }

  /// Indicates if a player is allowed to enter full screen mode.
  get playerCanEnterFullscreen() {
    return this.canEnterFullscreen
  }

  ///// Full Screen

  /// Indicates if the element supports full screen.
  get supportsFullscreen() {
    return document.fullscreenEnabled
  }

  /// Indicates if the element can currently enter full screen mode.
  get canEnterFullscreen() {
    return this.supportsFullscreen
  }

  /// Indicates if the element is currently in full screen mode.
  get fullscreenActive() {
    return !!document.fullscreenElement
  }

  /// Enters or exits full screen mode, depending on the current state.
  toggleFullscreen() {
    if (this.fullscreenActive) {
      this.exitFullscreen()
    } else {
      this.enterFullscreen()
    }
  }

  /// Enters the element into full screen mode.
  enterFullscreen() {
    if (!this.canEnterFullscreen) { return }

    this.requestFullscreen()
  }

  /// Exits the element from full screen mode.
  exitFullscreen() {
    document.exitFullscreen()
  }

  /// Responds to full screen changes on the element.
  fullscreenChanged(_event) {
    this.playersStateChanged()
  }

  ////////// Messages

  /// Removes any messages that are currently displayed.
  clearMessages() {
    if (this.overlayMessage) {
      this.overlayMessage.remove()
      this.overlayMessage = null
    }

    if (this.coverMessage) {
      this.coverMessage.remove()
      this.coverMessage = null
    }
  }

  /// Displays a messages over the element.
  ///
  /// This should be used to display information to a user. This accepts the following options:
  /// [icon]
  ///   An optional icon to be shown with the message.
  /// [timeout]
  ///   An optional duration in milliseconds to hide the message after.
  showOverlayMessage(message, options={}) {
    this.overlayMessage = document.createElement('div')
    this.overlayMessage.classList.add('onspace-multi-player__overlay-message')

    if (options.icon) {
      const iconElement = SVGElement.createOnspaceSpritemapSvg(options.icon)
      this.overlayMessage.appendChild(iconElement)
    }

    const messageElement = document.createElement('p')
    messageElement.innerText = message
    this.overlayMessage.appendChild(messageElement)

    this.appendChild(this.overlayMessage)

    if (options.timeout) {
      window.setTimeout(() => { this.clearMessages() }, options.timeout)
    }
  }


  /// Displays a message that covers the entire player.
  ///
  /// This should be used to display information relating to an error which caused the player to stop completely. Prior,
  /// to calling this, any active player and other elements should be cleaned up. This accepts the following options:
  /// [icon]
  ///   An optional icon to be shown with the message. By default, this will be set to +onspace/icon_warning+.
  /// [actions]
  ///   An optional array of actions to be shown with the message. This should be an array of objects, each containing a
  ///   +title+ and +callback+.
  ///
  /// Note that the cover message is always appended to +this+, and not the +contentElement+.
  showCoverMessage(message, options={}) {
    this.clearMessages()

    this.coverMessage = document.createElement('div')
    this.coverMessage.classList.add('onspace-player-cover-message')

    const icon = options.icon || 'onspace/icon_warning'
    const iconElement = SVGElement.createOnspaceSpritemapSvg(icon)
    this.coverMessage.appendChild(iconElement)

    const messageElement = document.createElement('p')
    messageElement.innerText = message
    this.coverMessage.appendChild(messageElement)

    if (options.actions && options.actions.length > 0) {
      const actionsElement = document.createElement('div')
      actionsElement.classList.add('onspace-player-cover-message__actions')

      options.actions.forEach((action) => {
        const actionElement = document.createElement('div')
        actionElement.classList.add('onspace-button')
        actionElement.innerText = action.title
        actionElement.addEventListener('click', action.callback)

        actionsElement.appendChild(actionElement)
      })

      this.coverMessage.appendChild(actionsElement)
    }

    this.appendChild(this.coverMessage)
  }
}

window.customElements.define('onspace-multi-video', OnspaceVideoMultiScreen)
