import '@onpace/svgspritemap-rails'

/// Updates the spritemap symbol on the SVG element.
///
/// This simply calls +setSpritemapSvg+, overriding the +:spritemap+ option to use 'onspace/cms', and also setting the
/// +viewbox+ option to +0 0 20 20+ unless it was already given.
SVGElement.prototype.setOnspaceSpritemapSvg = function(name, options={}) {
  if (name && name.startsWith('flag/')) {
    options.viewbox ||= '0 0 20 15'
  } else {
    options.viewbox ||= '0 0 20 20'
  }

  options.spritemap = 'onspace'

  this.setSpritemapSvg(name, options)
}

/// Creates a new spritemap SVG element.
///
/// This simply calls +createSpritemapSvg+, overriding the +:spritemap+ option to use 'onspace/cms', and also setting
/// the +viewbox+ option to +0 0 20 20+ unless it was already given.
SVGElement.createOnspaceSpritemapSvg = function(name, options={}) {
  if (name && name.startsWith('flag/')) {
    options.viewbox ||= '0 0 20 15'
  } else {
    options.viewbox ||= '0 0 20 20'
  }

  options.spritemap = 'onspace'

  return this.createSpritemapSvg(name, options)
}
