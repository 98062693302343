/// Generates a numeric hash code from the string.
///
/// Taken from https://github.com/bryc/code/blob/da36a3e07acfbd07f930a9212a2df9e854ff56e4/jshash/experimental/cyrb53.js
String.prototype.getHashCode = function() {
  const seed = 0
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed

  for (let i = 0, ch; i < this.length; i++) {
    ch = this.charCodeAt(i)
    h1 = Math.imul(h1 ^ ch, 2654435761)
    h2 = Math.imul(h2 ^ ch, 1597334677)
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507)
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909)
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507)
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909)

  return 4294967296 * (2097151 & h2) + (h1 >>> 0)
}
