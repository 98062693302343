import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

import translation from '@onpace/onspace-core/components/translations'
import OnspacePlayerBar from '@onpace/onspace-media/elements/player/bar'
import OnspacePlayerTabs from '@onpace/onspace-media/elements/player/tabs'

/// The Onspace Player Controls is an element to control a Media Player.
///
/// This class should not be used directly, instead subclass it and override the functionality as required.
export default class OnspacePlayerControls extends CustomHTMLElement {
  /// Runs when the controls element is first connected to the DOM.
  runFirstConnected(options = {}) {
    super.runFirstConnected(options)

    this.classList.add('onspace-player__controls')

    this.player = options.player

    this.player.addEventListener('onspace:media:player:metadata-changed', this.playerMetadataChanged.bind(this))
    this.player.addEventListener('onspace:media:player:activated-changed', this.playerActivatedChanged.bind(this))
    this.player.addEventListener('onspace:media:player:playback-started', this.playerPlaybackStarted.bind(this))
    this.player.addEventListener('onspace:media:player:playback-changed', this.playerPlaybackChanged.bind(this))
    this.player.addEventListener('onspace:media:player:position-changed', this.playerPositionChanged.bind(this))
    this.player.addEventListener('onspace:media:player:chapter-changed', this.playerChapterChanged.bind(this))
    this.player.addEventListener('onspace:media:player:volume-changed', this.playerVolumeChanged.bind(this))
    this.player.addEventListener('onspace:media:player:tabs-changed', this.playerTabsChanged.bind(this))
    this.player.addEventListener('onspace:media:player:remote-changed', this.playerRemoteChanged.bind(this))

    this.setupStructure()
    this.setupInitialContent()
    if (this.player.playbackStarted) {
      this.setupPlaybackContent()
    }
  }

  ////////// Player

  /// Responds to the player changing its metadata.
  playerMetadataChanged(_event) {
    this.updateMetadataElement()
  }

  /// Responds to the player changing its activated state.
  playerActivatedChanged(_event) {
    this.updateAudioElements()
  }

  /// Responds to the player beginning playback.
  playerPlaybackStarted(_event) {
    this.setupPlaybackContent()
    this.updateSkipActions()
  }

  /// Responds to the playback state of the player changing.
  playerPlaybackChanged(_event) {
    this.updatePlaybackControls()
    this.detectPositionScrubberAnimation()
  }

  /// Responds to the playback position of the player changing.
  playerPositionChanged(_event) {
    this.updatePositionElement()
  }

  /// Responds to the current chapter of the player changing.
  playerChapterChanged(_event) {
    this.updateSkipActions()
  }

  /// Responds to the volume of the player changing.
  playerVolumeChanged(_event) {
    this.updateAudioElements()
  }

  /// Responds to the player's tabs changing.
  playerTabsChanged(_event) {
    this.updateTabsElement()
  }

  /// Responds to the remote state of the player changing.
  playerRemoteChanged(_event) {
    this.updateRemoteControls()
  }

  ////////// Structure

  /// Creates the structural elements for the controls.
  setupStructure() {
    this.headerElement = document.createElement('div')
    this.headerElement.classList.add('onspace-player__controls__header')
    this.appendChild(this.headerElement)

    this.centerElement = document.createElement('div')
    this.centerElement.classList.add('onspace-player__controls__center')
    this.appendChild(this.centerElement)

    this.footerElement = document.createElement('div')
    this.footerElement.classList.add('onspace-player__controls__footer')
    this.appendChild(this.footerElement)
  }

  /// Sets up the content which should be displayed at all times.
  setupInitialContent() {
    this.setupMetadata()
    this.setupBars()
  }

  /// Sets up the content which should only be displayed after playback has begun.
  setupPlaybackContent() {
    this.setupActionControls()
    this.setupPlaybackControls()
    this.setupAudioControls()
    this.setupRemoteControls()
    this.setupPositionControls()
    this.setupTabsElement()
  }

  ////////// Actions

  /// Adds or removes the skip actions based on the player's current chapter.
  updateSkipActions() {
    if (this.player.canSkipChapter) {
      this.addSkipAction()
    } else {
      this.removeSkipAction()
    }
  }

  /// Adds a button to skip the current chapter, if available.
  addSkipAction() {
    if (!this.skipActionElement) {
      this.skipActionElement = document.createElement('a')
      this.skipActionElement.classList.add('onspace-player__controls__skip')
      this.skipActionElement.addPressEventListeners({ onPress: this.skipActionPressed.bind(this) })
      this.centerElement.appendChild(this.skipActionElement)
    }

    this.skipActionElement.innerText = translation(`onspace.media.player.chapter.skip.${this.player.currentChapter.type}`)
  }

  /// Removes the button to skip the current chapter, if setup.
  removeSkipAction() {
    if (!this.skipActionElement) { return }

    this.skipActionElement.remove()
    this.skipActionElement = null
  }

  /// Responds to clicks on the skip action button.
  skipActionPressed() {
    this.player.skipChapter()
  }

  ////////// Metadata

  /// Initialises the metadata element.
  setupMetadata() {
    if (this.metadataElement) { return }

    this.metadataElement = this.createMetadataElement()
    this.updateMetadataElement()
    this.footerElement.appendChild(this.metadataElement)
  }

  /// Creates the metadata element.
  ///
  /// The metadata element contains information about the media, given by the +metadata+ initialisation option. If there
  /// is no metadata to display here, this returns null.
  createMetadataElement() {
    const metadataElement = document.createElement('div')
    metadataElement.classList.add('onspace-player__controls__metadata')

    return metadataElement
  }

  /// Updates the metadata element.
  updateMetadataElement() {
    this.metadataElement.innerHTML = ''

    const metadata = this.player.metadata || {}

    if (metadata.artwork) {
      const artworkElement = document.createElement('img')
      artworkElement.src = metadata.artwork
      this.metadataElement.appendChild(artworkElement)
    }

    const contentElement = document.createElement('div')

    if (metadata.title) {
      const titleElement = document.createElement('span')
      titleElement.classList.add('onspace-player__controls__metadata__title')
      titleElement.innerText = metadata.title
      contentElement.appendChild(titleElement)
    }

    if (metadata.subtitle) {
      const subtitleElement = document.createElement('div')
      subtitleElement.classList.add('onspace-player__controls__metadata__subtitle')
      subtitleElement.innerText = metadata.subtitle
      contentElement.appendChild(subtitleElement)
    }

    this.metadataElement.appendChild(contentElement)
  }

  ////////// Bars

  /// Initialises the bars.
  setupBars() {
    if (this.barLeftElement) { return }

    [this.headerBarElement, this.barLeftElement, this.barRightElement] = this.createBars()

    this.headerElement.appendChild(this.headerBarElement)
    this.footerElement.appendChild(this.barLeftElement)
    this.footerElement.appendChild(this.barRightElement)
  }

  /// Creates the bar elements.
  createBars() {
    const headerBarElement = new OnspacePlayerBar()
    headerBarElement.classList.add('onspace-player-bar--header')

    const barLeftElement = new OnspacePlayerBar()
    barLeftElement.classList.add('onspace-player-bar--left')

    const barRightElement = new OnspacePlayerBar()
    barRightElement.classList.add('onspace-player-bar--right')

    return [headerBarElement, barLeftElement, barRightElement]
  }

  //////////

  /// Initialises the elements for custom action buttons.
  setupActionControls() {
    if (typeof this.player.closeButtonCallback === 'function') {
      if (this.closeButton) { return }

      this.closeButton = this.headerBarElement.addButton('close', { icon: 'onspace/icon_cross', pressed: this.player.closeButtonCallback })
    } else if (this.closeButton) {
      this.closeButton.remove()
      this.closeButton = null
    }
  }

  ////////// Playback

  /// Initialises the elements which control playback.
  setupPlaybackControls() {
    if (this.playPauseButton) { return }

    this.skipBackwardButton = this.barLeftElement.addButton('skip-backward', { icon: 'onspace/player_skip_backward', pressBegan: this.skipBackwardPressBegan.bind(this), pressEnded: this.skipBackwardPressEnded.bind(this) })
    this.playPauseButton = this.barLeftElement.addButton('play-pause', { icon: 'onspace/player_play', pressed: this.playPausePressed.bind(this) })
    this.skipForwardButton = this.barLeftElement.addButton('skip-forward', { icon: 'onspace/player_skip_forward', pressBegan: this.skipForwardPressBegan.bind(this), pressEnded: this.skipForwardPressEnded.bind(this) })

    this.loadingIndicator = this.barLeftElement.addIndicator('loading', { icon: 'onspace/icon_loading' })

    this.updatePlaybackControls()
  }

  /// Updates the state of playback controls.
  updatePlaybackControls() {
    if (!this.playPauseButton) { return }

    if (this.player.isPlaying) {
      this.playPauseButton.setIcon('onspace/player_pause')
    } else {
      this.playPauseButton.setIcon('onspace/player_play')
    }
  }

  ///// Events

  /// Responds to clicks on the play/pause button.
  playPausePressed(_event) {
    this.player.togglePlayback()
  }

  /// Responds to a press on the skip backward button starting.
  skipBackwardPressBegan(_event) {
    this.player.beginRepeatedlyAdjustingPosition(-10, 250)
  }

  /// Responds to a press on the skip backward button finishing.
  skipBackwardPressEnded(_event) {
    this.player.endRepeatedlyAdjustingPosition()
  }

  /// Responds to a press on the skip forward button starting.
  skipForwardPressBegan(_event) {
    this.player.beginRepeatedlyAdjustingPosition(10, 250)
  }

  /// Responds to a press on the skip forward button ending.
  skipForwardPressEnded(_event) {
    this.player.endRepeatedlyAdjustingPosition()
  }

  ////////// Audio

  /// Initialises the elements which control audio.
  setupAudioControls() {
    if (this.volumeButton) { return }

    this.volumeButton = this.barRightElement.addDropdownSlider('volume', { icon: 'onspace/player_volume_muted', pressed: this.volumePressed.bind(this), changed: this.volumeChanged.bind(this) })

    this.updateAudioElements()
  }

  /// Updates the state of audio elements.
  updateAudioElements() {
    if (!this.volumeButton) { return }

    const volume = this.player.audioVolume

    if (this.player.canControlAudio) {
      this.volumeButton.style.display = ''

      if (!this.player.audioEnabled) {
        this.volumeButton.setIcon('onspace/player_volume_muted')
      } else if (volume > 0.6) {
        this.volumeButton.setIcon('onspace/player_volume_loud')
      } else if (volume > 0.3) {
        this.volumeButton.setIcon('onspace/player_volume_normal')
      } else {
        this.volumeButton.setIcon('onspace/player_volume_low')
      }

      this.volumeButton.value = volume
    } else {
      this.volumeButton.style.display = 'none'
    }
  }

  /// Creates a new volume element.
  createVolumeControl() {
    const volumeElement = document.createElement('div')
    volumeElement.classList.add('onspace-player__controls__volume')

    const scrubberElement = document.createElement('div')
    scrubberElement.classList.add('onspace-player__controls__volume__scrubber')
    volumeElement.appendChild(scrubberElement)

    const trackElement = document.createElement('div')
    trackElement.classList.add('onspace-player__controls__volume__scrubber__track')
    scrubberElement.appendChild(trackElement)

    return [volumeElement, scrubberElement, trackElement]
  }

  ///// Events

  /// Responds to clicks on the volume button.
  volumePressed(_event) {
    this.player.toggleAudio()
  }

  /// Responds to slides on the volume button.
  volumeChanged(value) {
    this.player.audioVolume = value
  }

  ////////// Remote

  /// Initialises the elements which control remote playback.
  setupRemoteControls() {
    if (this.airplayButton) { return }

    this.airplayButton = this.barRightElement.addButton('airplay', { icon: 'onspace/player_airplay', pressed: this.airplayPressed.bind(this) })
    this.googleCastButton = this.barRightElement.addButton('google_cast', { icon: 'onspace/player_google_cast', pressed: this.googleCastPressed.bind(this) })

    this.updateRemoteControls()
  }

  /// Updates the state of remote playback controls.
  updateRemoteControls() {
    if (!this.airplayButton) { return }

    if (this.player.airplayActive) {
      this.airplayButton.style.display = ''
      this.airplayButton.setIcon('onspace/player_airplay_active')
    } else if (this.player.canAirplay) {
      this.airplayButton.style.display = ''
      this.airplayButton.setIcon('onspace/player_airplay')
    } else {
      this.airplayButton.style.display = 'none'
    }

    if (this.player.googleCastActive) {
      this.googleCastButton.style.display = ''
      this.googleCastButton.setIcon('onspace/player_google_cast_active')
    } else if (this.player.canGoogleCast) {
      this.googleCastButton.style.display = ''
      this.googleCastButton.setIcon('onspace/player_google_cast')
    } else {
      this.googleCastButton.style.display = 'none'
    }
  }

  ///// Events

  /// Responds to presses on the Airplay button.
  airplayPressed(_event) {
    this.player.selectAirplayDevice()
  }

  /// Responds to presses on the Google Cast button.
  googleCastPressed(_event) {
    this.player.selectGoogleCastDevice()
  }

  ////////// Position

  /// Initialises the position element.
  setupPositionControls() {
    if (this.positionElement) { return }

    [this.positionElement, this.positionScrubberElement, this.positionTrackElement, this.positionElapsedElement, this.positionRemainingElement] = this.createPositionElement()
    this.footerElement.appendChild(this.positionElement)

    this.liveIndicatorElement = this.barLeftElement.addIndicator('live', { text: translation('onspace.media.player.controls.live') })
    this.liveIndicatorElement.style.display = 'none'

    this.positionElement.addPressEventListeners({
      onPressBegan: this.positionScrubberPressBegan.bind(this),
      onPressMoved: this.positionScrubberPressMoved.bind(this),
      onPressEnded: this.positionScrubberPressEnded.bind(this)
    })
  }

  /// Creates a new position element.
  createPositionElement() {
    const positionElement = document.createElement('div')
    positionElement.classList.add('onspace-player__controls__position')

    const scrubberElement = document.createElement('div')
    scrubberElement.classList.add('onspace-player__controls__position__scrubber')
    positionElement.appendChild(scrubberElement)

    const trackElement = document.createElement('div')
    trackElement.classList.add('onspace-player__controls__position__scrubber__track')
    scrubberElement.appendChild(trackElement)

    const elapsedElement = document.createElement('div')
    elapsedElement.classList.add('onspace-player__controls__position__elapsed')
    elapsedElement.innerText = '00:00'
    positionElement.appendChild(elapsedElement)

    const remainingElement = document.createElement('div')
    remainingElement.classList.add('onspace-player__controls__position__remaining')
    remainingElement.innerText = '00:00'
    positionElement.appendChild(remainingElement)

    return [positionElement, scrubberElement, trackElement, elapsedElement, remainingElement]
  }

  /// Updates the data in the position element.
  updatePositionElement() {
    if (!this.positionElement) { return }

    if (this.player.isLiveContent) {
      this.classList.add('onspace-player__controls--live')
      this.liveIndicatorElement.style.display = ''
    } else {
      this.classList.remove('onspace-player__controls--live')
      this.liveIndicatorElement.style.display = 'none'

      let remaining = this.player.duration - this.player.position
      if (remaining < 0) { remaining = 0 }

      this.positionElapsedElement.innerText = this.player.position.toMediumDurationString()
      this.positionRemainingElement.innerText = `-${remaining.toMediumDurationString()}`

      if (!this.player.isPlaying) {
        this.updatePositionScrubber()
      }
    }
  }

  /// Updates the state of the position scrubber.
  updatePositionScrubber() {
    let percentage

    const duration = this.player.duration
    let position = this.player.position
    if (duration === 0) {
      position = 0
      percentage = 0
    } else {
      percentage = position / duration
    }

    this.positionTrackElement.style.width = `${percentage * 100}%`
  }

  /// Determines if the position scrubber should be animating.
  get canAnimatePositionScrubber() {
    return this.player.isPlaying && !this.player.isLiveContent && this.positionScrubberElement
  }

  /// Detects whether the position scrubber should be updating.
  ///
  /// This will start or stop the animation depending on the desired state.
  detectPositionScrubberAnimation() {
    if (this.canAnimatePositionScrubber) {
      if (!this.positionScrubberAnimation) {
        this.animatePositionScrubber()
      }
    } else if (this.positionScrubberAnimation) {
      cancelAnimationFrame(this.positionScrubberAnimation)
      this.positionScrubberAnimation = null
    }
  }

  /// Smoothly animates the position scrubber during playback.
  ///
  /// This repeatedly calls itself using +window.requestAnimationFrame+, which results in the scrubber being updated for
  /// every repaint of the screen. This will usually match with the screen's frame rate, e.g. 60hz.
  animatePositionScrubber() {
    this.updatePositionScrubber()

    this.positionScrubberAnimation = requestAnimationFrame(this.animatePositionScrubber.bind(this))
  }

  ///// Events

  /// Responds to presses on the position scrubber.
  positionScrubberPressBegan(event) {
    document.body.style.setProperty('cursor', 'grabbing', 'important')
    this.positionScrubberElement.classList.add('onspace-player__controls__position__scrubber--scrubbing')

    this.player.beginSeek()

    this.positionScrubberPressMoved(event)
  }

  /// Responds to press movement while scrubbing the position.
  positionScrubberPressMoved(event) {
    let clientX
    if (event.type.startsWith('mouse')) {
      clientX = event.clientX
    } else {
      clientX = event.touches[0].clientX
    }

    const boundingRect = this.positionScrubberElement.getBoundingClientRect()

    const percentage = (clientX - boundingRect.left) / boundingRect.width
    this.player.position = percentage * this.player.duration
  }

  /// Responds to a press ending from scrubbing the position.
  positionScrubberPressEnded(_event) {
    document.body.style.cursor = ''
    this.positionScrubberElement.classList.remove('onspace-player__controls__position__scrubber--scrubbing')

    this.player.endSeek()
  }

  ////////// Tabs

  /// Initialises the tabs element.
  setupTabsElement() {
    if (this.tabsElement) { return }

    this.tabsElement = new OnspacePlayerTabs({ player: this.player })
    this.appendChild(this.tabsElement)

    this.tabsElement.addEventListener('onspace:media:tabs:expanded', this.tabsExpanded.bind(this))
    this.tabsElement.addEventListener('onspace:media:tabs:collapsed', this.tabsCollapsed.bind(this))

    this.updateTabsElement()
  }

  /// Updates the tabs UI.
  updateTabsElement() {
    if (!this.tabsElement) { return }

    this.tabsElement.tabData = this.player.tabData
  }

  ///// Events

  /// Responds to the tabs element showing its content.
  tabsExpanded(_event) {
    this.player.classList.add('onspace-player--tabs-expanded')
  }

  /// Responds to the tabs element hiding its content.
  tabsCollapsed(_event) {
    this.player.classList.remove('onspace-player--tabs-expanded')
  }
}
