import './components/date'
import './components/device'
import './components/events'
import './components/form'
import './components/html_element'
import './components/locale'
import './components/number'
import './components/scrollretain'
import './components/string'
import './components/turbo'

import './elements/batch'
import './elements/clipboard_copy'
import './elements/dialog'
import './elements/dropdown'
import './elements/form'
import { configureTimeZone } from './elements/formatted_time'
import './elements/heading'
import './elements/navigation'
import './elements/svg'

configureTimeZone()
