import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// The Onspace Video Selector is a component which allows users to select from a list of videos.
export default class OnspaceVideoSelector extends CustomHTMLElement {
  /// Runs when the element is first connected to the DOM.
  runFirstConnected() {
    super.runFirstConnected()

    this.classList.add('onspace-video-selector')
  }

  ////////// Videos

  /// Add a video item to the selector.
  ///
  /// This accepts a +video+ object, see OnspaceVideoMultiScreen.parseVideos for more information.
  addVideo(video) {
    const videoElement = document.createElement('div')
    videoElement.classList.add('onspace-video-selector__item')
    videoElement.videoId = video.id
    videoElement.addPressEventListeners({ onPress: this.videoClicked.bind(this) })

    const imageElement = document.createElement('div')
    imageElement.classList.add('onspace-video-selector__item__image')
    videoElement.appendChild(imageElement)

    const selectedElement = document.createElement('div')
    selectedElement.classList.add('onspace-video-selector__item__selected')
    imageElement.appendChild(selectedElement)

    const actionIcon = SVGElement.createOnspaceSpritemapSvg()
    actionIcon.classList.add('onspace-video-selector__item__action')
    imageElement.appendChild(actionIcon)

    if (video.artwork) {
      imageElement.style.backgroundImage = `url(${video.artwork})`
    }

    if (video.title) {
      const titleElement = document.createElement('div')
      titleElement.classList.add('onspace-video-selector__item__title')
      titleElement.innerText = video.title
      videoElement.appendChild(titleElement)
    }

    if (video.subtitle) {
      const subtitleElement = document.createElement('div')
      subtitleElement.classList.add('onspace-video-selector__item__subtitle')
      subtitleElement.innerText = video.subtitle
      videoElement.appendChild(subtitleElement)
    }

    videoElement.setSelectedIndex = (index) => {
      if (index) {
        actionIcon.setOnspaceSpritemapSvg('onspace/icon_cross')
        selectedElement.innerText = index.toString()
      } else {
        actionIcon.setOnspaceSpritemapSvg('onspace/icon_plus')
        selectedElement.innerText = ''
      }
    }

    this.appendChild(videoElement)
  }

  /// Responds to clicks on a video element.
  videoClicked(event) {
    this.triggerEvent('onspace:media:video-selector:video-clicked', event.target.videoId)
  }

  /// Retrieves the selectable video elements.
  get videoElements() {
    return this.children
  }

  /// Updates the UI with the currently selected video indexes.
  set selectedVideos(value) {
    Array.from(this.videoElements).forEach((element) => {
      element.setSelectedIndex(value[element.videoId] || '')
    })
  }
}

window.customElements.define('onspace-video-selector', OnspaceVideoSelector)
