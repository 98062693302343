import RestService from '@onpace/api_service/rest_service'

////////// Service

/// A service which interacts with the Onspace analytics API.
///
/// This is used internally by OnspaceAnalyticsReporter and should not be used directly.
class OnspaceAnalyticsService extends RestService {
  ////////// Session

  /// Configures the client's session parameters.
  ///
  /// When +changed+ is +true+, which it is by default, the API will be informed of this change when the next request is
  /// sent. If no requests are sent within the next 2 seconds, a session update will be sent instead.
  updateSessionParams(session, changed = true) {
    this.defaultParams = { session }

    if (changed) {
      this.sessionUpdated = true

      setTimeout(() => {
        if (!this.sessionUpdated) { return }

        this.sessionUpdated = false
        this.putSessionChanged()
      }, 2000)
    }
  }

  ////////// Endpoints

  /// Makes a PUT request to +/session+.
  ///
  /// For more detail, see Onspace::Analytics::Controller::TrackingMethods.onspace_analytics_track_session_update.
  async putSessionChanged() {
    return await this.PUT('/tracking/session')
  }

  /// Makes a POST request to +/page-view+.
  ///
  /// This accepts the following parameters:
  /// [url]
  ///   The full URL of the page to report.
  /// [route]
  ///   The parameterised route for the page to report.
  ///
  /// For more detail, see Onspace::Analytics::Controller::TrackingMethods.onspace_analytics_track_page_view.
  async postPageView(url, route) {
    const params = { page: { url, route } }
    if (this.sessionUpdated) {
      this.sessionUpdated = false
      params.session_changed = true
    }

    return await this.POST('/tracking/page-view', { params })
  }

  /// Makes a POST request to +/event/:event-type+.
  ///
  /// This accepts the following parameters:
  /// [eventType]
  ///   The key representing the type of event.
  /// [:event]
  ///   An object with data specific to the event type.
  ///
  /// For more detail, see Onspace::Analytics::Controller::TrackingMethods.onspace_analytics_track_event.
  async postEvent(eventType, event) {
    const params = {}
    if (typeof event === 'object') { params.event = event }
    if (this.sessionUpdated) {
      this.sessionUpdated = false
      params.session_changed = true
    }

    return await this.POST(`/tracking/event/${eventType}`, { params })
  }
}

////////// Reporter

/// Reports analytics events to Onspace.
///
/// Do not create new instances of this element, instead use the globally exported instance from this file. Before this
/// can be used, it must be configured using OnspaceAnalyticsReporter.configure.
class OnspaceAnalyticsReporter {
  /// Configures the reporter.
  ///
  /// This must be called before using any of the other features of the reporter. If this is not the case, reporting
  /// will be ignored.
  ///
  /// The following options are available:
  /// [baseUrl]
  ///   The base URL which hosts the Onspace analytics API. This should correspond to the Rails application's
  ///   +analytics_routes_prefix+, with the full domain name if required.
  ///
  ///   By default this is set to +/onspace/analytics+.
  /// [requestedWith]
  ///   Sets the +X-Requested-With+ header for all requests.
  ///
  ///   This can be used to customise the browser name and version that is stored in processed analytics when used in
  ///   combination with the +analytics_parse_requested_with+ configuration.
  ///
  ///   By default this is not set, but a value may still be sent depending on the browser.
  /// [session]
  ///   Additional custom parameters to be stored in the session.
  configure({ baseUrl = '/onspace/analytics', requestedWith, session } = {}) {
    this.sessionParameters = {}
    if (typeof window.sessionStorage !== 'undefined') {
      const sessionString = sessionStorage.getItem('onspace-analytics-session-data')
      if (typeof sessionString === 'string' && sessionString.length > 0) {
        this.sessionParameters = JSON.parse(sessionString)
      }
    }

    const clientOptions = {
      baseUrl,
      defaultHeaders: {}
    }

    if (requestedWith) {
      clientOptions.defaultHeaders['X-Requested-With'] = requestedWith
    }

    this.client = new OnspaceAnalyticsService(clientOptions)

    this.updateSession(session)
  }

  /// Configures the reporter in Turbo mode.
  ///
  /// This will pass the given options to OnspaceAnalyticsReporter.configure, and setup event handlers to automatically
  /// log page views.
  configureTurbo(options = {}) {
    this.configure(options)

    document.addEventListener('turbo:load', (event) => {
      this.reportPage({ url: event.detail.url })
    })
  }

  ////////// Session

  /// Configure custom parameters to be stored in the session.
  ///
  /// This accepts an object of arbitrary parameters, although they need to be configured in the API. Additionally, this
  /// function also handles the screen size. The screen dimensions can be set from the +width+ and +height+ parameters,
  /// which if not given, will be set automatically.
  ///
  /// Any parameters configured here will be stored in the browser's session storage, meaning for the duration of a
  /// user's session, the parameters do not need to be continually set. When passing changes, the new parameters will be
  /// merged with the existing set, so attributes must be explicity set to +null+ to be removed.
  ///
  /// The API will be informed of changes, so that existing session data can be updated. Note that this means there will
  /// only ever be 1 set of parameters per session. If multiple data sets in a single session are required, use events
  /// instead.
  updateSession(parameters = {}) {
    const existingParameterString = JSON.stringify(this.sessionParameters)
    this.sessionParameters = { ...this.sessionParameters, ...parameters }

    if (!this.sessionParameters.width || !this.sessionParameters.height) {
      this.sessionParameters.width = window.screen.width
      this.sessionParameters.height = window.screen.height
    }

    this.sessionParameters = Object.keys(this.sessionParameters)
      .sort()
      .reduce((obj, key) => {
        obj[key] = this.sessionParameters[key]
        return obj
      }, {})
    const sessionString = JSON.stringify(this.sessionParameters)

    if (existingParameterString != sessionString) {
      if (typeof window.sessionStorage !== 'undefined') {
        sessionStorage.setItem('onspace-analytics-session-data', sessionString)
      }
      this.client.updateSessionParams(this.sessionParameters)
    } else {
      this.client.updateSessionParams(this.sessionParameters, false)
    }
  }

  ////////// URLs

  /// Retrieves the path portion of a URL.
  parseUrlPath(url) {
    url = URL(url)
    return url.pathname
  }

  ////////// Reporting

  /// Reports a page view.
  ///
  /// This accepts the following parameters:
  /// [:url]
  ///   The full URL of the page to report. If not explicitly provided, this is taken from the browser's current
  ///   location.
  /// [:route]
  ///   The parameterised route for the url. The reporter will attempt to infer this from the following:
  ///   - From the meta tag +onspace-analytics-route+.
  ///   - Otherwise it will be set to the +url+.
  async reportPage({ url, route } = {}) {
    if (!this.client) { return }

    if (!url) {
      url = window.location.href
    }

    if (!route) {
      const routeMeta = document.querySelector('meta[name=onspace-analytics-route]')
      if (routeMeta) { route = routeMeta.content }

      route = route || this.parseUrlPath(url)
    }

    await this.client.postPageView(url, route)
  }

  /// Reports an event.
  ///
  /// This accepts the following parameters:
  /// [eventType]
  ///   The key representing the type of event.
  /// [event]
  ///   An object with data specific to the event type.
  async reportEvent(eventType, event) {
    if (!this.client) { return }

    await this.client.postEvent(eventType, event)
  }
}

const reporter = new OnspaceAnalyticsReporter()
export default reporter

window.onspaceAnalyticsReporter = reporter
