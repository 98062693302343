import DropDown from '@onpace/onspace-core/elements/dropdown'

import translation from '@onpace/onspace-core/components/translations'

/// An element which handles batch selection and actions.
export default class BatchController extends DropDown {
  /// Sets up the batch element.
  runConstructor() {
    super.runConstructor()

    this.toggleModeActive = false
    this.selectionModeActive = true

    this.anchorTitleElement = this.anchor.querySelector('span')
    this.anchorIconElement = this.anchor.querySelector('svg')
  }

  /// Runs when the element is connected to the DOM.
  runConnected() {
    super.runConnected()

    this.setupActions()
    this.setupCheckboxes()
    this.selectionsChanged()

    this.addWindowBoundEventListener('resize', this.batchWindowResized)
    this.addDocumentBoundEventListener('onspace:batch-selection:changed', this.selectionsChanged)
    this.detectToggleMode()
  }

  /// Runs when the element is disconnected from the DOM.
  runDisconnected() {
    super.runDisconnected()

    this.removeWindowBoundEventListener('resize')
    this.removeDocumentBoundEventListener('onspace:batch-selection:changed')
  }

  batchWindowResized(_event) {
    this.detectToggleMode()
  }

  /// Callback for clicking the anchor element.
  ///
  /// This overrides DropDown.anchorClicked to enable selections when in toggle mode.
  anchorClicked(event) {
    if (this.toggleModeActive && !this.selectionModeActive) {
      this.enableSelectionMode()
      return
    }

    super.anchorClicked(event)
  }

  ////////// Selection Toggle Mode

  /// Detects whether selection toggle mode should be enabled.
  detectToggleMode() {
    const desktopButtons = document.querySelector('table td.onspace-table__buttons .onspace-button-group')
    const isDesktop = desktopButtons && desktopButtons.getComputedStyle().display !== 'none'

    if (isDesktop) {
      this.disableToggleMode()
    } else {
      this.enableToggleMode()
    }
  }

  /// Turns on selection toggle mode.
  enableToggleMode() {
    if (this.toggleModeActive) { return }
    this.toggleModeActive = true

    const selectedCheckboxes = document.querySelectorAll('input[name=onspace_batch_selection]:checked')
    if (selectedCheckboxes.length == 0) {
      this.disableSelectionMode()
    } else {
      this.enableSelectionMode()
    }
  }

  /// Turns off selection toggle mode.
  disableToggleMode() {
    if (!this.toggleModeActive) { return }
    this.toggleModeActive = false

    this.disableSelectionMode()

    this.anchorIconElement.style.display = ''
  }

  /// Turns on selection mode.
  enableSelectionMode() {
    this.selectionModeActive = true

    this.anchorIconElement.style.display = ''
    document.body.classList.add('onspace-batch-selecting')
  }

  /// Turns off selection mode.
  disableSelectionMode() {
    this.selectionModeActive = false

    this.anchorIconElement.style.display = 'none'
    document.body.classList.remove('onspace-batch-selecting')

    this.deselectAll()
  }

  ////////// Actions

  /// Sets up the actions within the dropdowns.
  ///
  /// Locates the required children and adds events where necessary.
  setupActions() {
    const selectAllItem = this.querySelector('[data-select-all]')
    selectAllItem.addEventListener('click', this.selectAllClicked.bind(this))

    const deselectAllItem = this.querySelector('[data-deselect-all]')
    deselectAllItem.addEventListener('click', this.deselectAllClicked.bind(this))

    this.actionNames = []
    this.actionItems = this.querySelectorAll('[data-action]')
    this.actionItems.forEach((actionItem) => {
      actionItem.actionName = actionItem.getAttribute('data-action')
      actionItem.actionHref = actionItem.getAttribute('data-href')

      this.actionNames.push(actionItem.actionName)

      actionItem.addEventListener('click', (_event) => this.hideMenu())
    })
  }

  /// Responds to clicks on the select all item.
  selectAllClicked(_event) {
    this.selectAll()
  }

  /// Response to clicks on the deselect all item.
  deselectAllClicked(_event) {
    if (this.selectionModeActive) {
      this.disableSelectionMode()
    } else {
      this.deselectAll()
    }

    this.hideMenu()
  }

  ////////// Selections

  /// Sets up listeners for selection checkboxes on the page.
  setupCheckboxes() {
    const checkboxes = document.querySelectorAll('input[name=onspace_batch_selection]')
    checkboxes.forEach((checkbox) => {
      checkbox.batchActions = checkbox.getAttribute('data-actions').split(',')
    })
  }

  /// Responds to a change in the selections.
  selectionsChanged(_event) {
    if (this.updatingSelections) { return }

    const selectedCheckboxes = document.querySelectorAll('input[name=onspace_batch_selection]:checked')
    if (selectedCheckboxes.length == 0) {
      this.anchor.classList.add('onspace-button--outline')
      this.anchorTitleElement.innerText = translation('onspace.controller.batch.button.title.none')

      this.actionItems.forEach((actionItem) => {
        actionItem.setAttribute('disabled', '')
        actionItem.removeAttribute('href')
      })
      return
    }

    this.anchor.classList.remove('onspace-button--outline')
    let availableActionNames = [...this.actionNames]
    let checkboxValues = []

    if (selectedCheckboxes.length == 1) {
      availableActionNames = selectedCheckboxes[0].batchActions
      checkboxValues = [selectedCheckboxes[0].value]
      this.anchorTitleElement.innerText = translation('onspace.controller.batch.button.title.one')
    } else {
      selectedCheckboxes.forEach((checkbox) => {
        availableActionNames = availableActionNames.filter((name) => checkbox.batchActions.includes(name))
        checkboxValues.push(checkbox.value)
      })
      this.anchorTitleElement.innerText = translation('onspace.controller.batch.button.title.many').replace('%{count}', selectedCheckboxes.length)
    }

    this.actionItems.forEach((actionItem) => {
      if (availableActionNames.includes(actionItem.actionName)) {
        actionItem.removeAttribute('disabled', '')
        actionItem.setAttribute('href', actionItem.actionHref.replace('$modelids', checkboxValues.join(',')))
      } else {
        actionItem.setAttribute('disabled', '')
        actionItem.removeAttribute('href')
      }
    })
  }

  /// Selects all unselected checkboxes.
  selectAll() {
    this.updatingSelections = true

    const checkboxes = document.querySelectorAll('input[name=onspace_batch_selection]:not(:checked)')
    checkboxes.forEach((checkbox) => {
      checkbox.setChecked(true)
    })

    this.updatingSelections = false
    this.selectionsChanged()
  }

  /// Deselects all selected checkboxes.
  deselectAll() {
    this.updatingSelections = true

    const checkboxes = document.querySelectorAll('input[name=onspace_batch_selection]:checked')
    checkboxes.forEach((checkbox) => {
      checkbox.setChecked(false)
    })

    this.updatingSelections = false
    this.selectionsChanged()
  }
}

window.customElements.define('batch-controller', BatchController)
