import CustomHTMLElement from '@onpace/onspace-core/components/html_element'
import FormattedTime from '@onpace/onspace-core/elements/formatted_time'

import Chart from 'chart.js/auto'

/// An element which displays a chart.js element.
export default class OnspaceChart extends CustomHTMLElement {
  /// Sets up the chart element.
  ///
  /// This creates a new chart from options and/or attributes. The following parameters are available:
  /// [type]
  ///   The type of chart to create. This is passed directly to the chart.js element as it's +type+ option.
  /// [data]
  ///   The data to display. This is passed directly to the chart.js element as it's +data+ option.
  /// [label format]
  ///   The format of data present in the label. If not provided, the labels will be left as-is. The following options
  ///   are available:
  ///     - +year+
  ///     - +month+
  ///     - +date+
  ///
  ///   For date types, the labels must be a string in ISO8601 date or datetime format.
  runConstructor(options = {}) {
    super.runConstructor()

    this.chartType = options.type || this.getAttribute('type')
    this.chartData = options.data || this.getJsonAttribute('data')
    this.labelFormat = options.labelFormat || this.getAttribute('label-format')
  }

  /// Sets up listeners for window events.
  runConnected() {
    super.runConnected()

    if (!this.chart) {
      this.setupChart()
    }

    if (window.matchMedia) {
      this.colorSchemeMatcher = window.matchMedia('(prefers-color-scheme: dark)')
      this.colorSchemeListener = this.updateColors.bind(this)
      this.colorSchemeMatcher.addEventListener('change', this.colorSchemeListener)
    }

    this.updateStyles()
    this.updateColors()
  }

  /// Cleans up listeners for window events.
  runDisconnected() {
    super.runDisconnected()

    if (this.colorSchemeMatcher) {
      this.colorSchemeMatcher.removeEventListener('change', this.colorSchemeListener)
      this.colorSchemeMatcher = null
      this.colorSchemeListener = null
    }
  }

  /// Sets up the options for the chart element.
  setupChartOptions() {
    const chartOptions = {
      animation: false,
      interaction: {
        intersect: false
      },
      elements: {
        line: {
          cubicInterpolationMode: 'monotone'
        }
      },
      scales: {
        x: {
          border: {
            display: false
          },
          grid: {
            display: false
          },
          ticks: {}
        },
        y: {
          border: {
            display: false
          },
          ticks: {
            maxTicksLimit: 5
          }
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            usePointStyle: true
          }
        },
        tooltip: {
          mode: 'index',
          axis: 'xy',
          position: 'nearest',
          usePointStyle: true,
          callbacks: {}
        }
      }
    }

    switch (this.labelFormat) {
    case 'year':
      chartOptions.scales.x.ticks.callback = function(value, _index, _ticks) {
        const date = new Date(this.getLabelForValue(value))
        return date.toLocaleString(FormattedTime.defaultLocale, FormattedTime.formats.year)
      }
      chartOptions.plugins.tooltip.callbacks.title = function(context) {
        const date = new Date(context[0].label)
        return date.toLocaleString(FormattedTime.defaultLocale, FormattedTime.formats.year_long)
      }
      break
    case 'month':
      chartOptions.scales.x.ticks.callback = function(value, _index, _ticks) {
        const date = new Date(this.getLabelForValue(value))
        return date.toLocaleString(FormattedTime.defaultLocale, FormattedTime.formats.month)
      }
      chartOptions.plugins.tooltip.callbacks.title = function(context) {
        const date = new Date(context[0].label)
        return date.toLocaleString(FormattedTime.defaultLocale, FormattedTime.formats.month_long)
      }
      break
    case 'date':
      chartOptions.scales.x.ticks.callback = function(value, _index, _ticks) {
        const date = new Date(this.getLabelForValue(value))
        return date.toLocaleString(FormattedTime.defaultLocale, FormattedTime.formats.date)
      }
      chartOptions.plugins.tooltip.callbacks.title = function(context) {
        const date = new Date(context[0].label)
        return date.toLocaleString(FormattedTime.defaultLocale, FormattedTime.formats.date_long)
      }
      break
    }

    return chartOptions
  }

  /// Creates the chart element.
  setupChart() {
    this.canvasElement = document.createElement('canvas')
    this.appendChild(this.canvasElement)

    const chartOptions = this.setupChartOptions()

    this.chart = new Chart(this.canvasElement, {
      type: this.chartType,
      data: this.chartData,
      options: chartOptions
    })
  }

  /// Updates the chart's styles.
  ///
  /// This retrieves fonts and other styles from the element's styles.
  updateStyles() {
    const computedStyle = this.getComputedStyle()

    const aspectRatio = computedStyle.aspectRatio
    if (aspectRatio.match(/^\d+\s*\/\s*\d+/)) {
      const parts = aspectRatio.split(/\s*\/\s*/)
      const numerator = parseFloat(parts[0])
      const denominator = parseFloat(parts[1])

      this.chart.options.aspectRatio = numerator / denominator
    }

    const primaryFont = {
      family: computedStyle.getPropertyValue('--font-family-primary'),
    }

    const headingFont = {
      family: computedStyle.getPropertyValue('--font-family-heading'),
      weight: computedStyle.getPropertyValue('--font-weight-semibold')
    }

    this.chart.options.scales.x.ticks.font = primaryFont
    this.chart.options.scales.y.ticks.font = primaryFont

    this.chart.options.plugins.legend.labels.font = primaryFont

    this.chart.options.plugins.tooltip.titleFont = headingFont
    this.chart.options.plugins.tooltip.labelFont = primaryFont

    this.updateColors()
  }

  /// Updates the chart's colors.
  ///
  /// This retrieves colors from the element's styles, which account for light or dark mode.
  updateColors() {
    const computedStyle = this.getComputedStyle()

    let colorNames = computedStyle.getPropertyValue('--chart-color-names')
    colorNames = colorNames.split(/,\s+/)

    const textColor = computedStyle.getPropertyValue('--color-text')
    const borderColor = computedStyle.getPropertyValue('--color-border')
    const bgHoverColor = computedStyle.getPropertyValue('--color-bg-hover')

    this.chart.data.datasets.forEach((dataset, index) => {
      const colorIndex = index % colorNames.length
      const colorName = colorNames[colorIndex]

      const color = computedStyle.getPropertyValue(`--color-${colorName}`)

      dataset.borderColor = color
      dataset.backgroundColor = color
    })

    this.chart.options.color = textColor

    this.chart.options.scales.x.ticks.color = textColor
    this.chart.options.scales.y.ticks.color = textColor
    this.chart.options.scales.x.grid.color = borderColor
    this.chart.options.scales.y.grid.color = borderColor

    this.chart.options.plugins.tooltip.backgroundColor = bgHoverColor
    this.chart.options.plugins.tooltip.titleColor = textColor
    this.chart.options.plugins.tooltip.bodyColor = textColor

    this.chart.update()
  }
}

window.customElements.define('onspace-chart', OnspaceChart)
