import { register } from '@onpace/onspace-core/components/translations'

register({
  "en": {
    "onspace": {
      "authentication": {
        "webauthn": {
          "message": {
            "unsupported": "Passkeys are not supported in your browser"
          },
          "add": {
            "failed": "The passkey could not be created",
            "success": "Passkey retrieved"
          },
          "get": {
            "action": "Sign In with Passkey",
            "failed": "The passkey could not be retrieved"
          }
        }
      }
    }
  }
})
