import CustomHTMLElement from '@onpace/onspace-core/components/html_element'

/// An element which controls pagination.
///
/// This adds a small amount of functionality to enable directly selecting a page, although optional. To do so requires
/// setting the +data-href+ to the URL that should be navigated to, and using +%24page+ (+$page+) at the spot where the pagination
/// parameter should be inserted. This supports the following controls:
/// - A +select+ or +custom-select+ element. Listens for a value change and navigates immediately.
/// - A +date+ or +month+ input. Listens for a value change and navigates immediately.
export default class OnspacePagination extends CustomHTMLElement {
  /// Sets up the pagination element.
  ///
  /// Locates the optional controls.
  runConstructor() {
    super.runConstructor()

    const control = this.querySelector('select-custom,input[type=date],input[type=month]')
    if (control) {
      control.addEventListener('change', () => this.selectPage(control.value))
    }

    this.configureDropDownSearch()
  }

  /// Navigates to the given page.
  ///
  /// This takes the +data-href+ attribute and replaces the +%24page+ placeholder with the given page.
  selectPage(page) {
    let href = this.getAttribute('data-href')
    href = href.replace('%24page', page)

    const dialog = this.closest('onspace-dialog') || this.closest('onspace-dialog-select')

    dialog ? dialog.loadUrl(href) : window.Turbo.visit(href)
  }

  ////////// Drop-Down Search

  /// Configures the drop-down search input in the button list.
  ///
  /// This configures the element so when the user hits +return+ or +enter+, it navigates to the entered page.
  configureDropDownSearch() {
    const input = this.querySelector('drop-down input.onspace-dropdown__search__input')
    const goButton = this.querySelector('drop-down .onspace-dropdown__search .onspace-button')

    if (!input || !goButton) { return }

    this.dropDownSearchInput = input
    input.addEventListener('keypress', this.dropDownSearchKeyPressed.bind(this))

    this.dropDownSearchGoButton = goButton
    goButton.addEventListener('click', this.dropDownSearchGoButtonClicked.bind(this))

    const dropdown = input.closest('drop-down')
    dropdown.addEventListener('onspace:dropdown:show', this.dropDownShown.bind(this))
    dropdown.addEventListener('onspace:dropdown:hide', this.dropDownHidden.bind(this))
  }

  /// Navigates to the entered page in the drop-down search input
  useDropDownSearchInput() {
    const page = this.dropDownSearchInput.value
    if (!page || page === '') { return }

    this.selectPage(page)
  }

  /// Responds to key presses on the focused drop-down search input.
  dropDownSearchKeyPressed(event) {
    switch (event.key) {
    case 'Enter':
      this.useDropDownSearchInput()
      break
    }
  }

  /// Responds to clicks on the drop-down search go button.
  dropDownSearchGoButtonClicked(_event) {
    this.useDropDownSearchInput()
  }

  /// Responds to the drop-down being shown.
  dropDownShown(_event) {
    this.dropDownSearchInput.focus()
  }

  /// Responds to the drop-down being hidden.
  dropDownHidden(_event) {
    this.dropDownSearchInput.value = ''
  }
}
window.customElements.define('onspace-pagination', OnspacePagination)
