import CustomHTMLElement from '@onpace/onspace-core/components/html_element'
import cable from '@onpace/onspace-cms/components/cable'

import translation from '@onpace/onspace-core/components/translations'

/// An element which manages and displays the interactions of CMS users.
export default class OnspaceModelInteractions extends CustomHTMLElement {
  /// Sets up the model interactions element.
  runConstructor() {
    super.runConstructor()

    this.randomId = Math.floor(Math.random() * 1000)

    this.modelType = this.getAttribute('data-type')
    this.modelId = this.getAttribute('data-id')

    this.classList.add('onspace-interactions')
  }

  /// Runs when the element is connected to the DOM for the first time.
  runFirstConnected() {
    super.runFirstConnected()

    const computedStyle = this.getComputedStyle()

    const colorNames = computedStyle.getPropertyValue('--interaction-color-names')
    this.colorNames = colorNames.split(/,\s+/)
  }

  /// Runs when the element is connected to the DOM.
  runConnected() {
    super.runConnected()

    this.subscribe()
  }

  /// Runs when the element is disconnected from the DOM.
  runDisconnected() {
    super.runDisconnected()

    this.unsubscribe()
  }

  ////////// Subscribers

  /// Retrieves the current list of subscribers.
  get subscribers() {
    return this._subscribers
  }

  /// Updates the current list of subscribers.
  set subscribers(newval) {
    const subscribers = []
    const subscriberIds = []
    newval.forEach((data) => {
      if (cable.onspaceMetadata) {
        if (data.connection_id == cable.onspaceMetadata.connection_id) { return }
        if (data.authentication_model_id && data.authentication_model_id == cable.onspaceMetadata.authentication_model_id) { return }
      }

      const subscriberId = data.authentication_model_id || data.connection_id
      if (subscriberIds.includes(subscriberId)) { return }

      const colorId = subscriberId.getHashCode() % this.colorNames.length
      const colorName = this.colorNames[colorId]

      let name, initials
      switch (typeof data.authentication_model_name) {
      case 'object':
        name = data.authentication_model_name.formatted
        initials = data.authentication_model_name.initials
        break
      case 'string':
        name = data.authentication_model_name
        initials = name.charAt(0)
        break
      default:
        name = translation('onspace.cms.interactions.subscribers.anonymous.name')
        initials = translation('onspace.cms.interactions.subscribers.anonymous.initials')
        break
      }

      subscriberIds.push(subscriberId)
      subscribers.push({ colorName, name, initials })
    })

    this._subscribers = subscribers
    this.triggerEvent('onspace:cms:interactions:subscribers-changed', subscribers)
    this.updateInterface()
  }

  /// Updates the UI to represent the current subscribers.
  updateInterface() {
    this.innerHTML = ''

    const subscriberCount = this.subscribers.length
    if (subscriberCount === 0) { return }

    const countElement = document.createElement('div')
    countElement.classList.add('onspace-model-interactions__count')
    if (subscriberCount === 1) {
      countElement.innerText = translation('onspace.cms.interactions.subscribers.online.singular').replace('%{count}', subscriberCount)
    } else {
      countElement.innerText = translation('onspace.cms.interactions.subscribers.online.plural').replace('%{count}', subscriberCount)
    }
    this.appendChild(countElement)

    const subscribersElement = document.createElement('div')
    subscribersElement.classList.add('onspace-model-interactions__subscribers')
    this.appendChild(subscribersElement)

    this.subscribers.forEach((subscriber) => {
      const subscriberElement = document.createElement('div')
      subscriberElement.classList.add('onspace-model-interactions__subscriber')
      subscriberElement.classList.add(`onspace-model-interactions__subscriber--${subscriber.colorName}`)

      const initialsElement = document.createElement('div')
      initialsElement.classList.add('onspace-model-interactions__subscriber__initials')
      initialsElement.innerText = subscriber.initials
      subscriberElement.appendChild(initialsElement)

      const nameElement = document.createElement('div')
      nameElement.classList.add('onspace-model-interactions__subscriber__name')
      nameElement.innerText = subscriber.name
      subscriberElement.appendChild(nameElement)

      subscribersElement.appendChild(subscriberElement)
    })
  }

  ////////// Channel

  /// Initiates and subscribes to the interactions channel for the model.
  subscribe() {
    if (this.channel || this.duplicateElement) { return }

    const duplicates = document.querySelectorAll(`onspace-model-interactions[data-type="${this.modelType}"][data-id="${this.modelId}"]`)
    const duplicate = Array.from(duplicates).find((element) => element.randomId != this.randomId)

    if (duplicate) {
      this.duplicateElement = duplicate
      duplicate.addEventListener('onspace:cms:interactions:subscribers-changed', (event) => {
        this._subscribers = event.detail
        this.updateInterface()
      })

      this._subscribers = duplicate.subscribers
      this.updateInterface()
    } else {
      this.channel = cable.subscribe('Onspace::Cms::InteractionsChannel', {
        params: { type: this.modelType, id: this.modelId },
        connected: this.channelConnected.bind(this),
        disconnected: this.channelDisconnected.bind(this),
        rejected: this.channelRejected.bind(this),
        received: this.channelReceived.bind(this)
      })
    }
  }

  /// Cleans up an existing channel subscription.
  unsubscribe() {
    if (this.duplicateElement) {
      this.duplicateElement = null
    } else if (this.channel) {
      this.channel.unsubscribe()
      this.channel = null
    }
  }

  ///// Events

  /// Responds to the channel successfully connecting.
  channelConnected() {}

  /// Responds to the channel subscription disconnects.
  channelDisconnected() {}

  /// Responds to a channel subscription attempt being rejected.
  channelRejected() {
    this.subscribers = []
  }

  /// Responds to the channel subscription receiving some data.
  channelReceived(action, data) {
    switch (action) {
    case 'subscribers_changed':
      this.subscribers = data
    }
  }
}

window.customElements.define('onspace-model-interactions', OnspaceModelInteractions)
