import { _adapters } from 'chart.js'

import FormattedTime from '@onpace/onspace-core/elements/formatted_time'

const UNIT_DATE = 'date'
const UNIT_HOUR = 'hour'
const UNIT_MINUTE = 'minute'
const UNIT_MONTH = 'month'

_adapters._date.override({
  formats() {
    return []
  },

  parse(value, _format) {
    if (typeof value === 'string') {
      return new Date(value)
    }

    return value.getTime()
  },

  format(time, format) {
    const date = new Date(time)
    return FormattedTime.formatDate(date, format)
  },

  add(time, amount, unit) {
    switch(unit) {
    case UNIT_DATE:
    case UNIT_HOUR:
      return time + (amount * 86400000)
    case UNIT_MINUTE:
      return time + (amount * 60000)
    case UNIT_MONTH: {
      const date = new Date(time)
      date.setUTCMonth(date.getUTCMonth() + amount)
      return date.getTime()
    }
    default:
      throw `Unknown unit ${unit}`
    }
  },

  diff(max, min, unit) {
    switch(unit) {
    case UNIT_DATE:
    case UNIT_HOUR:
      return (max - min) / 86400000
    case UNIT_MINUTE:
      return (max - min) / 60000
    case UNIT_MONTH: {
      const maxDate = new Date(max)
      const minDate = new Date(min)

      return (maxDate.getUTCFullYear() - minDate.getUTCFullYear()) * 12 + maxDate.getUTCMonth() - minDate.getUTCMonth()
    }
    default:
      throw `Unknown unit ${unit}`
    }
  },

  startOf(time, unit, _weekday) {
    const date = new Date(time)

    switch(unit) {
    case UNIT_DATE:
      date.setUTCHours(0, 0, 0, 0)
      break
    case UNIT_HOUR:
      date.setHours(0, 0, 0, 0)
      break
    case UNIT_MINUTE:
      date.setSeconds(0)
      break
    case UNIT_MONTH:
      date.setUTCDate(1)
      date.setUTCHours(0, 0, 0, 0)
      break
    default:
      throw `Unknown unit ${unit}`
    }

    return date.getTime()
  }
})
