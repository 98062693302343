import * as ActionCable from '@rails/actioncable'

////////// Connection

/// Maintains a connection to an Onspace ActionCable websocket.
export default class OnspaceCable {
  /// Initialises a new connection.
  ///
  /// This accepts the following arguments:
  /// [url]
  ///   The URL to connect to. It can either be a full +ws(s)+ URL, or a path relative to the current page.
  /// [:cookies]
  ///   A regular expression which identifies the cookies that should be sent when opening the connection. By default,
  ///   this will send all cookies starting with +onspace-+. Set this to +false+ to disable this feature.
  ///
  ///   This is calculated every time a new connection is opened, to ensure the cookies are up to date.
  /// [:opened]
  ///   A callback which is run when the websocket is opened successfully.
  /// [:closed]
  ///   A callback which is run when the websocket closes.
  /// [:errored]
  ///   A callback which is run when the websocket raises an error.
  /// [:messaged]
  ///   A callback which is run when the websocket receives a message. This will be called with the following:
  ///   [message]
  ///     The raw message received.
  ///
  /// Note that this does not automatically start the connection.
  constructor(url, { cookies, opened, closed, errored, messaged } = {}) {
    this.url = url

    if (cookies === false) {
      this.cookieMatcher = null
    } else {
      this.cookieMatcher = cookies || /^onspace-/
    }

    this.openedCallback = opened
    this.closedCallback = closed
    this.erroredCallback = errored
    this.messagedCallback = messaged

    this.cable = ActionCable.createConsumer(this.generateActionCableUrl.bind(this))

    this.cable.connection._defaultInstallEventHandlers = this.cable.connection.installEventHandlers.bind(this.cable.connection)
    this.cable.connection.installEventHandlers = () => {
      this.cable.connection._defaultInstallEventHandlers()
      this.installWebsocketEvents()
    }

    document.addEventListener('turbo:before-fetch-response', this.beforeTurboFetchResponse.bind(this))
  }

  /// Dynamically generates the URL for the connection.
  ///
  /// This will append parameters to the base URL as required, for example to include authentication details.
  generateActionCableUrl() {
    const url = new URL(this.url, window.location.href)

    if (this.cookieMatcher) {
      const cookieParams = []

      const cookies = document.cookie.split(/;\s*/)
      cookies.forEach((cookie) => {
        const name = cookie.split('=')[0]
        if (name.match(this.cookieMatcher)) { cookieParams.push(cookie) }
      })

      if (cookieParams.length > 0) {
        url.searchParams.append('onspace_cookies', cookieParams.join('; '))
      }
    }

    return url.toString()
  }

  ///// State

  /// Indicates if the websocket is currently connected.
  get connected() {
    return this.cable.connection.isActive()
  }

  /// Opens the connection to ActionCable.
  connect() {
    this.wasConnected = true

    this.cable.connect()
  }

  /// Closes the connection to ActionCable.
  disconnect() {
    this.wasConnected = false

    this.cable.disconnect()
  }

  /// Closes and reopens the connection to ActionCable.
  reconnect() {
    this.disconnect()
    this.connect()
  }

  /// Installs event listeners on the raw websocket.
  ///
  /// This is called automatically when opening a connection.
  installWebsocketEvents() {
    const websocket = this.cable.connection.webSocket
    websocket.addEventListener('open', this.socketOpened.bind(this))
    websocket.addEventListener('message', this.socketMessaged.bind(this))
    websocket.addEventListener('close', this.socketClosed.bind(this))
    websocket.addEventListener('error', this.socketErrored.bind(this))
  }

  /// Callback run when the websocket connection is opened.
  socketOpened(_event) {
    if (this.openedCallback) { this.openedCallback() }
  }

  /// Callback run when the websocket connection is closed.
  socketClosed(_event) {
    if (this.closedCallback) { this.closedCallback() }
  }

  /// Callback run when the websocket connection receives an error.
  socketErrored(_event) {
    if (this.erroredCallback) { this.erroredCallback() }
  }

  /// Callback run when the websocket receives a message.
  socketMessaged(event) {
    let data = event.data
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data)
      } catch (_error) {
        data = null
      }
    }

    if (typeof data !== 'object') { return }

    switch (data.type) {
    case 'welcome':
      if (typeof data.onspace_metadata === 'object') {
        this.onspaceMetadata = data.onspace_metadata
      } else {
        this.onspaceMetadata = null
      }
    }

    if (this.messagedCallback) { this.messagedCallback(event.data) }
  }

  ///// Subscriptions

  /// Subscribes to a channel.
  ///
  /// Parameters are passed through directly to OnspaceCableChannel.constructor. You are responsible for closing the
  /// subscription when you are finished with it by using subscription.unsubscribe.
  subscribe(name, options = {}) {
    const channel = new OnspaceCableChannel(name, options)
    channel.subscribe(this.cable)

    return channel
  }

  ///// Events

  /// Responds to a Turbo network fetch completing.
  ///
  /// This listens for responses containing the +Turbo-Websocket-Reconnect+ header, and will reconnect if required.
  beforeTurboFetchResponse(event) {
    if (!this.wasConnected) { return }

    const reconnectHeader = event.detail.fetchResponse.response.headers.get('Turbo-Actioncable-Reconnect')
    if (reconnectHeader) {
      this.reconnect()
    }
  }
}

////////// Channel

/// Models an ActionCable channel subscription.
///
/// Instances of this are created when subscribing to a channel, this should not be used directly.
class OnspaceCableChannel {
  /// Constructs a new channel.
  ///
  /// This accepts the following arguments:
  /// [name]
  ///   The name of the channel. This should exactly match the Ruby class name of the channel.
  /// [:params]
  ///   Optional additional parameters to pass through to the channel.
  /// [:initialized]
  ///   A callback which is run when the subscription is created.
  /// [:connected]
  ///   A callback which is run when the subscription successfully connects.
  /// [:disconnected]
  ///   A callback which is run when the subscription disconnects.
  /// [:rejected]
  ///   A callback which is run when the subscription is rejected.
  /// [:received]
  ///   A callback which is run when the subscription receives some data. This will be called with the following:
  ///   [action]
  ///     The action name in the message.
  ///   [data]
  ///     Additional data sent alongside the message.
  constructor(name, { params = {}, initialized, connected, disconnected, rejected, received } = {}) { // cspell:disable-line
    this.channelName = name
    this.channelParams = params

    this.isConnected = false

    this.initializedCallback = initialized // cspell:disable-line
    this.connectedCallback = connected
    this.disconnectedCallback = disconnected
    this.rejectedCallback = rejected
    this.receivedCallback = received
  }

  ///// Connection

  /// Retrieves the object sent when subscribing to the channel.
  get subscriptionParams() {
    return {
      channel: this.channelName,
      ...this.channelParams
    }
  }

  /// Subscribes to the channel on the cable consumer.
  ///
  /// This is called automatically when subscribing to a channel, this should not be used directly.
  subscribe(cable) {
    this.subscription = cable.subscriptions.create(this.subscriptionParams, {
      initialized: this.initialized.bind(this), // cspell:disable-line
      connected: this.connected.bind(this),
      disconnected: this.disconnected.bind(this),
      rejected: this.rejected.bind(this),
      received: this.received.bind(this)
    })
  }

  /// Unsubscribes from the channel.
  unsubscribe() {
    this.subscription.unsubscribe()
    this.subscription = null
  }

  ///// Callbacks

  /// Callback run when the channel is created.
  initialized() {
    if (this.initializedCallback) { this.initializedCallback() }
  }

  /// Callback run when the channel subscription is successful.
  connected() {
    this.isConnected = true

    if (this.connectedCallback) { this.connectedCallback() }
  }

  /// Callback run when the channel subscription disconnects.
  disconnected() {
    this.isConnected = false

    if (this.disconnectedCallback) { this.disconnectedCallback() }
  }

  /// Callback run when the channel subscription is rejected.
  rejected() {
    this.isConnected = false

    if (this.rejectedCallback) { this.rejectedCallback() }
  }

  /// Callback run when the channel subscription receives some data.
  received(message) {
    if (this.receivedCallback) {
      const action = message['action']
      const data = message['data']

      this.receivedCallback(action, data)
    }
  }
}
