import { register } from '@onpace/onspace-core/components/translations'

register({
  "en": {
    "onspace": {
      "controller": {
        "batch": {
          "button": {
            "title": {
              "none": "Select...",
              "one": "1 selected",
              "many": "%{count} selected"
            }
          },
          "selections": {
            "select": "Select All",
            "deselect": "Clear Selection"
          }
        }
      },
      "navigation": {
        "form_unsaved": {
          "link": "Are you sure you wish to leave the page?\n\nYour unsaved changes will be lost.",
          "dialog": "Are you sure you wish to close the dialog?\n\nYour unsaved changes will be lost."
        }
      }
    }
  }
})
